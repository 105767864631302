import { FC, useContext, useRef } from "react";
import ThriveTitle from "../../common/ThriveTitle";
import { Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { StyledButtonMain } from "../../common/Buttons";
import Cookies from "js-cookie";
import axios from "axios";
import { UserType } from "../../../interfaces";
import { UserContext } from "../../../context/UserContext";

interface ResourceNotesProps {
  setOpen: (open: boolean) => void;
  resource: any;
  editorType: string;
}

const ResourceNotes: FC<ResourceNotesProps> = ({
  setOpen,
  resource,
  editorType,
}) => {
  const editorRef = useRef(null);
  const { user }: { user: UserType } = useContext(UserContext);
  const handleSave = () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    if (!editorRef.current) return;
    //@ts-ignore
    const htmlString = editorRef.current.getContent();
    // console.log(htmlString, resource);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/resources`,
        {
          resource: {
            ...resource,
            editorType: editorType,
            notes: htmlString,
          },
        },
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        window.location.reload();
      });
  };

  return (
    <>
      <ThriveTitle title="Resource Notes" setOpen={setOpen}></ThriveTitle>
      <Box
        sx={{
          margin: "10px",
          border: "solid 1px #c4c4c4",
          borderRadius: "0 0 10px 10px",
          // overflow: "auto",
          height: "500px",
        }}
      >
        <Editor
          tinymceScriptSrc={`${process.env.REACT_APP_API_DOMAIN}/javascripts/tinymce/tinymce.min.js`}
          //@ts-ignore
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={resource.notes}
          init={{
            // theme: "advanced",
            // readonly: readonly,
            forced_root_block: "",
            plugins: "paste",
            paste_as_text: true,
            height: 500,
            menubar: false,
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            relative_urls: false,
            remove_script_host: false,
          }}
        />
      </Box>
      {user.role === "admin" && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <StyledButtonMain
            variant="contained"
            size="large"
            sx={{ width: "20%", backgroundColor: "#941566", margin: "auto" }}
            onClick={handleSave}
          >
            Save
          </StyledButtonMain>
        </Box>
      )}
    </>
  );
};

export default ResourceNotes;
