import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { UserContext } from "../../../context/UserContext";
import EditIcon from "@mui/icons-material/Edit";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PublicIcon from "@mui/icons-material/Public";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
const cleanUrl = (url: string) => {
  if (url.includes("http://") || url.includes("https://")) {
    return url;
  } else {
    return "https://" + url;
  }
};
const resourceImageMap = new Map([
  [
    "Holly Village Downtown Development Authority",
    "Holly Village Downtown Development Authority.png",
  ],
  ["Leonard Village Council", "Leonard Village Council.png"],
  ["Oxford Village Council", "Oxford Village Council.jpg"],
  ["PNC Bank", "PNC Bank.png"],
  [
    "Waterford Township Economic Development and Plan",
    "Waterford Township Economic Development and Planning.png",
  ],
  ["West Bloomfield Township Council", "West Bloomfield Township Council.jpg"],
  [
    "West Bloomfield Township Economic Development and Planning",
    "West Bloomfield Township Council.jpg",
  ],
  ["Pontiac City Council", "Pontiac City Council.png"],
  ["Pontiac Economic Development and Planning", "Pontiac City Council.png"],
  ["Birmingham Shopping District", "Birmingham Shopping District.png"],
  [
    "Ferndale Downtown Development Authority",
    "Ferndale Downtown Development Authority.png",
  ],
]);

const resourceFactsheetMap = new Map([
  [
    "Accounting Aid Academy (Accounting Aid Society)",
    ["Accounting Aid Academy (Accounting Aid Society).png"],
  ],
  [
    "CDC Small Business Finance",
    [
      "CDC Loan Program Overview - J Moon.pdf",
      "J Moon - CDC Activate.pdf",
      "J Moon - CDC Community Advantage.pdf",
    ],
  ],
  [
    "CEED Lending",
    [
      "2021-RLFBorrowerBrochure-v2.pdf",
      "Consent Oakland Thrive SBDC 051723.pdf",
      "no credit elsewhere letterhead 3 31 2021.pdf",
      "RLF-BorrowerFlyer-220114 Chris.pdf",
    ],
  ],
  ["Centrepolis Accelerator", ["Centrepolis Accelerator.pdf"]],
  ["DTE Small Business Program", ["DTE Small Business Program.pdf"]],
  ["FranNet of Michigan", ["FranNet of Michigan.pdf"]],
  [
    "Gary Burnstein Community Health Clinic",
    ["Gary Burnstein Community Health Clinic.pdf"],
  ],
  [
    "Michigan Small Business Development Center (SBDC)",
    [
      "Consent Oakland Thrive SBDC 051723_1.pdf",
      "Export Team One Pager.pdf",
      "Growth Team.pdf",
      "Market Reserach One-Pager.pdf",
      "MI-SBDC 2022 AR_FINAL_1.27.23.pdf",
      "Southeast Regional Report FINAL.2.7.23.pdf",
      "Tech Team One-Pager.pdf",
    ],
  ],
  [
    "Pure Michigan Business Connect - MEDC",
    ["Pure Michigan Business Connect-MEDC.pdf"],
  ],
  [
    "APEX Accelerators (Formerly PTAC)",
    [
      "What Does APEX Offer.pdf",
      "What You Need to Start Government Contracting.pdf",
    ],
  ],
  [
    "Southfield Chamber of Commerce",
    [
      "5.24.23 Southfield_Eventbrite_Product Idea.jpg",
      "burnout proof your team - Sterling Heights event.png",
      "Southfield Chamber of Commerce.pdf",
      "Women in Business Lunch 3.15.png",
    ],
  ],
]);

function Resource(props: any) {
  const { user } = React.useContext(UserContext);
  const resource = props.resourceContent;
  function openEditModal(resource: any) {
    props.setEditingResource({ ...resource, resource_id: resource.id });
    props.setResourceEditorOpen(true);
  }

  const openNotesEditor = (resource: any) => {
    props.setSelectedResource({ ...resource, resource_id: resource.id });
    props.setResourceNotesOpen(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-md pb-2 pt-2">
      <div className="grid grid-cols-3 gap-2">
        <div className="col-start-1 col-span-1">
          <div className="flex flex-col">
            <div className="w-60 h-60 bg-white flex items-center justify-center rounded-lg m-1">
              {resourceImageMap.has(resource.orgName) ? (
                <img
                  src={`${
                    process.env.REACT_APP_API_DOMAIN
                  }/images/resources/${resourceImageMap.get(resource.orgName)}`}
                  className="object-contain w-full h-full"
                  alt="Resource"
                />
              ) : (
                <img
                  src={resource.imagePath}
                  className="object-contain w-full h-full"
                  alt="Resource"
                />
              )}
            </div>

            <div className="flex flex-col items-start ml-2">
              <button
                className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                onClick={() => {
                  props.setCardOpen(true);
                  props.setSelectedResource(resource);
                }}
              >
                {" "}
                Connect to Case
              </button>
              {resource.notes && (
                <>
                  <button
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    onClick={() => {
                      openNotesEditor(resource);
                    }}
                  >
                    View Notes
                  </button>
                </>
              )}
              {resource.factsheets && (
                <>
                  <button
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    onClick={() => {
                      props.setResourceFactsheetOpen(true);
                      props.setSelectedResource(resource);
                    }}
                  >
                    View Fact Sheet
                  </button>
                </>
              )}
              {resource.onepagerPath && (
                <>
                  <a
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    href={resource.onepagerPath}
                    target="_blank"
                  >
                    View One Pager
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-start-2 col-span-2">
          <div className="flex flex-col items-start h-full">
            <div className="w-full flex justify-between">
              <h3
                onClick={() => {
                  props.setHisOpen(true);
                  props.setHisResource(resource);
                }}
                className="text-lg font-bold text-left"
                style={{ float: "left", cursor: "pointer" }}
              >
                {resource.orgName}
              </h3>
              {/* Edit Resource Button, Edit Notes Button */}
              {user.role === "admin" && (
                <Stack direction={"row"} spacing={0}>
                  <div
                    style={{ width: "50px", height: "50px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      openEditModal(resource);
                    }}
                  >
                    <EditIcon className="text-magenta " />
                  </div>
                  <div
                    style={{ width: "50px", height: "50px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      openNotesEditor(resource);
                    }}
                  >
                    <DescriptionIcon className="text-magenta " />
                  </div>
                </Stack>
              )}
            </div>
            <Grid container spacing={2} textAlign={"left"}>
              {resource.poc && (
                <Grid item xs={6}>
                  <PersonPinCircleIcon fontSize="small"></PersonPinCircleIcon>
                  {resource.poc}
                </Grid>
              )}
              {resource.url && (
                <Grid item xs={6}>
                  <PublicIcon fontSize="small"></PublicIcon>
                  <a target="_blank" href={cleanUrl(resource.url)}>
                    View Website
                  </a>
                </Grid>
              )}

              <Grid item xs={6}>
                <PhoneIcon fontSize="small"></PhoneIcon>
                {resource.phone}
              </Grid>
              <Grid item xs={6}>
                <MailOutlineIcon fontSize="small"></MailOutlineIcon>
                <a href={`mailto:${resource.email}`}>{resource.email}</a>
              </Grid>
              {resource.calendarLink && (
                <Grid item xs={6}>
                  <CalendarMonthIcon fontSize="small"></CalendarMonthIcon>
                  <a target="_blank" href={cleanUrl(resource.calendarLink)}>
                    Schedule Meeting
                  </a>
                </Grid>
              )}

              <Grid item xs={6}>
                <LocationOnIcon fontSize="small"></LocationOnIcon>
                {resource.address}
              </Grid>
            </Grid>

            <div className="m-2">
              <h3 className="text-sm text-justify">{resource.description}</h3>
            </div>

            {/* tags */}
            {props.tagDisplay && resource.tags ? (
              <div className="mt-auto flex justify-left flex-wrap">
                {resource.tags.map((tag: any, index: number) => (
                  <Button
                    value={tag}
                    onClick={props.handleTagClick}
                    key={index}
                    style={{
                      marginRight: "5px",
                      textTransform: "none",
                      marginBottom: "5px",
                    }}
                    variant="contained"
                    sx={{
                      color: "black",
                      backgroundColor: "#ffb30080",
                      "&:hover": {
                        backgroundColor: "#bd5187",
                        color: "white",
                      },
                    }}
                  >
                    {tag}
                  </Button>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
