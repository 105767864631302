import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";

const mapContainerStyle = {
  height: "400px",
};

type Library =
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization";
const libraries: any[] = ["places"];

const MapContainer = (props: any) => {
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [address, setAddress] = useState<string>("");
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyApOaYGy5gH_zMqiRF003g7CKEbo-Q5SXM",
    libraries: libraries,
  });

  useEffect(() => {
    if (isLoaded && props.address) {
      getLatLong(props.address);
    }
  }, [isLoaded, retryCount]);

  const getLatLong = (addr: string) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: addr }, (results, status) => {
      if (status === "OK") {
        const location = results && results[0] && results[0].geometry?.location;
        if (location) {
          setLat(location.lat());
          setLng(location.lng());
          reverseGeocodeLocation(location);
        }
      } else {
        console.log("Error: " + status);
        // Retry loading map after a delay
        if (retryCount < 3) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
          }, 1000); // Retry after 1 second
        }
      }
    });
  };

  const reverseGeocodeLocation = (location: google.maps.LatLng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        const addr = results && results[0] && results[0].formatted_address;
        if (addr) {
          setAddress(addr);
        }
      } else {
        // console.log(
        //   "Reverse geocode was not successful for the following reason: " +
        //     status
        // );
      }
    });
  };

  const onMarkerClick = () => {
    setShowInfoWindow(true);
    // console.log("Inside onMarkerClick");
  };

  const onInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map</div>;
  if (!lat || !lng) return null;
  // console.log(lat, lng);
  return (
    <GoogleMap
      key={`${lat}-${lng}`}
      mapContainerStyle={props.style ? props.style : mapContainerStyle}
      center={{ lat, lng }}
      zoom={15}
    >
      <Marker position={{ lat, lng }} onClick={onMarkerClick} />

      {showInfoWindow && (
        <InfoWindow position={{ lat, lng }} onCloseClick={onInfoWindowClose}>
          <div>
            <h4>{address}</h4>
            <br></br>
            <p>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Click here to open in Maps</u>
              </a>
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default MapContainer;
