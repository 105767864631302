import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledButton = styled(Button)`
  font-weight: bold;
  background-color: #fdb814;
  &:hover {
    background-color: #ae2c6f;
  }
`;

export const StyledButtonMain = styled(Button)`
  font-weight: bold;
  background-color: #ae2c6f;
  &:hover {
    background-color: #fdb814;
  }
`;
