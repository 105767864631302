import { Button, Stack, Typography } from "@mui/material";
import ThriveTitle from "../../common/ThriveTitle";
import { FC } from "react";

interface FactSheetsListProps {
  setOpen: (open: boolean) => void;
  resource: any;
}

const FactSheetsList: FC<FactSheetsListProps> = ({ setOpen, resource }) => {
  return (
    <>
      <ThriveTitle title={"Factsheets List"} setOpen={setOpen}></ThriveTitle>
      <Stack sx={{ margin: "10px" }}>
        {resource.factsheets &&
          resource.factsheets.map((factsheet: any, index: number) => {
            return (
              <Stack
                key={index}
                direction={"row"}
                spacing={1}
                justifyContent={"center"}
              >
                <Typography>{factsheet.name}</Typography>
                <Button
                  sx={{
                    height: "20px",
                    width: "20px",
                    fontSize: "10px",
                    marginTop: "2px",
                  }}
                  variant="contained"
                  href={factsheet.download}
                >
                  Download
                </Button>
              </Stack>
            );
          })}
      </Stack>
    </>
  );
};
export default FactSheetsList;
