import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/common/Layout";
import HomePage from "./components/Home/HomePage";
import Dashboard from "./components/Dashboard/Dashboard";
import { UserContext } from "./context/UserContext";
import Engagements from "./components/Dashboard/Engagements";
import Firms from "./components/Dashboard/Firms";
import FirmReport from "./components/FirmReport/FirmReport";
import ExploreFirm from "./components/Dashboard/ExploreFirm/ExploreFirm";
import Glossary from "./components/Dashboard/Glossary/Glossary";
import Providers from "./components/Dashboard/ExploreProviders/Providers";
import ProgramStatistics from "./components/Dashboard/ProgramStatistics/ProgramStatistics";
import Case from "./components/Case/Case";
import { validate } from "./utils/auth";
import { useNavigate, useLocation } from "react-router-dom";
import ResourceList from "./components/Dashboard/Resources/Resources";
import Events from "./components/Dashboard/Events/Events";
import { UserType } from "./interfaces";
import PublicClientReferralSurvey from "./components/Survey/PublicClientReferralSurvey";
import Reports from "./components/Dashboard/Reports/Reports";
import { NavbarProvider } from "./context/NavbarContext";
import { Outlet } from "react-router-dom";
import PublicEventSurvey from "./components/Survey/PublicEventSurvey";
import PastEvents from "./components/Dashboard/Events/PastEvents";
import PublicConferenceSurvey from "./components/Survey/PublicConferenceSurvey";
import PublicB2BClientSurvey from "./components/Survey/PublicB2BClientSurvey";
import PublicB2BProviderSurvey from "./components/Survey/PublicB2BProviderSurvey";
import UserManagement from "./components/User/UserManagement";
import TagManagement from "./components/User/TagManagement";
import ReportPrint from "./components/Dashboard/Reports/ReportPrint";
import ExploreArchiveFirm from "./components/Dashboard/ExploreArchiveFirm/ExploreArchiveFirm";
import ArchiveCase from "./components/Dashboard/ExploreArchiveFirm/ArchiveCase";
import ExploreFirmBeta from "./components/Dashboard/ExploreFirm/ExploreFirmBate";
import FirmReportPdf from "./components/FirmReport/FirmReportPdf";
import RGA_report from "./components/Dashboard/Reports/ReportItems/RGA_report";
import Report from "./components/Dashboard/Reports/Report";
// import Survey_May_2023 from "./components/Survey/Survey_May_2023";
function App() {
  const [user, setUser] = React.useState<UserType>(() => {
    return JSON.parse(localStorage.getItem("user") || "{}");
  });

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    validate(location.pathname).then((res) => {
      if (!res) {
        navigate("/");
      }
    });
  }, [navigate]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <NavbarProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />}></Route>
              <Route path="/case" element={<Case />}></Route>
              <Route path="/firmReport" element={<FirmReport />}></Route>
              <Route path="/firmReportPdf" element={<FirmReportPdf />}></Route>
              {/* <Route path="/survey" element={<Survey_May_2023 />}></Route> */}
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<Engagements />}></Route>
                <Route path="firms" element={<Firms />}></Route>
                <Route
                  path="resources"
                  element={<ResourceList user={user} />}
                ></Route>
                <Route path="events" element={<Events user={user} />}></Route>
                <Route
                  path="pastevents"
                  element={<PastEvents user={user} />}
                ></Route>
                <Route path="exploreFirm" element={<ExploreFirm />}></Route>
                <Route
                  path="exploreFirmBeta"
                  element={<ExploreFirmBeta />}
                ></Route>
                <Route path="glossary" element={<Glossary />}></Route>
                <Route
                  path="providers"
                  element={<Providers user={user} />}
                ></Route>
                <Route
                  path="statistics"
                  element={<ProgramStatistics />}
                ></Route>
                <Route path="reports" element={<Reports></Reports>}></Route>
                <Route path="reports/:id" element={<Report></Report>}></Route>
                <Route
                  path="archivedcases"
                  element={<ExploreArchiveFirm />}
                ></Route>
                <Route
                  path="archivedcases/:id"
                  element={<ArchiveCase />}
                ></Route>
              </Route>
              <Route path="/survey" element={<Outlet></Outlet>}>
                <Route
                  path="clientReferralSurvey"
                  element={<PublicClientReferralSurvey />}
                ></Route>
                <Route
                  path="eventSurvey"
                  element={<PublicEventSurvey />}
                ></Route>
                <Route
                  path="conference"
                  element={<PublicConferenceSurvey></PublicConferenceSurvey>}
                ></Route>
                <Route
                  path="b2bclient"
                  element={<PublicB2BClientSurvey></PublicB2BClientSurvey>}
                ></Route>
                <Route
                  path="b2bprovider"
                  element={<PublicB2BProviderSurvey></PublicB2BProviderSurvey>}
                ></Route>
              </Route>
              <Route
                path="/users"
                element={<UserManagement></UserManagement>}
              ></Route>
              <Route
                path="/tags"
                element={<TagManagement></TagManagement>}
              ></Route>
              <Route
                path="/reportprint"
                element={<ReportPrint></ReportPrint>}
              ></Route>
            </Route>
          </Routes>
        </div>
      </NavbarProvider>
    </UserContext.Provider>
  );
}

export default App;
