import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ThriveModal from "../common/ThriveModal";
import ThriveTitle from "../common/ThriveTitle";
import { Typography } from "@mui/material";

const PublicB2BProviderSurvey = () => {
  const [clientName, setClientName] = useState("");
  const surveyJson = {
    title: "Professional Business Provider Evaluation Form",
    logoPosition: "right",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "text",
            name: "question1",
            title: "PBP Business Name",
          },
          {
            type: "text",
            name: "question2",
            title: "Small Business Name",
          },
          {
            type: "text",
            name: "question3",
            title: "Small Business Contact Person",
          },
          {
            type: "text",
            name: "question4",
            title: "Provider Name",
          },
          {
            type: "text",
            name: "question5",
            title: "Place of Meeting",
          },
          {
            type: "dropdown",
            name: "question6",
            title: "Time Spent",
            choices: [
              {
                value: "30 minutes",
                text: "30 minutes",
              },
              {
                value: "60 minutes",
                text: "60 minutes",
              },
              {
                value: "90 minutes",
                text: "90 minutes",
              },
              {
                value: "120 minutes",
                text: "120 minutes",
              },
            ],
          },
          {
            type: "text",
            name: "question7",
            title: "Contact Date",
            inputType: "date",
          },
          {
            type: "text",
            name: "question8",
            title: "Today's Date",
            inputType: "date",
          },
        ],
        title: "Contact",
      },
      {
        name: "page2",
        elements: [
          {
            type: "rating",
            name: "question9",
            title: "Was the meeting well planned and executed?",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question10",
            title:
              "Did the Business come prepared with the necessary documents",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question11",
            title: "Was sufficient time allocated for the meeting?",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question12",
            title: 'Was the meeting successful and "as needed"?',
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question13",
            title:
              'Was the meeting "tone and participation level" sufficiently positive and productive?',
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question14",
            title:
              'Were all follow-up actions and "next steps" properly identified, recorded and tracked?',
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
        ],
        title: "Characteristics",
      },
      {
        name: "page3",
        elements: [
          {
            type: "comment",
            name: "question15",
            title: "Services Provided",
          },
          {
            type: "comment",
            name: "question16",
            title: "Next Steps",
          },
        ],
        title: "Goals",
      },
      {
        name: "page4",
        elements: [
          {
            type: "comment",
            name: "question17",
            title: "COMMENTS",
          },
        ],
        title: "Comments and Approval",
      },
    ],
    showProgressBar: "both",
    showTOC: true,
  };

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      // Add JWT token to Authorization header
    },
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/public_result?caseId=${caseId}&referralId=${referralID}&surveyId=655869c8da7bea1936680388`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const surveyStatus = res.data.status;
          setClientName(res.data.client);
          if (surveyStatus === "completed") {
            setOpen(true);
          }
        } else {
          console.log("API Error");
        }
      });
  }, []);

  const survey = new Model(surveyJson);
  survey.data = {
    question2: clientName,
    question8: new Date().toISOString().slice(0, 10),
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId");
  const referralID = queryParams.get("referralId");

  survey.onComplete.add((sender, options) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/survey/public_result`,
        {
          activity_type: "B2B Provider Survey (by Provider)",
          surveyResult: {
            survey_result: sender.data,
            case_id: caseId,
            survey_id: "655869c8da7bea1936680388",
            referral_id: referralID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
        } else {
          console.log("API Error");
        }
      });
  });

  return (
    <>
      <Survey model={survey} />
      <ThriveModal
        open={open}
        contentComponent={
          <>
            <ThriveTitle title={"Notice"} setOpen={setOpen}></ThriveTitle>
            <Typography variant="h4">
              This survey has been submitted, make sure you are opening the
              survey with the correct link sent by BFC.
            </Typography>
          </>
        }
      ></ThriveModal>
    </>
  );
};

export default PublicB2BProviderSurvey;
