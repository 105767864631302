import FieldValueItem from "./FieldValueItem";
import MapContainer from "../Map/MapContainer";

const FirmReportItem = (props: any) => {
  const data = props.data;
  const address = `${data["Address"]}, ${data["City"]}, ${data["State"]}, ${data["ZIP"]}`;

  return (
    <div className="grid ml-3 mr-3 mt-6">
      {/* Basic Info and Firm Address */}
      <div className="grid grid-cols-2 gap-5">
        {/* Basic Info */}
        <div className="col-start-1 col-span-1 grid grid-rows-[50px_1fr_30px]">
          <div className="row-start-1 row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>BASIC INFO</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              <FieldValueItem
                field={"Firm Name:"}
                value={data["Company Name"]}
              />
              <FieldValueItem field={"Contact:"} value={data["Contact"]} />
              <FieldValueItem field={"Title:"} value={data["Title"]} />
              <FieldValueItem field={"Email:"} value={data["Email"]} />
              <FieldValueItem field={"Phone:"} value={data["Phone"]} />
              <FieldValueItem field={"Website:"} value={data["Website"]} />
              <FieldValueItem
                field={"Founding Year:"}
                value={data["Founding Year"]}
              />
            </div>
          </div>
        </div>

        {/* Firm Address */}
        <div className="col-start-2 col-span-1 grid grid-rows-[50px_1fr_30px]">
          <div className="row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>FIRM ADDRESS</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              {/* <FieldValueItem field={"Address:"} value={address} /> */}
              <div className="font-bold mb-2 break-word">{address}</div>
              {/* <FieldValueItem field={"City:"} value={data["City"]} />
              <FieldValueItem field={"State:"} value={data["State"]} />
              <FieldValueItem field={"ZIP:"} value={data["ZIP"]} /> */}
              <MapContainer address={address} />
            </div>
          </div>
        </div>
      </div>

      {/* Business Area */}
      <div className="">
        {/* Business Area */}
        <div className="grid grid-rows-[50px_1fr_30px]">
          <div className="row-start-1 row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>BUSINESS AREA</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              <FieldValueItem
                field={"Company Narrative:"}
                value={data["Company Narrative"]}
              />
              <FieldValueItem field={"Industry:"} value={data["Industry"]} />
              <FieldValueItem
                field={"Legal Entity:"}
                value={data["Legal Entity"]}
              />
              <FieldValueItem
                field={"Annual Revenue:"}
                value={"$" + data["Annual Revenue"]}
              />
              <FieldValueItem
                field={"No. of Employees:"}
                value={data["Employees"]}
              />
              <FieldValueItem
                field={"B2B Accounting:"}
                value={data["B2B Accounting"]}
              />
              <FieldValueItem
                field={"B2B Attorney:"}
                value={data["B2B Attorney"]}
              />
              <FieldValueItem
                field={"B2B Consulting:"}
                value={data["B2B Consulting"]}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Commercial Rating and Miscellaneous */}
      <div className="grid grid-cols-2 gap-5">
        {/* Commercial Rating */}
        <div className="col-start-1 col-span-1 grid grid-rows-[50px_1fr_30px]">
          <div className="row-start-1 row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>COMMERCIAL DATA</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              <FieldValueItem field={"Grant:"} value={data["Grant"]} />
              <FieldValueItem field={"Decision:"} value={data["Decision"]} />
              <FieldValueItem
                field={"Award Amount:"}
                value={data["Award Amount"] && "$" + data["Award Amount"]}
              />

              <FieldValueItem
                field={"PPP Grant:"}
                value={data["PPP Grant"] && "$" + data["PPP Grant"]}
              />
              <FieldValueItem
                field={"EIDL Grant:"}
                value={data["EIDL Grant"] && "$" + data["EIDL Grant"]}
              />
              <FieldValueItem
                field={"Saving Business Saving Lives Grant:"}
                value={
                  data["Saving Business Saving Lives Grant"] &&
                  "$" + data["Saving Business Saving Lives Grant"]
                }
              />
              <FieldValueItem
                field={"Stabilization Grant:"}
                value={
                  data["Stabilization Grant"] &&
                  "$" + data["Stabilization Grant"]
                }
              />
              <FieldValueItem
                field={"MEDC Grant:"}
                value={data["MEDC Grant"] && "$" + data["MEDC Grant"]}
              />
            </div>
          </div>
        </div>

        {/* Miscellaneous */}
        <div className="col-start-2 col-span-1 grid grid-rows-[50px_1fr_30px]">
          <div className="row-start-1 row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>MISCELLANEOUS</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              <FieldValueItem
                field={"Minority-Owned:"}
                value={data["Minority-Owned"]}
              />
              <FieldValueItem
                field={"Veteran-Owned:"}
                value={data["Veteran-Owned"]}
              />
              <FieldValueItem
                field={"Woman-Owned:"}
                value={data["Woman-Owned"]}
              />
              <FieldValueItem
                field={"For-Profit:"}
                value={data["For-Profit"]}
              />
              <FieldValueItem
                field={"Home-based:"}
                value={data["Home-based"]}
              />
              <FieldValueItem
                field={"In-Person Sales:"}
                value={data["In-Person Sales"]}
              />
              <FieldValueItem
                field={"Use Local Vendors:"}
                value={data["Use Local Vendors"]}
              />
              <FieldValueItem
                field={"Governmental Contacts (Senator, Rep, Commissioner):"}
              />
              <FieldValueItem field={"Chamber of Commmerce:"} />
            </div>
          </div>
        </div>
      </div>

      {/* Notes */}
      <div className="hidden print:block">
        {/* Business Area */}
        <div className="grid grid-rows-[50px_1fr_30px]">
          <div className="row-start-1 row-span-1 text-2xl bg-magenta text-white flex items-center">
            <div className="ml-3">
              <h1>Notes</h1>
            </div>
          </div>
          <div className="row-start-2 ml-4 mt-3">
            <div className="flex flex-col text-left">
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
              <hr className="my-6 border-gray-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmReportItem;
