import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import SearchIcon from "@mui/icons-material/Search";
import ThriveModal from "../../common/ThriveModal";
import ThriveCaseSelection from "../../common/ThriveCaseSelection";
import NoticeDialog from "../../common/NoticeDialog";
import { NavLink } from "react-router-dom";
import { EventType } from "../../../interfaces";
import EmailEditor from "../../common/EmailEditor";
import { EventInvitation } from "../../common/EmailBodies/EventInvitation";
const Events = (props: any) => {
  // const [availableTags, setAvailableTags] = useState([]);
  // const [displayTags, setDisplayTags] = useState([]);
  // const [tagSearchTerm, setTagSearchTerm] = useState("");
  // const [checkedTags, setCheckedTags] = useState([]);

  // const [availableEventCategories, setAvailableEventCategories] = useState([]);
  // const [checkedEventCategories, setChekcedEventCategories] = useState([]);
  const [availableCases, setAvailableCases] = useState([]);
  const [caseActivities, setCaseActivities] = useState([]);
  const [checkedCases, setCheckedCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState({ _id: "", firm: "" });
  const [availableEvents, setAvailableEvents] = useState<EventType[]>([]);
  const [displayEvents, setDisplayEvents] = useState<EventType[]>([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [displayCases, setDisplayCases] = useState<any>([]);
  const [dataFlag, setDataFlag] = useState(false);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/events`, axiosConfig)
      .then((res) => {
        setAvailableEvents(res.data.events);
        setDisplayEvents(res.data.events);
      });
  }, []);

  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        const cases = res.data.cases.filter((caseItem: any) => {
          return true;
        });
        setAvailableCases(cases);
        let case_id: string = "";
        for (let item of cases) {
          case_id = case_id + item._id + ",";
        }

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/activity/massive_get`,
            { case_id: case_id },
            axiosConfig
          )
          .then((res) => {
            setCaseActivities(res.data.activities);
          });
      });
  }, []);

  const handleEventSearch = (e: any) => {
    setDisplayEvents(
      availableEvents.filter((event: EventType) => {
        return (event.name.text + event.description.text)
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };

  const handleSendInvitation = () => {
    // console.log(displayCases);
    setInvitationOpen(false);
    setEditorOpen(true);
  };

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        {/* <Box sx={{ width: "30%" }}>
          <Stack>
            <Box>Tag Search Bar</Box>

            <Box>Tag List</Box>
          </Stack>
        </Box> */}

        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <TextField
              label="Search Events..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleEventSearch}
            ></TextField>
            <NavLink to="/dashboard/pastevents">
              <Button sx={{ color: "#b3197a" }}>Past Events and Surveys</Button>
            </NavLink>

            {/* <Box>Event Category</Box> */}
            <Stack>
              {displayEvents.map((event: EventType, index: number) => {
                return (
                  <EventCard
                    key={index}
                    event={event}
                    displayButton={true}
                    setInvitationOpen={setInvitationOpen}
                    setSelectedEvent={setSelectedEvent}
                  ></EventCard>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <ThriveModal
        open={invitationOpen}
        setOpen={setInvitationOpen}
        closeByClick={true}
        contentComponent={
          <ThriveCaseSelection
            cases={availableCases}
            displayCases={displayCases}
            setDisplayCases={setDisplayCases}
            caseActivities={caseActivities}
            checkedCases={checkedCases}
            setCheckedCases={setCheckedCases}
            selectedCase={selectedCase}
            setSelectedCase={setSelectedCase}
            setOpen={setInvitationOpen}
            enableControl={true}
            enableSelection={true}
            enableMultiSelection={true}
            title={"Send Event Invitation"}
            status_default="open"
            sortby_default="recent"
            handleNext={handleSendInvitation}
          ></ThriveCaseSelection>
        }
      ></ThriveModal>
      <ThriveModal
        height={750}
        open={editorOpen}
        setOpen={setEditorOpen}
        closeByClick={true}
        contentComponent={
          <EmailEditor
            setOpen={setEditorOpen}
            theCase={{}}
            theFirm={{}}
            theReferral={{}}
            editorTitle={"Event Invitation"}
            theSubject={"Event Invitation"}
            theSelectedEvent={selectedEvent}
            theSelectedProvider={{}}
            bodyGenerator={EventInvitation}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            checkedCases={checkedCases}
            displayCases={displayCases}
            theBFC={{}}
          ></EmailEditor>
        }
      ></ThriveModal>
      <ThriveModal
        open={messageOpen}
        setOpen={setMessageOpen}
        closeByClick={true}
        height={200}
        width={500}
        contentComponent={
          <NoticeDialog
            title={"Email Sent!"}
            setOpen={setMessageOpen}
            setDataFlag={setDataFlag}
          ></NoticeDialog>
        }
      ></ThriveModal>
    </Box>
  );
};

export default Events;
