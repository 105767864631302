import React, { useEffect } from "react";
import UserProfile from "../User/UserProfile";
import { validate } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import DashboardBFC from "./Dashboard_BFC";

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    validate().then((res) => {
      if (!res) {
        navigate("/");
      }
    });
  }, [navigate]);
  return (
    <>
      <DashboardBFC />
    </>
  );
};

export default Dashboard;
