import { useEffect, useState } from "react";
import WizardContent from "../Dashboard/WizardContent";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { UserType } from "../../interfaces";
import ThriveTitle from "./ThriveTitle";

interface Props {
  selectedUser: any;
  setSelectedUser: any;
  currentUser: any;
  handleNext: any;
  setOpen: any;
}

const ThriveUserSelection: React.FC<Props> = ({
  selectedUser,
  setSelectedUser,
  currentUser,
  handleNext,
  setOpen,
}: Props) => {
  const [availableUsers, setAvailableUsers] = useState<any>([]);

  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  // const [availableTags, setAvailableTags] = React.useState<any>([]);
  useEffect(() => {
    if (currentUser.role === "admin") {
      axios
        .get(`${process.env.REACT_APP_API_DOMAIN}/user/allUsers`, axiosConfig)
        .then((res) => {
          const users = res.data.users as UserType[];
          const userNames = users.map((user: any) => {
            return {
              label: user.firstName + " " + user.lastName,
              id: user._id,
            };
          });
          setAvailableUsers(
            userNames.sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            )
          );
        });
    }
  }, []);

  return (
    <>
      <ThriveTitle
        title="Select Business Forward Consultant"
        setOpen={setOpen}
      ></ThriveTitle>
      <div className="flex justify-center" style={{ marginTop: "200px" }}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          placeholder="Select Business Forward Consultant"
          value={selectedUser}
          style={{
            border: "1px solid",
            marginTop: "20px",
            width: "80%",
            margin: "auto",
          }}
          id="tags-standard"
          options={availableUsers}
          onChange={(event, value) => {
            setSelectedUser(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Business Forward Consultant"
              value={selectedUser}
            />
          )}
        />
      </div>
      <Box sx={{ width: "70%", margin: "auto", marginTop: "250px" }}>
        <Stack direction="row" spacing={2} justifyContent={"space-between"}>
          <Button variant="contained" onClick={handleNext}>
            Create Visit Plan
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default ThriveUserSelection;
