import glossary from "../../../assets/files/Glossary.pdf";
const Glossary = () => {
  // const handlePrint = () => {
  //   window.print();
  // };
  return (
    <div>
      <a href={glossary} target="_blank">
        <button className="bg-magenta hover:bg-yellow text-white font-bold py-3 px-7 rounded shadow m-8 hover:cursor-pointer">
          Click here for Glossary
        </button>
      </a>
    </div>
  );
};

export default Glossary;
