import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import ReportTable from "./ReportTable";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
dayjs.extend(utc);
dayjs.extend(dayOfYear);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  page_title: {
    fontSize: 24,
    color: "white",
    backgroundColor: "rgb(158, 31, 99)",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 15,
    paddingVertical: 10,
    fontWeight: "bold", // Bold font weight
    textTransform: "uppercase", // Uppercase text
  },
  section_title: {
    fontSize: 18,
    color: "black",
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
    paddingVertical: 5,
    fontWeight: "bold", // Bold font weight for section titles
    backgroundColor: "#e0e0e0", // Light grey background color
    borderLeftWidth: 6, // Left border
    borderLeftColor: "rgb(158, 31, 99)", // Border color matching the page title background
  },
});

const AllBFCReport = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const startDate = props.startDate;
  const endDate = props.endDate;

  const [reportData, setReportData] = useState<any>({});
  const startDateString = startDate ? startDate.format("YYYY-MM-DD") : "";
  const endDateShow = endDate || dayjs();
  const endDateString = endDateShow.format("YYYY-MM-DD");
  const [dataFlag, setDataFlag] = useState(false);

  useEffect(() => {
    if (startDate === null && endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/allBfcReport`,
          axiosConfig
        )
        .then((result) => {
          setReportData(result.data);
          setDataFlag(true);
        });
    } else if (startDate !== null && endDate === null) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_DOMAIN
          }/user/allBfcReport?startDate=${startDate.toDate()}`,
          axiosConfig
        )
        .then((result) => {
          setReportData(result.data);
          setDataFlag(true);
        });
    } else {
      axios
        .get(
          `${
            process.env.REACT_APP_API_DOMAIN
          }/user/allBfcReport?startDate=${startDate.toDate()}&endDate=${endDate.toDate()}`,
          axiosConfig
        )
        .then((result) => {
          setReportData(result.data);
          setDataFlag(true);
        });
    }
  }, []);
  if (dataFlag) {
    return (
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="LETTER" style={styles.page} key="reportData">
            <View>
              <Text style={styles.page_title}>Report Data</Text>
              {startDate && (
                <Text style={styles.section_title}>
                  {startDateString} to {endDateString}
                </Text>
              )}
            </View>
            <ReportTable data={reportData} />
          </Page>
        </Document>
      </PDFViewer>
    );
  } else {
    return <></>;
  }
};

export default AllBFCReport;
