import React from "react";
import Box from "@mui/material/Box";
import styles from "../../assets/styles/user_profile.module.css";
import Stack from "@mui/material/Stack";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Typography from "@mui/material/Typography";
// import ActivityCount from "../Dashboard/ActivityCount";

const UserProfile = (props: any) => {
  const { user } = useContext(UserContext);
  let displayUser;
  if (props.user) {
    displayUser = props.user;
  } else {
    displayUser = user;
  }
  const getUserTitle = (user: any) => {
    let userTitle = "";
    switch (user.role) {
      case "admin":
        userTitle = "Administrator";
        break;
      case "bfc":
        userTitle = "Business Forward Consultant";
        break;
      case "client":
        userTitle = "Business Owner";
        break;
      default:
        userTitle = "User";
    }
    return userTitle;
  };

  return (
    <Box
      sx={{
        width: "100%",
        // backgroundColor: "#9e1f63",
        // borderRadius: { md: "10px" },
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
      className={styles.profile_container}
    >
      <Stack direction="row" spacing={2}>
        {/* <Box
          sx={{
            backgroundColor: "white",
            width: "120px",
            height: "120px",
            margin: "10px",
          }}
        >
          <img
            className={styles.profile_img}
            src={`${process.env.REACT_APP_API_DOMAIN}/images/avatar_example.png`}
            alt={"User Avatar"}
          />
        </Box> */}
        <Box className={styles.profile_content}>
          <Typography
            sx={{ color: "#FDB814", fontSize: "12px" }}
            variant={"h6"}
          >
            {getUserTitle(displayUser)}
          </Typography>
          <Typography variant={"h5"} color={"white"}>
            {displayUser.firstName} {displayUser.lastName}
          </Typography>
          <Typography variant={"subtitle1"} sx={{ color: "white" }}>
            {displayUser.email}
          </Typography>
        </Box>
        {/*TODO: Explore method to display the statistics of BFC inside user profile */}
        {/*<ActivityCount/>*/}
      </Stack>
    </Box>
  );
};

export default UserProfile;
