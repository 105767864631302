import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { StyledButtonMain } from "../common/Buttons";
import ThriveModal from "../common/ThriveModal";
import { useEffect, useState } from "react";
import UserEditor from "./UserEditor";
import axios from "axios";
import Cookies from "js-cookie";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [userEditorOpen, setUserEditorOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({ password: "" });

  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/user/allUsers`, axiosConfig)
      .then((res) => {
        setUsers(
          res.data.users.sort((a: any, b: any) =>
            a.firstName > b.firstName ? 1 : -1
          )
        );
      });
  }, []);
  const handleAddUser = () => {
    setUserEditorOpen(true);
    setEditingUser({ password: "" });
  };

  const handleEditUser = (user: any) => {
    setUserEditorOpen(true);
    setEditingUser(user);
  };

  return (
    <Box>
      <Stack spacing={2}>
        <StyledButtonMain variant="contained" onClick={handleAddUser}>
          Add New User
        </StyledButtonMain>
        <Grid container spacing={2}>
          {users.map((user: any) => {
            return (
              <Grid key={user._id} item xs={3} sx={{ cursor: "pointer" }}>
                <Card
                  sx={{ padding: "20px" }}
                  onClick={() => {
                    handleEditUser(user);
                  }}
                >
                  <Typography>
                    {user.role === "admin"
                      ? "Admin"
                      : "Business Forward Consultant"}
                  </Typography>

                  <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                  <Typography>
                    {user.last_login
                      ? `Last Login:${user.last_login.slice(0, 10)} `
                      : ""}
                  </Typography>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
      <ThriveModal
        open={userEditorOpen}
        setOpen={setUserEditorOpen}
        contentComponent={
          <UserEditor
            setOpen={setUserEditorOpen}
            user={editingUser}
          ></UserEditor>
        }
      ></ThriveModal>
    </Box>
  );
};

export default UserManagement;
