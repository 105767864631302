import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface DateFormFilterProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (startDate: Dayjs | null) => void;
  setEndDate: (endDate: Dayjs | null) => void;
  onReset: () => void;
}

const DateFilterForm: React.FC<DateFormFilterProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onReset,
}) => {
  const shouldDisableEndDate = (date: Dayjs) => {
    if (startDate === null) {
      return true;
    }
    return date < startDate;
  };

  return (
    <div className="flex">
      <div className="">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            disableFuture={true}
          />
        </LocalizationProvider>
      </div>
      <div className="ml-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            shouldDisableDate={shouldDisableEndDate}
          />
        </LocalizationProvider>
      </div>
      <div className="ml-3 bg-magenta hover:bg-yellow text-white font-bold py-4 px-7 rounded shadow hover:cursor-pointer">
        <button onClick={() => onReset()}>Reset</button>
      </div>
    </div>
  );
};

export default DateFilterForm;
