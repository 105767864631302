import { useEffect } from "react";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { VisualizationPanel } from "survey-analytics";
import "survey-analytics/survey.analytics.min.css";

const SurveyJsPanel = (props: any) => {
  const vizPanelOptions = {
    allowHideQuestions: false,
    haveCommercialLicense: true,
    allowDragDrop: false,
    allowDynamicLayout: false,
  };
  let surveyContent = props.surveyContent;
  let surveyResult = props.surveyResult;
  let surveyTitle = props.surveyTitle;

  if (
    surveyTitle === "Event Survey Report" &&
    surveyContent.elements &&
    surveyResult.length > 0
  ) {
    //Get distinct q1 values of the survey result
    const q1Values = surveyResult.map((result: any) => result["q1"]);
    surveyContent["elements"][1]["choices"] = q1Values;
  }

  const new_survey = new Model(surveyContent);
  // setSurvey(new_survey);

  const new_vizPanel = new VisualizationPanel(
    new_survey.getAllQuestions(),
    surveyResult,
    vizPanelOptions
  );
  // setVizPanel(new_vizPanel);

  useEffect(() => {
    if (new_vizPanel) {
      new_vizPanel.render("surveyVizPanel");
      return () => {
        if (document.getElementById("surveyVizPanel")) {
          document.getElementById("surveyVizPanel")!.innerHTML = "";
        }
      };
    }
  }, [new_vizPanel]);

  return <div ref={props.pdfref} id="surveyVizPanel"></div>;
};

export default SurveyJsPanel;
