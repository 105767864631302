import { useLocation } from "react-router-dom";
import SurveyJsPanel from "./SurveyJsPanel";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { get } from "http";
import { Box } from "@mui/material";

const token: string | undefined = Cookies.get("token");
const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
  },
};

const ReportPrint = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportId = queryParams.get("reportId");
  const [surveyContent, setSurveyContent] = useState<any>(null);
  const [surveyResult, setSurveyResult] = useState<any[]>([]);
  // console.log(reportId);

  const refineSurveyContent = (surveyContent: any) => {
    if (surveyContent.pages) {
      for (const pageContent of surveyContent.pages) {
        const pageTitle = pageContent.title;
        for (const elementContent of pageContent.elements) {
          elementContent.title = pageTitle + " - " + elementContent.title;
        }
      }
    }
    return surveyContent;
  };

  const getSurvey = async (survey_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/survey?id=${survey_id}`,
      axiosConfig
    );
    const refinedSurveyContent = refineSurveyContent(
      response.data.survey.survey_content
    );
    // console.log("survey content is: ", refinedSurveyContent);
    setSurveyContent(refinedSurveyContent);
  };

  const getSurveyResult = async (survey_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=${survey_id}`,
      axiosConfig
    );
    // console.log("survey results are: ", response.data.results);
    setSurveyResult(
      response.data.results.map((result: any) => result.survey_result)
    );
    return response.data.results;
  };

  useEffect(() => {
    getSurvey(reportId);
    getSurveyResult(reportId);
  }, [reportId]);

  // console.log(reportId);
  // console.log(surveyContent, surveyResult);
  return (
    <Box sx={{ maxWidth: "960px" }}>
      {surveyContent && surveyResult ? (
        <SurveyJsPanel
          surveyContent={surveyContent}
          surveyResult={surveyResult}
        ></SurveyJsPanel>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default ReportPrint;
