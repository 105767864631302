import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import Cookies from "js-cookie";
import axios from "axios";
import React, { useEffect } from "react";

const SurveyResultViewer = (props: any) => {
  const [surveyJson, setSurveyJson] = React.useState<any>({});
  const [surveyValue, setSurveyValue] = React.useState<any>({});
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const theActivity = props.activity;
  const title = props.title;

  useEffect(() => {
    if (props.activity) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/survey?id=${theActivity.survey_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setSurveyJson(res.data.survey.survey_content);
            // const surveyJson = res.data.survey.survey_content;
            // survey = new Model(surveyJson);
          }
        });
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/survey/result?survey_result_id=${theActivity.survey_result_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setSurveyValue(res.data.survey_result);
          } else {
            console.log("API Error at getting survey result");
          }
        });
    } else {
      console.log("API Error at getting survey result");
    }
  }, [props.activity]);

  const survey = new Model(surveyJson);
  survey.data = surveyValue;
  survey.onComplete.add((sender, options) => {
    props.setSurveyOpen(false);
  });

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          {title}
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setSurveyOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>

      <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 520 } }}>
        <Survey model={survey} />
      </Box>
    </>
  );
};

export default SurveyResultViewer;
