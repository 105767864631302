import * as React from "react";
import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
// import MenuIcon from '@mui/icons-material/Menu';
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from '@mui/icons-material/Adb';
import { logout, validate } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import UserProfile from "../User/UserProfile";
import { Stack } from "@mui/material";
// const pages = ["Products", "Pricing", "Blog"];
const settings = [
  { name: "Logout", role: "all" },
  { name: "User Management", role: "admin" },
  { name: "Tag Management", role: "admin" },
];
function User() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    validate().then((res) => {
      if (res) {
        setValidated(res);
      }
    });
  }, [navigate]);
  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //     setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    const chosenItem = event.currentTarget.innerText;
    switch (chosenItem) {
      case "Logout": {
        logout();
        setValidated(false);
        navigate("/");
        window.location.reload();
        break;
      }
      case "User Management": {
        if (user.role === "admin") {
          navigate("/users");
        }
        break;
      }
      case "Tag Management": {
        if (user.role === "admin") {
          navigate("/tags");
        }
        break;
      }
      default: {
      }
    }
  };

  const logoClickHandler = () => {
    navigate("/");
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: { xs: "#9D2362", md: "#9D2362" } }}
    >
      <Container maxWidth="xl">
        <Toolbar style={{ height: "90px" }}>
          <div className="flex justify-center items-center -ml-6">
            <img
              style={{ height: "90px" }}
              src={`${process.env.REACT_APP_API_DOMAIN}/images/Thrive_logo.png`}
              alt={"Logo"}
              onClick={logoClickHandler}
              className="hover:cursor-pointer"
            />
          </div>
          {process.env.REACT_APP_API_DOMAIN ===
            "http://23.26.250.185:12300" && (
            <Box sx={{ textAlign: "center", width: "70%" }}>
              <Typography sx={{ fontSize: "48px", fontWeight: "bold" }}>
                STAGING SITE
              </Typography>
            </Box>
          )}

          {/*<Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>*/}
          {/*    <IconButton*/}
          {/*        size="large"*/}
          {/*        aria-label="account of current user"*/}
          {/*        aria-controls="menu-appbar"*/}
          {/*        aria-haspopup="true"*/}
          {/*        onClick={handleOpenNavMenu}*/}
          {/*        color="inherit"*/}
          {/*    >*/}
          {/*        <MenuIcon/>*/}
          {/*    </IconButton>*/}
          {/*    <Menu*/}
          {/*        id="menu-appbar"*/}
          {/*        anchorEl={anchorElNav}*/}
          {/*        anchorOrigin={{*/}
          {/*            vertical: 'bottom',*/}
          {/*            horizontal: 'right',*/}
          {/*        }}*/}
          {/*        keepMounted*/}
          {/*        transformOrigin={{*/}
          {/*            vertical: 'top',*/}
          {/*            horizontal: 'right',*/}
          {/*        }}*/}
          {/*        open={Boolean(anchorElNav)}*/}
          {/*        onClose={handleCloseNavMenu}*/}
          {/*        sx={{*/}
          {/*            display: {xs: 'block', md: 'none'},*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        /!*{pages.map((page) => (*!/*/}
          {/*        /!*    <MenuItem key={page} onClick={handleCloseNavMenu}>*!/*/}
          {/*        /!*        <Typography textAlign="center">{page}</Typography>*!/*/}
          {/*        /!*    </MenuItem>*!/*/}
          {/*        /!*))}*!/*/}
          {/*    </Menu>*/}
          {/*</Box>*/}
          {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img
              style={{ height: "64px" }}
              src={`${process.env.REACT_APP_API_DOMAIN}/images/Thrive_logo.png`}
              alt={"Logo"}
            />
          </Box> */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/*{pages.map((page) => (*/}
            {/*    <Button*/}
            {/*        key={page}*/}
            {/*        onClick={handleCloseNavMenu}*/}
            {/*        sx={{my: 2, color: 'white', display: 'block'}}*/}
            {/*    >*/}
            {/*        {page}*/}
            {/*    </Button>*/}
            {/*))}*/}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          {validated && (
            <Box sx={{ flexGrow: 0 }}>
              <Stack direction={"row"}>
                <UserProfile></UserProfile>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="" src="">
                      {user["firstName"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Stack>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => {
                  if (setting.role === "all" || setting.role === user.role) {
                    return (
                      <MenuItem
                        key={setting.name}
                        onClick={handleCloseUserMenu}
                      >
                        <Typography
                          textAlign="center"
                          onClick={handleUserMenuItem}
                        >
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default User;
