import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import SearchIcon from "@mui/icons-material/Search";
import ThriveModal from "../../common/ThriveModal";

import { NavLink } from "react-router-dom";
import { SurveyContent } from "../../Survey/PublicEventSurvey";
import CloseIcon from "@mui/icons-material/Close";

const PastEvents = (props: any) => {
  // const [availableTags, setAvailableTags] = useState([]);
  // const [displayTags, setDisplayTags] = useState([]);
  // const [tagSearchTerm, setTagSearchTerm] = useState("");
  // const [checkedTags, setCheckedTags] = useState([]);

  // const [availableEventCategories, setAvailableEventCategories] = useState([]);
  // const [checkedEventCategories, setChekcedEventCategories] = useState([]);
  const [availableCases, setAvailableCases] = useState([]);

  const [availableEvents, setAvailableEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const [surveyResults, setSurveyResults] = useState<any>([]);
  const [surveyResultOpen, setSurveyResultOpen] = useState(false);
  const [openSurvey, setOpenSurvey] = useState("");
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/events/past`, axiosConfig)
      .then((res) => {
        setAvailableEvents(res.data.events);
        setDisplayEvents(res.data.events);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        const cases = res.data.cases.filter((caseItem: any) => {
          return caseItem.status === "open";
        });
        setAvailableCases(cases);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=65412693f9ecc07bf529219f`,
        axiosConfig
      )
      .then((res) => {
        setSurveyResults(res.data.results);
      });
  }, []);

  const handleEventSearch = (e: any) => {
    setDisplayEvents(
      availableEvents.filter((event: any) => {
        return (event.name.text + event.description.text)
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };

  const handleSendInvitation = () => {
    setInvitationOpen(false);
    setEditorOpen(true);
  };

  let mappedResults: any = {};

  if (surveyResults.length != 0) {
    surveyResults.forEach((result: any) => {
      let surveyTitle = result.survey_result.q1.split(" - ")[1];
      if (mappedResults[surveyTitle]) {
        mappedResults[surveyTitle].push(result);
      } else {
        mappedResults[surveyTitle] = [result];
      }
    });
  }

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        {/* <Box sx={{ width: "30%" }}>
          <Stack>
            <Box>Tag Search Bar</Box>

            <Box>Tag List</Box>
          </Stack>
        </Box> */}

        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Typography variant="h3">Previous Events</Typography>
            <NavLink to="/dashboard/events">
              <Button sx={{ color: "#b3197a" }}>See Upcoming Events</Button>
            </NavLink>
            <TextField
              label="Search Events..."
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleEventSearch}
            ></TextField>

            {/* <Box>Event Category</Box> */}

            <Stack>
              {displayEvents.map((event: any, index: number) => {
                return (
                  <EventCard
                    key={index}
                    event={event}
                    setInvitationOpen={setInvitationOpen}
                    setSelectedEvent={setSelectedEvent}
                    surveyResults={mappedResults[event.name.text]}
                    openSurvey={openSurvey}
                    setOpenSurvey={setOpenSurvey}
                    setSurveyResultOpen={setSurveyResultOpen}
                  ></EventCard>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <ThriveModal
        open={surveyResultOpen}
        setOpen={setSurveyResultOpen}
        contentComponent={
          <>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "#9E1F63FF",
                padding: "3px",
                margin: 0,
                color: "white",
                borderRadius: "5px 5px 0 0",
                marginBottom: "20px",
              }}
            >
              <Typography sx={{ display: "inline-block" }} variant="h4">
                Event Survey Result
              </Typography>
              <Box
                sx={{ display: "inline", float: "right", cursor: "pointer" }}
                onClick={() => {
                  setSurveyResultOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
              </Box>
            </Box>
            <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 570 } }}>
              <SurveyContent survey_id={openSurvey}></SurveyContent>
            </Box>
          </>
        }
      ></ThriveModal>
    </Box>
  );
};

export default PastEvents;
