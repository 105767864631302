import * as React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import axios from "axios";
import zipcodes from "../../assets/zipcode";
import cities from "../../assets/city";
import Cookies from "js-cookie";
import FirmListing from "./FirmListing/FirmListing";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../interfaces";
import WizardContent from "./WizardContent";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

type bidTargeting = {
  bfc: any;
  setAsides: any[];
  city: any[];
  zipcode: any[];
  bussType: string[];
  subBussType: string[];
  emergOrEst: string;
  grantStatus: string;
  visitPlan: any[];
  // reportType: null | string
};

const steps = [
  "CONSULTANT",
  "OWNERSHIP/ CLASSIFICATION",
  "LOCATION",
  "BUSINESS TYPE",
  "BUSINESS STAGE",
  "GRANT STATUS",
  "FIRMS",
  "VISIT",
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 40,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 80,
  height: 80,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/consultant.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    2: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/set_asides.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    3: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/location.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    4: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/bussiness_type.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    5: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/emerOrEsta.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    6: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/grant_status.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    7: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/report.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
    8: (
      <img
        src={`${process.env.REACT_APP_API_DOMAIN}/images/stepper/visit_plan.png`}
        width={80}
        height={80}
        alt="Step"
      />
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function FirmWizard() {
  const theme = useTheme();
  const [user, setUser] = React.useState<UserType>(() => {
    return JSON.parse(localStorage.getItem("user") || "{}");
  });
  const [firmData, setFirmData] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [theForm, setTheForm] = React.useState({
    bfc: { label: user.firstName + " " + user.lastName, id: user._id },
    setAsides: ["No Preference"],
    city: [""],
    zipcode: [""],
    bussType: [],
    subBussType: [],
    emergOrEst: "No Preference",
    grantStatus: "Approved",
    visitPlan: [""],
  } as bidTargeting);
  const [selectedRow, setSelectedRow] = React.useState<any>([]);
  const [showOnlyAvailableFirms, setShowOnlyAvailableFirms] =
    React.useState<boolean>(false);
  const navigate = useNavigate();

  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  let selectedRoww: any = [...selectedRow];
  const handleFirmSelect = (data: any) => {
    selectedRoww = data;
  };

  const exportFirms = (firms: any) => {
    let csvContent: any[] = [];
    let csvHeading = [
      "thriveID",
      "Company Name",
      "Contact",
      "Email",
      "Phone",
      "Website",
      "Address",
      "City",
      "ZIP",
      "Founding Year",
      "Company Narrative",
      "primaryIndustry",
      "secondaryIndustry",
      "Annual Revenue",
      "Employees",
      "Woman-Owned",
      "Veteran-Owned",
      "Minority-Owned",
      "firmScore",
      "totalGrantsValue",
    ];
    csvContent.push(csvHeading);
    for (const firm of firms) {
      let csvRow = [];
      for (const item of csvHeading) {
        if (firm[item]) {
          csvRow.push(firm[item]);
        } else {
          csvRow.push("");
        }
      }
      csvContent.push(csvRow);
    }

    let csvString = ``;
    for (const row of csvContent) {
      let rowString = `\n`;
      for (const item of row) {
        rowString = rowString + `"${item}",`;
      }
      csvString = csvString + rowString;
    }

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a hidden anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "firms_export.csv";
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  //Consultant
  function StepOne(theForm: bidTargeting) {
    const [availableUsers, setAvailableUsers] = React.useState<any>([]);

    // const [availableTags, setAvailableTags] = React.useState<any>([]);
    React.useEffect(() => {
      if (user.role === "admin") {
        axios
          .get(`${process.env.REACT_APP_API_DOMAIN}/user/allUsers`, axiosConfig)
          .then((res) => {
            const users = res.data.users as UserType[];
            const userNames = users.map((user: any) => {
              return {
                label: user.firstName + " " + user.lastName,
                id: user._id,
              };
            });
            setAvailableUsers(
              userNames.sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
              )
            );
          });
      }
    }, []);

    return (
      <>
        <div className="flex justify-center">
          <WizardContent heading={"Choose a Business Consultant"}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              placeholder="Select Business Forward Consultant"
              value={theForm["bfc"]}
              style={{
                border: "1px solid",
                marginTop: "20px",
                width: "80%",
                margin: "auto",
              }}
              id="tags-standard"
              options={availableUsers}
              onChange={(event, value) => {
                setTheForm({ ...theForm, bfc: value! });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Business Forward Consultant"
                  value={theForm["bfc"]}
                />
              )}
            />
          </WizardContent>
        </div>
      </>
    );
  }

  //Set Aside
  function StepTwo(theForm: bidTargeting) {
    return (
      <div className="flex justify-center">
        <WizardContent heading={"Choose Ownership/Specialty Classification"}>
          <FormControl
            sx={{ m: 6 }}
            component="fieldset"
            variant="standard"
            style={{ marginTop: 0 }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="No Preference"
                    checked={theForm["setAsides"].includes("No Preference")}
                  />
                }
                label="No Preference"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="Woman-Owned"
                    checked={theForm["setAsides"].includes("Woman-Owned")}
                  />
                }
                label="Woman-Owned"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="Minority-Owned"
                    checked={theForm["setAsides"].includes("Minority-Owned")}
                  />
                }
                label="Minority-Owned"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="Veteran-Owned"
                    checked={theForm["setAsides"].includes("Veteran-Owned")}
                  />
                }
                label="Veteran-Owned"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="For-Profit"
                    checked={theForm["setAsides"].includes("For-Profit")}
                  />
                }
                label="For-Profit"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="setasides"
                    value="Non-Profit"
                    checked={theForm["setAsides"].includes("Non-Profit")}
                  />
                }
                label="Non-Profit"
              />
            </FormGroup>
          </FormControl>
        </WizardContent>
      </div>
    );
  }

  //Location
  function StepThree(theForm: bidTargeting) {
    return (
      <div className="flex justify-center">
        <WizardContent heading={"Where are the firms located?"}>
          <Autocomplete
            value={theForm["zipcode"].filter(function (item) {
              return item !== "";
            })}
            multiple
            id="tags-standard"
            options={zipcodes}
            onChange={(event, value) => {
              let mappedResult: Array<string> = value.map((item) => {
                return item.split(" ")[0];
              });
              setTheForm({ ...theForm, zipcode: mappedResult });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Zip Code"
                placeholder="Zip Code"
                value={theForm["zipcode"]}
                onChange={handleChange}
              />
            )}
            style={{
              width: "80%",
              margin: "auto",
            }}
          />
          <Autocomplete
            value={theForm["city"].filter(function (item) {
              return item !== "";
            })}
            multiple
            id="tags-standard"
            options={cities}
            onChange={(event, value) => {
              let mappedResult: Array<string> = value.map((item) => {
                return item;
              });
              setTheForm({ ...theForm, city: mappedResult });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="City"
                placeholder="City"
                value={theForm["city"]}
                onChange={handleChange}
              />
            )}
            style={{
              width: "80%",
              margin: "auto",
            }}
          />
        </WizardContent>
      </div>
    );
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(
    name: string,
    assistance_requested: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        assistance_requested.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleBussClick = (e: any) => {
    let selectedBussType: any;
    if (e.target.getAttribute("data-value")) {
      selectedBussType = e.target.getAttribute("data-value");
    }
    if (e.target.value) {
      selectedBussType = e.target.value;
    }
    let tempBussType = [...theForm.bussType];
    let tempSubBussType = [...theForm.subBussType];

    if (tempBussType.indexOf(selectedBussType) === -1) {
      tempBussType.push(selectedBussType);
    } else {
      tempBussType = tempBussType.filter((x) => x !== selectedBussType);
      tempSubBussType = tempBussType.filter(
        (x) => !x.includes(selectedBussType)
      );
    }

    setTheForm({
      ...theForm,
      bussType: tempBussType,
      subBussType: tempSubBussType,
    });
  };

  const handleSubBussClick = (e: any) => {
    let selectedSubBussType: any;
    if (e.target.getAttribute("data-value")) {
      selectedSubBussType = e.target.getAttribute("data-value");
    }
    if (e.target.value) {
      selectedSubBussType = e.target.value;
    }
    let tempBussType = [...theForm.subBussType];
    if (tempBussType.indexOf(selectedSubBussType) === -1) {
      tempBussType.push(selectedSubBussType);
    } else {
      tempBussType = tempBussType.filter((x) => x !== selectedSubBussType);
    }
    setTheForm({ ...theForm, subBussType: tempBussType });
  };

  const showSecondaryCategory = (bussType: any) => {
    let shownSecondaryCategoty = [];
    if (bussType.includes("Business Services")) {
      shownSecondaryCategoty.push(
        "Business Services - Landscaping, extermination, janitorial and housekeeping services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Printing, market research, photography, advertising, public relations and related services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Computer systems design, software, programming services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Interior, industrial, graphic, and other specialized design services"
      );
    }
    if (bussType.includes("Entertainment and Recreation")) {
      shownSecondaryCategoty.push(
        "Entertainment and Recreation - Golf courses, skiing facilities, other recreation industries"
      );
      shownSecondaryCategoty.push(
        "Entertainment and Recreation - Yoga studios, gyms, fitness studios, personal trainers"
      );
    }
    if (bussType.includes("Personal Services")) {
      shownSecondaryCategoty.push("Personal Services - Childcare");
      shownSecondaryCategoty.push(
        "Personal Services - Barber shops, hair and nail salons"
      );
      shownSecondaryCategoty.push(
        "Personal Services - Diet, weight loss, spas, massage, other personal care services"
      );
    }
    if (bussType.includes("Retail")) {
      shownSecondaryCategoty.push(
        "Retail - Bookstores, hardware, anything sold that is not food"
      );
      shownSecondaryCategoty.push(
        "Retail - Clothing, shoe, sporting goods, resale and consignment stores"
      );
      shownSecondaryCategoty.push(
        "Retail - Jewelry, art, hobby, musical instrument, pet supply and other miscellaneous retail stores"
      );
      shownSecondaryCategoty.push(
        "Retail - Pharmacies, cosmetics, optical goods, other health and personal care stores"
      );
      shownSecondaryCategoty.push("Retail - Groceries");
    }

    return (
      <>
        {shownSecondaryCategoty.length > 0 ? (
          <FormControl style={{ width: "90%", marginTop: "20px" }}>
            <InputLabel>Secondary Business Type</InputLabel>
            <Select
              label={"Secondary Business Type"}
              multiple
              value={theForm.subBussType}
              input={<OutlinedInput label="Secondary Business Type" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {shownSecondaryCategoty.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, theForm.subBussType, theme)}
                  onClick={handleSubBussClick}
                >
                  <Checkbox
                    value={name}
                    checked={theForm.subBussType.indexOf(name) > -1}
                    // onChange={handleBussChange}
                  />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
      </>
    );
  };
  const primaryBussTypes = [
    "Accommodations - Bed and breakfast, or any other lodging facility",
    "Business Services",
    "Construction and Maintenance Services - Grading, excavating, other construction and maintenance services",
    "Engineering, Design and Development",
    "Entertainment and Recreation",
    "Food",
    "Healthcare - chiropractors, dental, health clinics",
    "Maintenance & Repair - automobile repair, automobile dealership, cellular phone repair, landscaping and lawn maintenance",
    "Nonprofit - churches, religious centers, advocacy organizations, etc.",
    "Other",
    "Manufacturing",
    "Personal Services",
    "Professional & Other Services - taxes, consulting, publishing, marketing",
    "Retail",
    "Technology",
    "Transportation - limousine services, etc.",
  ];

  //Business Type
  function StepFour(theForm: bidTargeting) {
    return (
      <div className="flex justify-center">
        <WizardContent heading={"What Business Types would you like to visit?"}>
          <FormControl style={{ width: "90%" }}>
            <InputLabel>Primary Business Type</InputLabel>
            <Select
              label={"Primary Business Type"}
              multiple
              value={theForm.bussType}
              // onChange={handleBussChange}

              input={<OutlinedInput label="Primary Business Type" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {primaryBussTypes.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, theForm.bussType, theme)}
                  onClick={handleBussClick}
                >
                  <Checkbox
                    value={name}
                    checked={theForm.bussType.indexOf(name) > -1}
                    // onChange={handleBussChange}
                  />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showSecondaryCategory(theForm["bussType"])}
        </WizardContent>
      </div>
    );
  }

  //Emerging or Established
  function StepFive(theForm: bidTargeting) {
    return (
      <div className="flex justify-center">
        <WizardContent heading={"What Business Stage should the firms be at?"}>
          <FormControl style={{ width: "100%" }}>
            <RadioGroup
              style={{ margin: "auto" }}
              value={theForm["emergOrEst"]}
              onChange={handleChange}
            >
              <div className="flex items-center">
                <FormControlLabel
                  name="emergOrEst"
                  value="Emerging"
                  control={<Radio />}
                  label="Emerging"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Typically represents companies that have been in business
                    2-5 years, has $25k-$250k annual revenue, and 0-10
                    employees.
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <FormControlLabel
                  name="emergOrEst"
                  value="Established"
                  control={<Radio />}
                  label="Established"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Typically represents companies that have been in business
                    more than 5 years, has more than $250k annual revenue, and
                    over 11 employees.
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <FormControlLabel
                  name="emergOrEst"
                  value="No Preference"
                  control={<Radio />}
                  label="No Preference"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Search for all companies regardless of their business stage.
                  </span>
                </div>
              </div>
            </RadioGroup>
          </FormControl>
        </WizardContent>
      </div>
    );
  }

  //Grant Status
  function StepSix(theForm: bidTargeting) {
    return (
      <div className="flex justify-center">
        <WizardContent heading={"What is the desired Grant Status?"}>
          <FormControl style={{ width: "100%" }}>
            <RadioGroup
              defaultValue={
                theForm["grantStatus"] ? theForm["grantStatus"] : "N/A"
              }
              style={{ margin: "auto" }}
              value={theForm["grantStatus"]}
              onChange={handleChange}
            >
              <div className="flex items-center">
                <FormControlLabel
                  name="grantStatus"
                  value="Approved"
                  control={<Radio />}
                  label="Approved"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Filters the resulting firms to those that applied for any
                    CARES Act grant, and were approved for at least one type.
                    The approved grant types and amounts should be shown in the
                    firm’s details (if that data was available). To view that
                    info, click any firm in the results list and it will be
                    shown in the popup box that appears.
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <FormControlLabel
                  name="grantStatus"
                  value="Rejected"
                  control={<Radio />}
                  label="Rejected"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Filters the resulting firms to those that applied for CARES
                    Act grant(s), but were Rejected.
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <FormControlLabel
                  name="grantStatus"
                  value="N/A"
                  control={<Radio />}
                  label="N/A"
                />
                <div className="group">
                  <InfoIcon
                    sx={{ color: "#0F95B3" }}
                    className="group-hover:cursor-pointer"
                  />
                  <span className="absolute ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                    Doesn’t filter. Resulting firms appear whether or not they
                    Applied, were Approved, or were Rejected for a CARES Act
                    grant.
                  </span>
                </div>
              </div>
            </RadioGroup>
          </FormControl>
        </WizardContent>
      </div>
    );
  }

  //Firms
  function StepSeven() {
    let displayFirmList: any;
    if (showOnlyAvailableFirms) {
      displayFirmList = firmData["firms"].filter((item: any, index: number) => {
        return !item["openCase"];
      });
    } else {
      displayFirmList = firmData["firms"];
    }
    return (
      <>
        <div>
          <input
            type="checkbox"
            id="showOnlyAvailableFirms"
            checked={showOnlyAvailableFirms}
            onChange={() => setShowOnlyAvailableFirms(!showOnlyAvailableFirms)}
          />
          <label htmlFor="showOnlyAvailableFirms" className="ml-1">
            Show only Available Firms
          </label>
        </div>
        <Box sx={{ width: "120%", marginLeft: "-10%", marginTop: "30px" }}>
          <Typography
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              cursor: "pointer",
              color: "#006d86",
            }}
            onClick={() => {
              exportFirms(displayFirmList);
            }}
          >
            Export Firm Result
          </Typography>
          <FirmListing
            selectedRow={selectedRow}
            handleFirmSelect={handleFirmSelect}
            firmData={{ firms: displayFirmList }}
            selectable={true}
            showOnlyAvailableFirms={showOnlyAvailableFirms}
          />
        </Box>
      </>
    );
  }

  //Visit
  function StepEight(theForm: bidTargeting) {
    let displayFirmList: any;
    if (showOnlyAvailableFirms) {
      displayFirmList = firmData["firms"].filter((item: any, index: number) => {
        return !item["openCase"];
      });
    } else {
      displayFirmList = firmData["firms"];
    }
    return (
      <>
        {selectedRow.length > 0 ? (
          <>
            <Typography
              sx={{
                textAlign: "right",
                textDecoration: "underline",
                cursor: "pointer",
                color: "#006d86",
              }}
              onClick={() => {
                exportFirms(
                  displayFirmList.filter((item: any, index: number) => {
                    return selectedRow.includes(index);
                  })
                );
              }}
            >
              Export Firm Result
            </Typography>
            <FirmListing
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              firmData={{
                firms: displayFirmList.filter((item: any, index: number) => {
                  return selectedRow.includes(index);
                }),
              }}
              selectable={false}
            />
          </>
        ) : (
          <>Please select firm(s) from pervious step</>
        )}
      </>
    );
  }

  function getStepContent(theForm: bidTargeting, step: number) {
    switch (step) {
      case 0:
        return StepOne(theForm);
      case 1:
        return StepTwo(theForm);
      case 2:
        return StepThree(theForm);
      case 3:
        return StepFour(theForm);
      case 4:
        return StepFive(theForm);
      case 5:
        return StepSix(theForm);
      case 6:
        return StepSeven();
      case 7:
        return StepEight(theForm);
      default:
        return StepOne(theForm);
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // console.log(activeStep);
    if (activeStep === 6) {
      setSelectedRow(selectedRoww);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/firms/firm_listing`,
        theForm,
        axiosConfig
      )
      .then((res) => {
        setFirmData(res.data);
        setActiveStep(activeStep + 1);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).name === "setasides") {
      let currentSetAsides: Array<string> = theForm["setAsides"];
      if (
        currentSetAsides.includes("No Preference") &&
        currentSetAsides.length === 1
      ) {
        currentSetAsides = currentSetAsides.filter((item: string) => {
          if (item !== "No Preference") {
            return item;
          } else {
            return false;
          }
        });
        setTheForm({ ...theForm, setAsides: currentSetAsides });
      }
      if ((event.target as HTMLInputElement).value === "No Preference") {
        if ((event.target as HTMLInputElement).checked) {
          setTheForm({ ...theForm, setAsides: ["No Preference"] });
        }
      } else {
        if (
          currentSetAsides.includes((event.target as HTMLInputElement).value)
        ) {
          if ((event.target as HTMLInputElement).checked) {
            // console.log("element already exists");
          } else {
            // console.log("removing element");
            currentSetAsides = currentSetAsides.filter((item: string) => {
              if (item !== (event.target as HTMLInputElement).value) {
                return item;
              } else {
                return false;
              }
            });
            setTheForm({ ...theForm, setAsides: currentSetAsides });
          }
        } else {
          if ((event.target as HTMLInputElement).checked) {
            // console.log("adding element");
            currentSetAsides.push((event.target as HTMLInputElement).value);
            setTheForm({ ...theForm, setAsides: currentSetAsides });
          } else {
            // console.log("removing non-existing?");
          }
        }
      }

      if (currentSetAsides.length === 0) {
        setTheForm({ ...theForm, setAsides: ["No Preference"] });
      }
    }

    //handling Bussiness Type
    if ((event.target as HTMLInputElement).name === "bussType") {
      let currentBussTypes: Array<string> = theForm["bussType"];
      let value = (event.target as HTMLInputElement).value;
      let checked = (event.target as HTMLInputElement).checked;

      //inserting value
      if (!currentBussTypes.includes(value) && checked) {
        currentBussTypes.push(value);
      }

      //removing value
      if (currentBussTypes.includes(value) && !checked) {
        currentBussTypes = currentBussTypes.filter((item: string) => {
          if (item !== value) {
            return item;
          } else {
            return false;
          }
        });
      }

      setTheForm({
        ...theForm,
        bussType: currentBussTypes,
      });
    }

    //handling step 5: Emerging or Established
    if ((event.target as HTMLInputElement).name === "emergOrEst") {
      setTheForm({
        ...theForm,
        emergOrEst: (event.target as HTMLInputElement).value,
      });
    }

    //handling Step 6: Grant Status
    if ((event.target as HTMLInputElement).name === "grantStatus") {
      setTheForm({
        ...theForm,
        grantStatus: (event.target as HTMLInputElement).value,
      });
    }

    //handling Step 7: Visit Planner
    if ((event.target as HTMLInputElement).name === "visitPlan") {
      let currentVisitPlans: Array<string> = theForm["visitPlan"];
      let value = (event.target as HTMLInputElement).value;
      let checked = (event.target as HTMLInputElement).checked;

      //inserting value
      if (!currentVisitPlans.includes(value) && checked) {
        currentVisitPlans.push(value);
      }

      //removing value
      if (currentVisitPlans.includes(value) && !checked) {
        currentVisitPlans = currentVisitPlans.filter((item: string) => {
          if (item !== value) {
            return item;
          } else {
            return false;
          }
        });
      }

      setTheForm({
        ...theForm,
        visitPlan: currentVisitPlans,
      });
    }
  };

  const handleVisitPlan = () => {
    let displayFirmList: any[];
    if (showOnlyAvailableFirms) {
      displayFirmList = firmData["firms"].filter((item: any, index: number) => {
        return !item["openCase"];
      });
    } else {
      displayFirmList = firmData["firms"];
    }
    const data = selectedRow.map((row: number) => {
      return { _id: displayFirmList[row]["_id"] };
    });

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases`,
        { firms: data, bfc: theForm["bfc"]["id"] },
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
      });
    navigate("/firmReport", {
      state: { firmData: { firms: displayFirmList }, indices: selectedRow },
    });
  };

  const NextButton = () => {
    return (
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              mr: 1,
              backgroundColor: activeStep === 0 ? "#00FF00" : "#9e1f63",
              color: activeStep === 0 ? "#00FF00" : "#fdb814",
              "&:hover": {
                backgroundColor: "#9e1f63",
              },
            }}
            variant="contained"
            component="span"
          >
            {"<Back"}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            variant="contained"
            component="span"
            onClick={handleNext}
            style={{ backgroundColor: "#9e1f63", color: "#fdb814" }}
          >
            {"Next>>>>"}
          </Button>
        </Box>
        {getStepContent(theForm, activeStep)}
      </React.Fragment>
    );
  };

  const GenerateVisitPlanButton = () => {
    return (
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              mr: 1,
              backgroundColor: activeStep === 0 ? "#00FF00" : "#9e1f63",
              color: activeStep === 0 ? "#00FF00" : "#fdb814",
              "&:hover": {
                backgroundColor: "#9e1f63",
              },
            }}
            variant="contained"
            component="span"
          >
            {"<Back"}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            component="span"
            onClick={handleVisitPlan}
            style={
              selectedRow.length === 0
                ? {
                    backgroundColor: "#9e1f63",
                    color: "#fdb814",
                    display: "none",
                  }
                : { backgroundColor: "#9e1f63", color: "#fdb814" }
            }
          >
            Generate Visit Plan
          </Button>
        </Box>
        {getStepContent(theForm, activeStep)}
      </React.Fragment>
    );
  };

  const GenerateFirmListing = () => {
    return (
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              mr: 1,
              backgroundColor: activeStep === 0 ? "#00FF00" : "#9e1f63",
              color: activeStep === 0 ? "#00FF00" : "#fdb814",
              "&:hover": {
                backgroundColor: "#9e1f63",
              },
            }}
            variant="contained"
            component="span"
          >
            {"<Back"}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            component="span"
            onClick={handleSubmit}
            style={{ backgroundColor: "#9e1f63", color: "#fdb814" }}
          >
            Generate Firm Listing
          </Button>
        </Box>
        {getStepContent(theForm, activeStep)}
      </React.Fragment>
    );
  };

  const WizardButtons = (props: any) => {
    switch (props.activeStep) {
      case 0:
        return NextButton();
      case 1:
        return NextButton();
      case 2:
        return NextButton();
      case 3:
        return NextButton();
      case 4:
        return NextButton();
      case 5:
        return GenerateFirmListing();
      case 6:
        return NextButton();
      case 7:
        return GenerateVisitPlanButton();
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className="">
        <div style={{ width: "80%", margin: "auto", paddingTop: "30px" }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (steps.indexOf(label) <= 5) {
                      setActiveStep(steps.indexOf(label));
                    } else {
                      setActiveStep(5);
                    }
                  }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <WizardButtons activeStep={activeStep} />
        </div>
      </div>
    </>
  );
}

export default FirmWizard;
