import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { assistances } from "./Interaction";

import ThriveModal from "../common/ThriveModal";
import Confirm from "./Confirm";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import EmailEditor from "../common/EmailEditor";
import { ResourceReferralClientSurvey } from "../common/EmailBodies/ResourceReferralClientSurvey";
import { StyledTextarea } from "../common/Textareas";

const Referral = (props: any) => {
  const [surveyEditorOpen, setSurveyEditorOpen] = useState<boolean>(false);
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const [referralContent, setReferralContent] = React.useState({
    resource_id: "",
    provider_id: "",
    referral_objective: "No Response",
    referral_note: "",
    case_id: "",
    _id: "",
    referral_date: dayjs(),
  });
  const [resource, setResource] = React.useState({
    id: "",
    title: "",
    description: "",
    url: "",
    tags: "",
    imagePath: "",
    orgName: "",
    address: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = React.useState<string>("");
  const [messageOpen, setMessageOpen] = React.useState<boolean>(false);
  const [messageTitle, setMessageTitle] = React.useState<string>("");
  const getResourceById = (resource_id: any) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/resources?resource_id=${resource_id}`,
        axiosConfig
      )
      .then((res) => {
        setResource(res.data.resource);
      });
  };

  const getProviderById = (provider_id: any) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/resources?provider_id=${provider_id}`,
        axiosConfig
      )
      .then((res) => {
        setResource(res.data.provider);
      });
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/referral?referral_id=${props.referral}`,
        axiosConfig
      )
      .then((res) => {
        setReferralContent({
          ...res.data.referralContent,
        });
        if (res.data.referralContent.resource_id) {
          getResourceById(res.data.referralContent.resource_id);
        }
        if (res.data.referralContent.provider_id) {
          getProviderById(res.data.referralContent.provider_id);
        }
      });
  }, []);

  const handleSaveReferral = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/referral`,
        {
          activity_type: "Referral",
          referral: referralContent,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          props.setDataFlag(!props.dataFlag);
        } else {
          console.log("API Error");
        }
      });
  };

  const handleDelete = () => {
    // Check if referralContent._id exists before making the API call
    if (referralContent._id) {
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/referral/delete`,
          {
            activity_type: "Referral",
            referral: referralContent,
          },
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            props.setDataFlag(!props.dataFlag);
          } else {
            console.log("API Error");
          }
        });
    }
  };

  const client_survey_result = (referral_id: any) => {
    if (referralContent) {
      let client_survey_activity = props.activity.find((item: any) => {
        return (
          item.activity_type === "Client Referral Survey (by Client)" &&
          item.referral_id === referral_id
        );
      });
      if (client_survey_activity) {
        return client_survey_activity._id === referralContent._id;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const client_notice_sent = () => {
    let client_referral_notice_activity = props.activity.find((item: any) => {
      return (
        (item.activity_type === "Referral Notice" ||
          item.activity_type === "Provider Referral Notice Email") &&
        item.referral_id === referralContent._id
      );
    });

    if (client_referral_notice_activity) {
      return true;
    } else {
      return false;
    }
  };

  const b2b_client_survey_result = (referral_id: any) => {
    let client_survey_activity = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey (by Client)" &&
        item.referral_id === referral_id
      );
    });
    if (client_survey_activity) {
      return true;
    } else {
      return false;
    }
  };

  const b2b_client_survey_button_color = (referral_id: any) => {
    let client_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let client_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey (by Client)" &&
        item.referral_id === referral_id
      );
    });
    if (client_survey_completed) {
      return "success";
    } else if (client_survey_sent) {
      return "warning";
    } else {
      return "primary";
    }
  };

  const b2b_client_survey_button_variant = (referral_id: any) => {
    let client_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let client_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey (by Client)" &&
        item.referral_id === referral_id
      );
    });
    if (client_survey_completed) {
      return "contained";
    } else if (client_survey_sent) {
      return "contained";
    } else {
      return "outlined";
    }
  };

  const b2b_client_survey_button_text = (referral_id: any) => {
    let client_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let client_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey (by Client)" &&
        item.referral_id === referral_id
      );
    });
    if (client_survey_completed) {
      return "B2B Client Survey Complete";
    } else if (client_survey_sent) {
      return "B2B Client Survey Sent";
    } else {
      return "Send Client Survey";
    }
  };

  const ButtonGroupForResourceReferral = (button_props: any) => {
    return (
      <>
        <Button
          variant={client_notice_sent() ? "contained" : "outlined"}
          color={client_notice_sent() ? "success" : "primary"}
          sx={{ width: "100%" }}
          onClick={() => {
            props.setReferralNoticeOpen(true);
            props.setOpenReferral(resource);
            props.setReferralNoticeContent(referralContent);
          }}
        >
          {client_notice_sent()
            ? "Referral Notice Sent"
            : "Send Referral Notice"}
        </Button>

        {client_survey_result(button_props.referral_id) ? (
          <>
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={() => setSurveyEditorOpen(true)}
            >
              Client Referral Survey
            </Button>
          </>
        ) : (
          <>
            <Button
              color="success"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={() => {
                let client_survey_activity = props.activity.find(
                  (item: any) => {
                    return (
                      item.activity_type ===
                      "Client Referral Survey (by Client)"
                    );
                  }
                );
                props.setActivityCardOpen(true);
                props.setOpenActivityID(client_survey_activity._id);
              }}
            >
              View Referral Survey
            </Button>
          </>
        )}
      </>
    );
  };

  const b2b_provider_survey_result = (referral_id: any) => {
    let provider_survey_activity = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey (by Provider)" &&
        item.referral_id === referral_id
      );
    });
    if (provider_survey_activity) {
      return true;
    } else {
      return false;
    }
  };

  const b2b_provider_survey_button_color = (referral_id: any) => {
    let b2b_provider_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let b2b_provider_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey (by Provider)" &&
        item.referral_id === referral_id
      );
    });
    if (b2b_provider_survey_completed) {
      return "success";
    } else if (b2b_provider_survey_sent) {
      return "warning";
    } else {
      return "primary";
    }
  };

  const b2b_provider_survey_button_variant = (referral_id: any) => {
    let b2b_provider_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let b2b_provider_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey (by Provider)" &&
        item.referral_id === referral_id
      );
    });
    if (b2b_provider_survey_completed) {
      return "contained";
    } else if (b2b_provider_survey_sent) {
      return "contained";
    } else {
      return "outlined";
    }
  };

  const b2b_provider_survey_button_text = (referral_id: any) => {
    let b2b_provider_survey_sent = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey Email" &&
        item.referral_id === referral_id
      );
    });
    let b2b_provider_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey (by Provider)" &&
        item.referral_id === referral_id
      );
    });
    if (b2b_provider_survey_completed) {
      return "B2B Provider Survey Complete";
    } else if (b2b_provider_survey_sent) {
      return "B2B Provider Survey Sent";
    } else {
      return "Send B2B Provider Survey";
    }
  };

  const openB2BProviderSurvey = () => {
    let b2b_provider_survey_activity = props.activity.find((item: any) => {
      return item.activity_type === "B2B Provider Survey (by Provider)";
    });
    props.setActivityCardOpen(true);
    props.setOpenActivityID(b2b_provider_survey_activity.activity_id);
  };

  const openB2BProviderSurveyEmail = () => {
    props.setProviderSurveyOpen(true);
    props.setReferralNoticeContent(referralContent);
    props.setSelectedProvider(resource);
  };

  const openB2BClientSurvey = () => {
    let b2b_client_survey_activity = props.activity.find((item: any) => {
      return item.activity_type === "B2B Client Survey (by Client)";
    });

    props.setActivityCardOpen(true);
    props.setOpenActivityID(b2b_client_survey_activity.activity_id);
  };

  const openB2BClientSurveyEmail = () => {
    props.setClientSurveyOpen(true);
    props.setReferralNoticeContent(referralContent);
  };

  const ButtonGroupForProviderReferral = (button_props: any) => {
    return (
      <>
        <Button
          color={client_notice_sent() ? "success" : "primary"}
          variant={client_notice_sent() ? "contained" : "outlined"}
          sx={{ width: "100%" }}
          onClick={() => {
            props.setProviderReferralNoticeOpen(true);
            props.setReferralNoticeContent(referralContent);
          }}
        >
          {client_notice_sent()
            ? "Provider Notice Sent"
            : "Send Provider Notice"}
        </Button>
        <Button
          color={b2b_client_survey_button_color(button_props.referral_id)}
          variant={b2b_client_survey_button_variant(button_props.referral_id)}
          sx={{ width: "100%" }}
          onClick={() => {
            b2b_client_survey_result(button_props.referral_id)
              ? openB2BClientSurvey()
              : openB2BClientSurveyEmail();
          }}
        >
          {b2b_client_survey_button_text(button_props.referral_id)}
        </Button>
        <Button
          color={b2b_provider_survey_button_color(button_props.referral_id)}
          variant={b2b_provider_survey_button_variant(button_props.referral_id)}
          onClick={() => {
            b2b_provider_survey_result(button_props.referral_id)
              ? openB2BProviderSurvey()
              : openB2BProviderSurveyEmail();
          }}
        >
          {b2b_provider_survey_button_text(button_props.referral_id)}
        </Button>
      </>
    );
  };

  const provider_referral_completion_indicator = (referral_id: any) => {
    let b2b_provider_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Provider Survey (by Provider)" &&
        item.referral_id === referral_id
      );
    });
    let client_survey_completed = props.activity.find((item: any) => {
      return (
        item.activity_type === "B2B Client Survey (by Client)" &&
        item.referral_id === referral_id
      );
    });

    if (b2b_provider_survey_completed && client_survey_completed) {
      return `B2B Engagement Completed (${
        new Date(b2b_provider_survey_completed.created_at) >
        new Date(client_survey_completed.created_at)
          ? new Date(b2b_provider_survey_completed.created_at).toLocaleString(
              "en-US",
              {
                timeZone: "EST",
              }
            )
          : new Date(client_survey_completed.created_at).toLocaleString(
              "en-US",
              {
                timeZone: "EST",
              }
            )
      })`;
    } else if (b2b_provider_survey_completed) {
      return "B2B Provider Survey Completed";
    } else if (client_survey_completed) {
      return "B2B Client Survey Completed";
    } else {
      return "Referral Processing";
    }
  };
  return (
    <>
      <Box
        style={{
          textAlign: "left",
          color: `${referralContent["resource_id"] ? "#9E1F63FF" : "#fdb814"}`,
          backgroundColor: `${
            referralContent["resource_id"] ? "#fdb814" : "#9E1F63FF"
          }`,
          padding: "3px",
          margin: 0,
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography
          sx={{ display: "inline-block", marginLeft: "10px", fontSize: "28px" }}
        >
          {referralContent["resource_id"] ? "Resource" : "Provider"} Referral{" "}
          {"- " +
            provider_referral_completion_indicator(referralContent["_id"])}
        </Typography>
        <Typography
          sx={{
            float: "right",
            marginRight: "10px",
            fontSize: "28px",
            fontWeight: "bold",
          }}
        >
          {resource["orgName"]}
        </Typography>
        {/* {props.user.role === "admin" || props.user.role === "bfc" ? (
          <Button
            sx={{
              display: "inline-block",
              float: "right",
              marginTop: "3px",
              backgroundColor: "#fdb814",
              color: "black",
              "&:hover": {
                backgroundColor: "#ffd061",
                color: "gray",
              },
            }}
            variant="contained"
            // onClick={handleEditClick}
          >
            Edit
          </Button>
        ) : (
          <></>
        )} */}
      </Box>
      <Grid container sx={{ height: "380px" }}>
        <Grid item md={3}>
          <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <img
              src={resource.imagePath}
              width={250}
              alt="Resource Image"
              style={{ margin: "auto" }}
            ></img>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Stack>
            <Box
              // direction={"row"}
              // spacing={2}
              sx={{
                textAlign: "left",
                margin: "20px",
                marginBottom: 0,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ display: "inline-block" }}
                >
                  <DatePicker
                    sx={{ width: "150px" }}
                    label="Referral Date"
                    value={dayjs(referralContent.referral_date)}
                    onChange={(value) => {
                      setReferralContent({
                        ...referralContent,
                        referral_date: value as Dayjs,
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <FormControl
                sx={{ width: "560px", marginTop: "8px", marginLeft: "20px" }}
              >
                <InputLabel id="demo-multiple-chip-label">
                  Referral Objective
                </InputLabel>
                <Select
                  label="Referral Objective"
                  onChange={(e: any) => {
                    setReferralContent({
                      ...referralContent,
                      referral_objective: e.target.value,
                    });
                  }}
                  value={referralContent.referral_objective}
                >
                  {assistances.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ margin: "20px" }}>
              <StyledTextarea
                sx={{ width: "100%" }}
                minRows={8}
                maxRows={10}
                placeholder="Referral Notes"
                onChange={(e: any) => {
                  setReferralContent({
                    ...referralContent,
                    referral_note: e.target.value,
                  });
                }}
                value={referralContent.referral_note}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item md={2}>
          <Stack
            spacing={2}
            sx={{ padding: "10px", marginTop: "10px" }}
            useFlexGap
          >
            {referralContent["resource_id"] ? (
              <ButtonGroupForResourceReferral
                referral_id={referralContent["_id"]}
              ></ButtonGroupForResourceReferral>
            ) : (
              <ButtonGroupForProviderReferral
                referral_id={referralContent["_id"]}
              ></ButtonGroupForProviderReferral>
            )}

            <Button
              size="large"
              variant="contained"
              sx={{
                width: "100%",
                fontSize: "28px",
                backgroundColor: "#9E1F63FF",
              }}
              onClick={handleSaveReferral}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={() => {
                setConfirmMessage("Are you sure to delete the Referral?");
                setConfirmOpen(true);
              }}
            >
              Unassign
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <ThriveModal
        height={750}
        open={surveyEditorOpen}
        setOpen={setSurveyEditorOpen}
        contentComponent={
          <EmailEditor
            setOpen={setSurveyEditorOpen}
            theCase={props.case}
            theFirm={props.firm}
            theReferral={referralContent}
            theSubject={
              "Business Forward - Referral Survey for " +
              props.firm["Company Name"]
            }
            editorTitle={"Client Survey - Resource"}
            bodyGenerator={ResourceReferralClientSurvey}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            theBFC={{}}
            theSelectedEvent={{}}
            theSelectedProvider={{}}
          ></EmailEditor>
        }
      ></ThriveModal>
      <Confirm
        isOpen={confirmOpen}
        setCardOpen={setConfirmOpen}
        message={confirmMessage}
        confirmAction={handleDelete}
      />
    </>
  );
};

export default Referral;
