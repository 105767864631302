import { Margin, usePDF } from "react-to-pdf";
import SurveyJsPanel from "../SurveyJsPanel";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const RGA_report = () => {
  const [cities, setCities] = useState<string[]>([]);
  const [data1, setData1] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/reports/rga`, axiosConfig)
      .then((response: any) => {
        setCities(response.data.city);
        setData1(response.data.data1);
        setData2(response.data.data2);
      });
  }, []);

  const surveyContent1 = {
    elements: [
      {
        name: "city",
        title:
          "Recovery Grant Applicants (RGA round 1 and 2) by community/city",
        type: "dropdown",
        choices: cities,
      },
    ],
    showQuestionNumbers: "off",
    completedHtml: "Thank you for your feedback!",
  };

  const surveyContent2 = {
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "matrix",
            name: "city",
            title: "RGA with an “Initiate Contact”",
            columns: ["Approved", "Rejected"],
            rows: cities,
          },
        ],
      },
    ],
  };
  const surveyResult1: any[] = data1;
  const surveyResult2: any[] = data2;

  const { toPDF, targetRef } = usePDF({
    filename: "Report.pdf",
    page: { margin: Margin.SMALL, format: "letter" },
    overrides: {
      canvas: {
        removeContainer: false,
      },
    },
  });

  return (
    <>
      <Box sx={{ position: "absolute", marginTop: "-42px" }}>
        <Button
          sx={{ marginLeft: "10px" }}
          color={"warning"}
          variant="contained"
          onClick={() => toPDF()}
        >
          Print
        </Button>
      </Box>
      <SurveyJsPanel
        surveyTitle={"RGA Report"}
        pdfref={targetRef}
        surveyContent={surveyContent1}
        surveyResult={surveyResult1}
      ></SurveyJsPanel>
      <SurveyJsPanel
        surveyTitle={"RGA Report"}
        pdfref={targetRef}
        surveyContent={surveyContent2}
        surveyResult={surveyResult2}
      ></SurveyJsPanel>
    </>
  );
};

export default RGA_report;
