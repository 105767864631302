import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";

const Confirm = (props: any) => {

    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            props.setCardOpen(false);
          }
        };
    
        const handleClickOutside = (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          if (target && !target.closest('.modal-container')) {
            props.setCardOpen(false);
          }
        };
    
        if (props.isOpen) {
          document.addEventListener('keydown', handleEscapeKey);
          document.addEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKey);
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [props.isOpen]);
    
  if (!props.isOpen) return null;

    return (
    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 flex items-center justify-center z-50">
    <div className="modal-overlay" onClick={()=>props.setCardOpen(false)} />
    <div className="modal-container bg-white w-[30%] rounded-lg shadow-lg h-[30%] flex justify-center">
        <div className="flex flex-col justify-center items-center">
            <div className="text-lg">{props.message}</div>
            <div className="flex">
            <button
                className="mt-4 mb-4 bg-magenta hover:bg-yellow text-white px-4 py-2 rounded"
                onClick={() => props.confirmAction()}
            >
                Yes
            </button>
            <button
                className="mt-4 mb-4 bg-magenta hover:bg-yellow text-white px-4 py-2 rounded ml-2"
                onClick={() => props.setCardOpen(false)}
            >
                No
            </button>
            </div>
        </div>
    </div>
  </div>
    );

};
export default Confirm;