const WizardContent = (props: any) => {
  return (
    <div className="w-[745px] min-h-[452px] h-auto mt-4 bg-white rounded-lg shadow-md inset-0">
      <div className="w-[743px] h-[70px] bg-blueGreen mb-10 rounded-t-lg text-white text-2xl font-bold flex items-center justify-center">
        {props.heading}
      </div>
      {props.children}
    </div>
  );
};

export default WizardContent;
