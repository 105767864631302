import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Resource from "./Resource";
import TextField from "@mui/material/TextField";
import axios from "axios";
import ThriveModal from "../../common/ThriveModal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import Tags from "./Tags";
import ThriveCaseSelection from "../../common/ThriveCaseSelection";
import ThriveResourceEditor from "../../common/ThriveResourceEditor";
import { StyledButtonMain } from "../../common/Buttons";
import FactSheetsList from "./FactsheetsList";
import ResourceNotes from "./ResourceNotes";
import ResourceHis from "./ResourceHis";
export interface resourceData {
  _id: string;
  id: string;
  title: string;
  description: string;
  url: string;
  tags: string[];
  imagePath: string;
  orgName: string;
  address: string;
  typeOfResource: string;
  poc: string;
  phone: string;
  email: string;
}

export interface tagsData {
  tag: string;
}

export interface categoryWiseTagsData {
  name: string;
  tags: string[];
}

export interface resourceTag {
  name: string;
  category: string;
}

interface ResourceStatics {
  [tag: string]: number;
}

function ResourceList(props: any) {
  // const tags = props.availableTags.filter((tag: any) => {
  //   if (!(tag.value == "HIDDEN" && !props.user)) {
  //     return tag;
  //   }
  // });
  // Edit reousrce states
  const [resourceEditorOpen, setResourceEditorOpen] = useState(false);

  const navigate = useNavigate();
  const [selectedResource, setSelectedResource] = useState(null);

  const [cases, setCases] = useState([]);
  const [displayCases, setDisplayCases] = useState<any>([]);
  const [caseActivities, setCaseActivities] = useState([]);
  const [editingResource, setEditingResource] = useState({});
  const [listingResource, setListingResources] = useState<resourceData[]>([]);
  const [cardOpen, setCardOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [availableResources, setAvailableResources] = useState<resourceData[]>(
    []
  );
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [checkedTags, setCheckedTags] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [checkedCases, setCheckedCases] = useState<number[]>([]);
  const [selectedCase, setSelectedCase] = useState({ _id: "", firm: "" });
  const [resourceFactsheetOpen, setResourceFactsheetOpen] = useState(false);
  const [resourceNotesOpen, setResourceNotesOpen] = useState(false);
  const [resourceTypes, setResourceTypes] = useState<string[]>([]);
  const [checkedResourceTypes, setCheckedResourceTypes] = useState<{
    [key: string]: boolean;
  }>({});

  const [resourceStatics, setResourceStatics] = useState<ResourceStatics>({});
  const [selectUnselectAll, setSelectUnselectAll] = useState<boolean>(true);
  const [hisOpen, setHisOpen] = useState(false);
  const [hisResource, setHisResource] = useState<resourceData | null>(null);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios.get(`${process.env.REACT_APP_API_DOMAIN}/resources`).then((res) => {
      setAvailableResources(res.data.resources);
      const typeOfResources = res.data.resources
        .map((resource: resourceData) => resource.typeOfResource)
        .sort();
      setResourceTypes(Array.from(new Set(typeOfResources)));
    });

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        setCases(res.data.cases);
        let case_id: string = "";
        for (let item of res.data.cases) {
          case_id = case_id + item._id + ",";
        }

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/activity/massive_get`,
            { case_id: case_id },
            axiosConfig
          )
          .then((res) => {
            setCaseActivities(res.data.activities);
          });
      });
  }, [openEdit]);

  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/tags`, axiosConfig)
      .then((res) => {
        setAvailableTags(res.data.tags);
        setCheckedTags(new Array(res.data.tags.length).fill(false));
      });
  }, []);

  useEffect(() => {
    const checkedResourceTypeMap: { [key: string]: boolean } = {};
    resourceTypes.forEach((value: string) => {
      checkedResourceTypeMap[value] = true;
    });
    setCheckedResourceTypes(checkedResourceTypeMap);
  }, [resourceTypes]);

  useEffect(() => {
    calResourceStatics();
    const allChecked = Object.values(checkedResourceTypes).every(
      (value) => value === true
    );
    if (allChecked) {
      setSelectUnselectAll(true);
    } else {
      setSelectUnselectAll(false);
    }
  }, [checkedResourceTypes]);

  //Keep tracking on the checkTags
  useEffect(() => {
    setListing();
  }, [checkedTags, availableResources, checkedResourceTypes]);

  const calResourceStatics = () => {
    let tempResourceStatics: ResourceStatics = {};
    if (availableResources.length > 0) {
      availableResources
        .filter((resource) => checkedResourceTypes[resource.typeOfResource])
        .map((resource: resourceData) => {
          if (resource.tags) {
            resource.tags.map((tag: string) => {
              if (!(tag in tempResourceStatics)) {
                tempResourceStatics[tag] = 0;
              }
              // @ts-ignore
              tempResourceStatics[tag] += 1;
              return null;
            });
          }
          return null;
        });
    }
    setResourceStatics(tempResourceStatics);
  };

  const handleTagChange = (tagIndex: number) => {
    const updatedCheckedTags = checkedTags.map((item: any, index: number) =>
      index === tagIndex ? !item : item
    );
    setSearchQuery("");
    setCheckedTags(updatedCheckedTags);
  };

  if (!listingResource) return <p>No profile data</p>;

  function setListing() {
    if (availableResources) {
      let tagFlag: boolean = true;
      checkedTags.map((tag: boolean) => {
        tagFlag = tagFlag && !tag;
        return null;
      });
      if (tagFlag) {
        setListingResources(
          availableResources.filter(
            (resource) => checkedResourceTypes[resource.typeOfResource]
          )
        );
      } else {
        let currentListingResource: resourceData[] = [];
        availableResources
          .filter((resource) => checkedResourceTypes[resource.typeOfResource])
          .map((resource: resourceData) => {
            for (let index in checkedTags) {
              if (checkedTags[index]) {
                if (resource.tags) {
                  if (
                    // @ts-ignore
                    resource.tags.includes(availableTags[index])
                  ) {
                    currentListingResource.push(resource);
                    break;
                  }
                }
              }
            }
            return null;
          });
        setListingResources(currentListingResource);
      }
    }
  }

  function handleTagClick(e: any) {
    //old code
    let currentTagCheck = availableTags.map((tag: string) => {
      return e.target.value === tag;
    });
    // @ts-ignore
    setSearchQuery("");
    setCheckedTags(currentTagCheck);
  }

  const handleReferral = () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    let selectedCaseID: any, resourceID, firmID;
    if (selectedCase !== null) {
      selectedCaseID = selectedCase["_id"];
      firmID = selectedCase["firm"];
    }
    if (selectedResource !== null) {
      resourceID = selectedResource["id"];
    }

    let referral = {
      _id: "",
      case_id: selectedCaseID,
      resource_id: resourceID,
      firm_id: firmID,
      referral_type: "Resource",
    };

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/referral`,
        {
          activity_type: "Referral",
          referral: referral,
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          setCardOpen(false);
          navigate(`/case?id=${selectedCaseID}`);
        } else {
          console.log("API Error");
        }
      });
  };

  const handleSearchInputChange = (event: any) => {
    if (checkedTags.some((tag: boolean) => tag)) {
      const updatedTags = checkedTags.map(() => false);
      setCheckedTags(updatedTags);
    }
    const searchValue = event.target.value;
    setSearchQuery(searchValue);
    if (searchValue === "") {
      setListingResources(availableResources);
    } else {
      const filteredResources = availableResources.filter((resource) => {
        return resource.orgName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setListingResources(filteredResources);
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: "#9D2362",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#9D2362",
      },
    },
  };

  const handleResourceTypeChange = (event: any) => {
    const { name, checked } = event.target;
    setCheckedResourceTypes((prevCheckedResourceTypes) => ({
      ...prevCheckedResourceTypes,
      [name]: checked,
    }));
  };

  const handleSelectUnselectAll = (event: any) => {
    const { checked } = event.target;
    const updatedCheckedResourceTypes: { [key: string]: boolean } = {};
    for (const key in checkedResourceTypes) {
      updatedCheckedResourceTypes[key] = checked;
    }
    setSelectUnselectAll(checked);
    setCheckedResourceTypes(updatedCheckedResourceTypes);
  };

  const handleAddNewResource = () => {
    setEditingResource({});
    setResourceEditorOpen(true);
  };

  return (
    <div className="">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1 col-span-1">
          <div className="min-h-[670px]">
            {availableTags && (
              <Tags
                availableTags={availableTags}
                checkedTags={checkedTags}
                resourceStatics={resourceStatics}
                onTagChange={handleTagChange}
              />
            )}
          </div>
        </div>

        <div className="col-start-2 col-span-2">
          <TextField
            label="Search Resources"
            value={searchQuery}
            onChange={handleSearchInputChange}
            style={{ marginBottom: "10px", borderColor: "#9D2362" }}
            className="bg-white w-full"
            color="secondary"
            sx={style}
          />

          <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="grid grid-cols-2 mb-2">
              {Object.entries(checkedResourceTypes).map(([key, value]) => (
                <div key={key} className="flex items-center">
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={value}
                    onChange={handleResourceTypeChange}
                  />
                  <label htmlFor={key} className="text-left ml-2">
                    {key}
                  </label>
                </div>
              ))}
            </div>
            <hr className="border-t-2 border-gray-300 mt-4" />
            <div className="flex items-center justify-between">
              <div key="select_unselect_all" className="flex items-center mt-2">
                <input
                  type="checkbox"
                  name="Select/Unselect All"
                  checked={selectUnselectAll}
                  onChange={handleSelectUnselectAll}
                />
                <label htmlFor="select_unselect_all" className="text-left ml-2">
                  Select/Unselect All
                </label>
              </div>
              <div className="self-end">
                Total Showing = {listingResource.length}
              </div>
            </div>
          </div>

          <Stack spacing={3}>
            {props.user.role === "admin" && (
              <StyledButtonMain
                variant="contained"
                onClick={handleAddNewResource}
              >
                Add new Resource
              </StyledButtonMain>
            )}
            {listingResource.length > 0 ? (
              listingResource.map((resource: resourceData, index: number) => {
                return (
                  <Resource
                    tagDisplay={true}
                    // tags={tags}
                    handleTagClick={handleTagClick}
                    resourceContent={resource}
                    index={index}
                    key={index}
                    // openEdit={props.openEdit}
                    setResourceEditorOpen={setResourceEditorOpen}
                    setEditingResource={setEditingResource}
                    user={props.user}
                    cardOpen={cardOpen}
                    setCardOpen={setCardOpen}
                    setSelectedResource={setSelectedResource}
                    setResourceFactsheetOpen={setResourceFactsheetOpen}
                    setResourceNotesOpen={setResourceNotesOpen}
                    setHisOpen={setHisOpen}
                    setHisResource={setHisResource}
                  />
                );
              })
            ) : (
              <>
                <h3 className="text-lg font-bold text-magenta">
                  No record found
                </h3>
              </>
            )}
          </Stack>
        </div>
      </div>

      {/* Select Case Popup Box*/}
      <ThriveModal
        height={720}
        open={cardOpen}
        setOpen={setCardOpen}
        contentComponent={
          <ThriveCaseSelection
            cases={cases}
            displayCases={displayCases}
            setDisplayCases={setDisplayCases}
            caseActivities={caseActivities}
            checkedCases={checkedCases}
            setCheckedCases={setCheckedCases}
            selectedCase={selectedCase}
            setSelectedCase={setSelectedCase}
            enableControl={true}
            enableSelection={true}
            enableMultiSelection={false}
            setOpen={setCardOpen}
            status_default="open"
            sortby_default="recent"
            title="Select Connecting Case"
            handleNext={handleReferral}
          ></ThriveCaseSelection>
        }
      ></ThriveModal>
      <ThriveModal
        open={resourceEditorOpen}
        setOpen={setResourceEditorOpen}
        contentComponent={
          <ThriveResourceEditor
            setOpen={setResourceEditorOpen}
            editorType={"Resource"}
            title={"Resource Editor"}
            editingResource={editingResource}
          ></ThriveResourceEditor>
        }
      ></ThriveModal>

      {/* modal for resource factsheets */}
      <ThriveModal
        open={resourceFactsheetOpen}
        setOpen={setResourceFactsheetOpen}
        contentComponent={
          <FactSheetsList
            setOpen={setResourceFactsheetOpen}
            resource={selectedResource}
          ></FactSheetsList>
        }
      ></ThriveModal>

      {/* modal for resource notes */}
      <ThriveModal
        open={resourceNotesOpen}
        setOpen={setResourceNotesOpen}
        contentComponent={
          <ResourceNotes
            setOpen={setResourceNotesOpen}
            resource={selectedResource}
            editorType={"Resource"}
          ></ResourceNotes>
        }
      ></ThriveModal>

      {/* modal for resource his */}
      <ThriveModal
        open={hisOpen}
        setOpen={setHisOpen}
        contentComponent={
          <ResourceHis
            setOpen={setHisOpen}
            hisResource={hisResource}
          ></ResourceHis>
        }
      ></ThriveModal>
    </div>
  );
}

export default ResourceList;
