import { ObjectId } from "mongodb";

export interface EventType {
  _id?: string | ObjectId;
  url: string;
  logo: { url: string };
  name: { text: string };
  description: { text: string };
  locationInfo: { localized_address_display: string };
  start: { local: string };
  end: { local: string };
  startDateTime: Date;
  EndDateTime: Date;
  attendeeInfo?: object[];
}

export interface CaseType {
  _id: string | ObjectId;
  user: string;
  date: Date;
  firm: string;
  status: string;
  createdBy: string;
  user_2nd?: string;
}

export const defaultCase: CaseType = {
  _id: "",
  user: "",
  date: new Date(),
  firm: "",
  status: "",
  createdBy: "",
};

export interface UserType {
  _id: string | ObjectId;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phone: string;
  token?: string;
}

export const defaultUser: UserType = {
  _id: "",
  username: "",
  password: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  phone: "",
  token: "",
};

export interface ReferralType {
  _id: string | ObjectId;
  case_id: string | ObjectId;
  created_at: Date;
  firm_id: string | ObjectId;
  referral_note: string;
  referral_objective: string;
  resource_id: string | ObjectId;
  updated_at: Date;
}

export const defaultReferral: ReferralType = {
  _id: "",
  case_id: "",
  created_at: new Date(),
  firm_id: "",
  referral_note: "",
  referral_objective: "",
  resource_id: "",
  updated_at: new Date(),
};
