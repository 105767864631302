import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FirmReportItem from "./FirmReportItem";
import { validate } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const FirmReport = () => {
  const { state } = useLocation();
  const { firmData, indices } = state;
  const firmList = firmData["firms"];
  const navigate = useNavigate();

  useEffect(() => {
    validate().then((res) => {
      if (!res) {
        navigate("/");
      }
    });
  }, [navigate]);

  const handlePrint = () => {
    window.print();
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="print:h-[29.7cm] print:w-[23cm]">
      <div className="text-4xl text-magenta font-extrabold print:hidden">
        <h1>Firm Visit Plan</h1>
      </div>
      <div className="print:hidden">
        <div className="flex justify-between">
          <button
            onClick={handleDashboard}
            className="bg-magenta hover:bg-yellow text-white font-bold py-3 px-6 rounded shadow m-8 hover:cursor-pointer"
          >
            Back to Dashboard
          </button>
          <button
            onClick={handlePrint}
            className="bg-magenta hover:bg-yellow text-white font-bold py-3 px-7 rounded shadow m-8 hover:cursor-pointer"
          >
            Print your visit plan
          </button>
        </div>
      </div>
      {indices.map((index: number, idx: number) => {
        const currentFirmData = firmList[index];
        return (
          <div key={index} className="break-before-page">
            <div className="text-2xl text-left ml-5 mt-10 font-bold text-magenta">
              <h1>
                Firm {idx + 1} - {currentFirmData["Company Name"]}
              </h1>
            </div>

            <FirmReportItem data={currentFirmData} key={index} />
            <div className="border-b-8 border-yellow border-dashed print:hidden"></div>
          </div>
        );
      })}
    </div>
  );
};

export default FirmReport;
