import React from "react";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import FirmListing from "../FirmListing/FirmListing";
import { useNavigate } from "react-router-dom";
import ThriveModal from "../../common/ThriveModal";
import EditFirm from "../FirmListing/EditFirm";
import ThriveUserSelection from "../../common/ThriveUserSelection";

const ExploreFirmBeta = () => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [search, setSearch] = React.useState("");
  const [firmData, setFirmData] = React.useState<any>({ firms: [] });
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({
    label: user.firstName + " " + user.lastName,
    id: user._id,
  });
  const [newFirmOpen, setNewFirmOpen] = React.useState(false);
  const [userSelectOpen, setUserSelectOpen] = React.useState(false);
  const [dataFlag, setDataFlag] = React.useState<boolean>(false);
  const [editedFirm, setEditedFirm] = React.useState({});
  const [editedGrant, setEditedGrant] = React.useState({});
  const navigate = useNavigate();

  const [showOnlyAvailableFirms, setShowOnlyAvailableFirms] =
    React.useState<boolean>(false);
  React.useEffect(() => {}, [firmData]);

  const exportFirms = (firms: any) => {
    let csvContent: any[] = [];
    let csvHeading = [
      "thriveID",
      "Company Name",
      "Contact",
      "Email",
      "Phone",
      "Website",
      "Address",
      "City",
      "ZIP",
      "Founding Year",
      "Company Narrative",
      "primaryIndustry",
      "secondaryIndustry",
      "Annual Revenue",
      "Employees",
      "Woman-Owned",
      "Veteran-Owned",
      "Minority-Owned",
      "firmScore",
      "totalGrantsValue",
      "Home-based",
      "Selling Online",
      "In-Person Sales",
      "Commercial Location",
      "Mobile Business",
    ];
    csvContent.push(csvHeading);
    for (const firm of firms) {
      let csvRow = [];
      for (const item of csvHeading) {
        if (firm[item]) {
          csvRow.push(firm[item]);
        } else {
          csvRow.push("");
        }
      }
      csvContent.push(csvRow);
    }

    let csvString = ``;
    for (const row of csvContent) {
      let rowString = `\n`;
      for (const item of row) {
        rowString = rowString + `"${item}",`;
      }
      csvString = csvString + rowString;
    }

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a hidden anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "firms_export.csv";
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/openai`,
        { term: search },
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
        setFirmData(res.data);
      });
  };

  const handleUserSelect = () => {
    if (user.role === "admin") {
      setUserSelectOpen(true);
    } else {
      handleVisitPlan();
    }
  };

  const handleVisitPlan = () => {
    let displayFirmList: any[];
    if (showOnlyAvailableFirms) {
      displayFirmList = firmData["firms"].filter((item: any, index: number) => {
        return !item["openCase"];
      });
    } else {
      displayFirmList = firmData["firms"];
    }
    const data = selectedRow.map((row: number) => {
      return { _id: displayFirmList[row]["_id"] };
    });

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases`,
        { firms: data, bfc: selectedUser.id },
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
      });
    navigate("/firmReport", {
      state: { firmData: { firms: displayFirmList }, indices: selectedRow },
    });
  };

  const handleAddNewFirm = () => {
    setNewFirmOpen(true);
  };

  let selectedRoww: any = [...selectedRow];
  const handleFirmSelect = (data: any) => {
    selectedRoww = data;
    setSelectedRow(selectedRoww);
  };

  let displayFirmList: any;
  if (showOnlyAvailableFirms) {
    displayFirmList = firmData["firms"].filter((item: any, index: number) => {
      return !item["openCase"];
    });
  } else {
    displayFirmList = firmData["firms"];
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: 300,
        backgroundColor: "white",
        borderRadius: "15px",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ width: "500px" }}
          label="Search Company..."
          variant="outlined"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Button
          style={{ backgroundColor: "#9e1f63", color: "white" }}
          variant="contained"
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Stack>

      {firmData["firms"].length > 0 ? (
        <Box sx={{ marginTop: "30px" }}>
          <Button
            style={{
              backgroundColor: "#9e1f63",
              color: "white",
              marginBottom: "10px",
            }}
            variant="contained"
            onClick={handleUserSelect}
          >
            Create Visit Plan
          </Button>
          <div>
            <input
              type="checkbox"
              id="showOnlyAvailableFirms"
              checked={showOnlyAvailableFirms}
              onChange={() =>
                setShowOnlyAvailableFirms(!showOnlyAvailableFirms)
              }
            />
            <label htmlFor="showOnlyAvailableFirms" className="ml-1">
              Show only Available Firms
            </label>
          </div>
          <Typography
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              cursor: "pointer",
              color: "#006d86",
            }}
            onClick={() => {
              exportFirms(displayFirmList);
            }}
          >
            Export Firm Result
          </Typography>
          <FirmListing
            handleFirmSelect={handleFirmSelect}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            firmData={{ firms: displayFirmList }}
            selectable={true}
          />
        </Box>
      ) : (
        <h2></h2>
      )}
      <div>
        <Button
          style={{
            backgroundColor: "#9e1f63",
            color: "white",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          variant="contained"
          onClick={handleAddNewFirm}
        >
          Add New Firm
        </Button>
      </div>
      <ThriveModal
        height={750}
        open={newFirmOpen}
        contentComponent={
          <EditFirm
            firm={editedFirm}
            editedFirm={editedFirm}
            setEditedFirm={setEditedFirm}
            editedGrant={editedGrant}
            setEditedGrant={setEditedGrant}
            user={user}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
            setOpen={setNewFirmOpen}
            newFirm={true}
            openCasePage={true}
          ></EditFirm>
        }
      ></ThriveModal>
      <ThriveModal
        open={userSelectOpen}
        contentComponent={
          <ThriveUserSelection
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            currentUser={user}
            handleNext={handleVisitPlan}
            setOpen={setUserSelectOpen}
          ></ThriveUserSelection>
        }
      ></ThriveModal>
    </Box>
  );
};

export default ExploreFirmBeta;
