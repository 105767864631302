import styles from "../../assets/styles/layout.module.css";
import { Outlet } from "react-router-dom";
import User from "../User/User";
import ThriveBreadcrumb from "./ThriveBreadcrumb";
import Footer from "./Footer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavbarContext } from "../../context/NavbarContext";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const Layout = () => {
  const { isNavbarOpen, setIsNavbarOpen } = useNavbarContext();
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  return (
    <div className={styles.application}>
      <div className={styles.header_container}>
        <div className={styles.header}>
          <div className={styles.user_container}>
            <User />
          </div>
        </div>
      </div>
      <Box
        className="flex ml-[30px]"
        sx={{ maxWidth: "1440px", margin: "auto", paddingLeft: "6px" }}
      >
        {currentPath !== "/" && (
          <IconButton
            onClick={toggleNavbar}
            color="inherit"
            aria-label="Open Menu"
          >
            <MenuIcon />
          </IconButton>
        )}
        <ThriveBreadcrumb></ThriveBreadcrumb>
      </Box>

      <div className={styles.content_container}>
        <Outlet />
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
