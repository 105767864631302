import React from "react";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment";

import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";

const ActivityDetail = (props: any) => {
  return (
    props.firm && (
      <>
        <Link style={{ width: "100%" }} to={`/case?id=${props.case._id}`}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              // margin: "10px",
              marginBottom: "5px",
              padding: "0 0 0 5px",
              textAlign: "left",
            }}
            // onClick={() => setOpen(true)}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {props.firm["Company Name"]}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="div"
                >
                  Created Date: {moment(props.case.date).format("MM/DD/YYYY")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="div"
                >
                  Created by:{" "}
                  {props.case.createdBy ? props.case.createdBy : "BFC"}
                </Typography>
                {/* <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                {props.firm["Industry"]}
              </Typography> */}
                {props.showStatus && (
                  <Typography
                    variant="subtitle2"
                    color={props.showStatus === "Open" ? "green" : "red"}
                    component="div"
                  >
                    Status: {props.showStatus}
                  </Typography>
                )}
              </CardContent>
            </Box>
          </Card>
        </Link>
      </>
    )
  );
};

export default ActivityDetail;
