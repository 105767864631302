import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useState } from "react";
import SurveyJsPanel from "./SurveyJsPanel";
import ThriveModal from "../../common/ThriveModal";
import ThriveTitle from "../../common/ThriveTitle";
// import BusinessNeedsAssessmentPanel from "./BusinessNeedsAssessmentPanel";
import { usePDF, Margin } from "react-to-pdf";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const { toPDF, targetRef } = usePDF({
    filename: "Report.pdf",
    page: { margin: Margin.SMALL, format: "letter" },
    overrides: {
      canvas: {
        removeContainer: false,
      },
    },
  });
  const [surveyOpen, setSurveyOpen] = useState(false);
  // const [bussNeedsSurveyOpen, setBussNeedsSurveyOpen] = useState(false);
  const [surveyResult, setSurveyResult] = useState<any[]>([]);
  const [surveyTitle, setSurveyTitle] = useState<string>("");
  const [surveyContent, setSurveyContent] = useState<any>({});
  const navigate = useNavigate();
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const refineSurveyContent = (surveyContent: any) => {
    if (surveyContent.pages) {
      for (const pageContent of surveyContent.pages) {
        const pageTitle = pageContent.title;
        for (const elementContent of pageContent.elements) {
          elementContent.title = pageTitle + " - " + elementContent.title;
        }
      }
    }
    return surveyContent;
  };

  const getSurvey = async (survey_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/survey?id=${survey_id}`,
      axiosConfig
    );
    const refinedSurveyContent = refineSurveyContent(
      response.data.survey.survey_content
    );
    setSurveyContent(refinedSurveyContent);
    return response.data.survey;
  };

  const getSurveyResult = async (survey_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=${survey_id}`,
      axiosConfig
    );
    setSurveyResult(
      response.data.results.map((result: any) => result.survey_result)
    );
    return response.data.results;
  };

  const getBFC = async (case_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/user?case_id=${case_id}`,
      axiosConfig
    );
    return response.data.user.firstName + " " + response.data.user.lastName;
  };

  const getCompany = async (case_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/firms/id?case_id=${case_id}`,
      axiosConfig
    );
    return response.data.firm;
  };

  const handleReferralSurvey = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=64f75c4287cfd9719d4904e1`,

        axiosConfig
      )
      .then(async (res) => {
        if (res.status === 200) {
          // console.log(res.data);
          let csvContent: any[] = [];
          let csvHeading = [
            "BFC",
            "Company",
            "Contact",
            "Email",
            "Survey Date",
          ];
          let csvNames: string[] = [];
          res.data.survey.survey_content.elements.map((survey_q: any) => {
            if (survey_q.title) {
              if (survey_q.description) {
                csvHeading.push(
                  survey_q.title + "(" + survey_q.description + ")"
                );
              } else {
                csvHeading.push(survey_q.title);
              }

              csvNames.push(survey_q.name);
            }
            return null;
          });
          csvContent.push(csvHeading);

          for (const result of res.data.results) {
            let csvAnswer = [];
            const BFC = await getBFC(result["case_id"]);
            csvAnswer.push(BFC);
            const Company = await getCompany(result["case_id"]);
            csvAnswer.push(Company["Company Name"]);
            csvAnswer.push(
              Company["Contact"]
                ? Company["Contact"]
                : Company["additionalContacts"][0]["Contact"]
            );
            csvAnswer.push(Company["Email"]);
            csvAnswer.push(dayjs(result.updated_at).format("YYYY-MM-DD"));

            for (const q of csvNames) {
              if (result["survey_result"][q]) {
                csvAnswer.push(result["survey_result"][q]);
              } else {
                csvAnswer.push("");
              }
            }
            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = "referral_survey.csv";
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          console.log("API Error");
        }
      });
  };

  const handleEventSurvey = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=65412693f9ecc07bf529219f`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let csvContent: any[] = [];
          let csvHeading = [
            "First Name",
            "Last Name",
            "Email",
            "What event did you attend?",
            "How likely are you to recommend this event to a friend or colleague?",
            "Overall how satisfied are you with the event?",
            "How likely are you to join Oakland Thrive for another event?",
            "Quality of venue?",
            "What other training topics would you like to see offered by Oakland Thrive/Business Forward?",
            "Any other comments or suggestions?",
            "How did you hear about this event/Oakland Thrive?",
            "Other",
            "Can we use your comments, name and company name on Oakland Thrive promotional materials in the future?",
          ];
          let csvNames: string[] = [];
          csvContent.push(csvHeading);

          for (const surveyItem of res.data.results) {
            let csvAnswer = [];
            csvAnswer.push(
              surveyItem.survey_result["first-name"]
                ? surveyItem.survey_result["first-name"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["last-name"]
                ? surveyItem.survey_result["last-name"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.email
                ? surveyItem.survey_result.email
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q1 ? surveyItem.survey_result.q1 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q2 ? surveyItem.survey_result.q2 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q3 ? surveyItem.survey_result.q3 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q4 ? surveyItem.survey_result.q4 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q5 ? surveyItem.survey_result.q5 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q6 ? surveyItem.survey_result.q6 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q7 ? surveyItem.survey_result.q7 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q8 ? surveyItem.survey_result.q8 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["q8-Comment"]
                ? surveyItem.survey_result["q8-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q9 ? surveyItem.survey_result.q9 : ""
            );
            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = `event survey.csv`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  };

  const handleConferenceSurvey = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=654b20727276d3350fde26ba`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let csvContent: any[] = [];
          let csvHeading = [
            "First Name",
            "Last Name",
            "Email",
            "What event did you attend?",
            "How likely are you to recommend this conference to a friend or colleague?",
            "Overall how satisfied are you with the conference?",
            "How likely are you to join us at the conference next year?",
            "Quality of venue?",
            " What other topics would you like to see covered at the next conference?",
            "Any other comments or suggestions?",
            "How did you hear about the conference?",
            "Other",
            "Can we use your comments, name and company name on conference promotional materials in the future?",
          ];
          let csvNames: string[] = [];
          csvContent.push(csvHeading);

          for (const surveyItem of res.data.results) {
            let csvAnswer = [];
            csvAnswer.push(
              surveyItem.survey_result["first-name"]
                ? surveyItem.survey_result["first-name"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["last-name"]
                ? surveyItem.survey_result["last-name"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.email
                ? surveyItem.survey_result.email
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q1 ? surveyItem.survey_result.q1 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q2 ? surveyItem.survey_result.q2 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q3 ? surveyItem.survey_result.q3 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q4
                ? surveyItem.survey_result.q4
                : "Defense & Advanced Manufacturing Conference"
            );
            csvAnswer.push(
              surveyItem.survey_result.q5 ? surveyItem.survey_result.q5 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q6 ? surveyItem.survey_result.q6 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q7 ? surveyItem.survey_result.q7 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q8 ? surveyItem.survey_result.q8 : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["q8-Comment"]
                ? surveyItem.survey_result["q8-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result.q9 ? surveyItem.survey_result.q9 : ""
            );
            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = `event survey.csv`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  };

  const handleB2BClientSurvey = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=65585ca5da7bea193668034e`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let csvContent: any[] = [];
          let csvHeading = [
            "Contact Name",
            "Small Business Name",
            "Small Business Contact Person",
            "Professional Business Provider Name",
            "Place of Meeting",
            "Time Spent",
            "Contact Date",
            "Today's Date",
            "The Meeting was well planned (Communications and Scheduling)",
            "Notes",
            "The Content of the meeting was informative and useful for me",
            "Notes",
            "My business needs were met",
            "Notes",
            "The provider I met with was prepared, professional and knowledgeable",
            "Notes",
            "Were you satisfied with the overall meeting experience?",
            "Notes",
            "How likely are you to recommend the Professional Business Provider",
            "Notes",
            "What questions were you left with after this meeting?",
            "Next Steps",
            "Do you have any suggestions or additional comments regarding your meeting?",
          ];
          let csvNames: string[] = [];
          csvContent.push(csvHeading);

          for (const surveyItem of res.data.results) {
            let csvAnswer = [];
            csvAnswer.push(
              surveyItem.survey_result["question1"]
                ? surveyItem.survey_result["question1"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question2"]
                ? surveyItem.survey_result["question2"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question3"]
                ? surveyItem.survey_result["question3"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question4"]
                ? surveyItem.survey_result["question4"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question5"]
                ? surveyItem.survey_result["question5"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question6"]
                ? surveyItem.survey_result["question6"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question7"]
                ? surveyItem.survey_result["question7"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question8"]
                ? surveyItem.survey_result["question8"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question9"]
                ? surveyItem.survey_result["question9"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question9-Comment"]
                ? surveyItem.survey_result["question9-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question10"]
                ? surveyItem.survey_result["question10"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question10-Comment"]
                ? surveyItem.survey_result["question10-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question11"]
                ? surveyItem.survey_result["question11"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question11-Comment"]
                ? surveyItem.survey_result["question11-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question12"]
                ? surveyItem.survey_result["question12"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question12-Comment"]
                ? surveyItem.survey_result["question12-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question13"]
                ? surveyItem.survey_result["question13"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question13-Comment"]
                ? surveyItem.survey_result["question13-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question14"]
                ? surveyItem.survey_result["question14"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question14-Comment"]
                ? surveyItem.survey_result["question14-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question15"]
                ? surveyItem.survey_result["question15"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question16"]
                ? surveyItem.survey_result["question16"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question17"]
                ? surveyItem.survey_result["question17"]
                : ""
            );

            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = `b2b client survey.csv`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  };

  const handleB2BProviderSurvey = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/survey/results?survey_id=655869c8da7bea1936680388`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let csvContent: any[] = [];
          let csvHeading = [
            "PBP Business Name",
            "Small Business Name",
            "Small Business Contact Person",
            "Provider Name",
            "Place of Meeting",
            "Time Spent",
            "Contact Date",
            "Today's Date",
            "Was the meeting well planned and executed?",
            "Notes",
            "Did the Business come prepared with the necessary documents",
            "Notes",
            "Was sufficient time allocated for the meeting?",
            "Notes",
            "Was the meeting successful and 'as needed'?",
            "Notes",
            "Was the meeting 'tone and participation level' sufficiently positive and productive?",
            "Notes",
            "Were all follow-up actions and 'next steps' properly identified, recorded and tracked?",
            "Notes",
            "Services Provided",
            "Next Steps",
            "COMMENTS",
          ];
          let csvNames: string[] = [];
          csvContent.push(csvHeading);

          for (const surveyItem of res.data.results) {
            let csvAnswer = [];
            csvAnswer.push(
              surveyItem.survey_result["question1"]
                ? surveyItem.survey_result["question1"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question2"]
                ? surveyItem.survey_result["question2"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question3"]
                ? surveyItem.survey_result["question3"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question4"]
                ? surveyItem.survey_result["question4"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question5"]
                ? surveyItem.survey_result["question5"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question6"]
                ? surveyItem.survey_result["question6"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question7"]
                ? surveyItem.survey_result["question7"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question8"]
                ? surveyItem.survey_result["question8"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question9"]
                ? surveyItem.survey_result["question9"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question9-Comment"]
                ? surveyItem.survey_result["question9-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question10"]
                ? surveyItem.survey_result["question10"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question10-Comment"]
                ? surveyItem.survey_result["question10-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question11"]
                ? surveyItem.survey_result["question11"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question11-Comment"]
                ? surveyItem.survey_result["question11-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question12"]
                ? surveyItem.survey_result["question12"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question12-Comment"]
                ? surveyItem.survey_result["question12-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question13"]
                ? surveyItem.survey_result["question13"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question13-Comment"]
                ? surveyItem.survey_result["question13-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question14"]
                ? surveyItem.survey_result["question14"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question14-Comment"]
                ? surveyItem.survey_result["question14-Comment"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question15"]
                ? surveyItem.survey_result["question15"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question16"]
                ? surveyItem.survey_result["question16"]
                : ""
            );
            csvAnswer.push(
              surveyItem.survey_result["question17"]
                ? surveyItem.survey_result["question17"]
                : ""
            );

            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = `b2b provider survey.csv`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  };

  const handleActiveClients = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/reports/active_clients`,
        axiosConfig
      )
      .then((response: any) => {
        if (response.status === 200) {
          let csvContent: any[] = [];
          let csvHeading = ["Active Clients", "City"];
          let csvNames: string[] = [];
          csvContent.push(csvHeading);

          for (const clients of response.data.activeClients) {
            let csvAnswer = [];
            csvAnswer.push(clients["Company Name"]);
            csvAnswer.push(clients["City"]);
            csvContent.push(csvAnswer);
          }

          let csvString = ``;
          for (const row of csvContent) {
            let rowString = `\n`;
            for (const item of row) {
              rowString = rowString + `"${item}",`;
            }
            csvString = csvString + rowString;
          }

          const blob = new Blob([csvString], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a hidden anchor element
          const a = document.createElement("a");
          a.href = url;
          a.download = `b2b provider survey.csv`;
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      });
  };

  return (
    <>
      <Table sx={{ width: "80%", margin: "auto" }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Button
              // onClick={() => {
              //   navigate("/dashboard/reports/active_clients");
              // }}
              >
                Active Clients Report
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleActiveClients}>Download Raw</Button>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  navigate("/dashboard/reports/initial_contact");
                }}
              >
                Initial Contacts Report
              </Button>
            </TableCell>
            <TableCell>{/* <Button>Download Raw</Button> */}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  navigate("/dashboard/reports/rga");
                }}
              >
                Recovery Grant Applicants Report
              </Button>
            </TableCell>
            <TableCell>{/* <Button>Download Raw</Button> */}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("6488788edd1f6f5e5c5e5d7a");
                  getSurveyResult("6488788edd1f6f5e5c5e5d7a");
                  setSurveyTitle("Discovery Question Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                Discovery Question Report
              </Button>
            </TableCell>
            <TableCell>{/* <Button>Download Raw</Button> */}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("6504ae6817baa329c061dee7");
                  getSurveyResult("6504ae6817baa329c061dee7");
                  setSurveyTitle("Business Needs Assessment Report");
                  // setBussNeedsSurveyOpen(!bussNeedsSurveyOpen);
                  setSurveyOpen(!surveyOpen);
                }}
              >
                Business Needs Assessment Report
              </Button>
            </TableCell>
            <TableCell>{/* <Button>Download Raw</Button> */}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("64f75c4287cfd9719d4904e1");
                  getSurveyResult("64f75c4287cfd9719d4904e1");
                  setSurveyTitle("Resource Referral Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                Resource Referral Surveys
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleReferralSurvey}>Download Raw</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("65412693f9ecc07bf529219f");
                  getSurveyResult("65412693f9ecc07bf529219f");
                  setSurveyTitle("Event Survey Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                Event Surveys
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleEventSurvey}>Download Raw</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("654b20727276d3350fde26ba");
                  getSurveyResult("654b20727276d3350fde26ba");
                  setSurveyTitle("Conference Survey Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                Conference Surveys
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleConferenceSurvey}>Download Raw</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("65585ca5da7bea193668034e");
                  getSurveyResult("65585ca5da7bea193668034e");
                  setSurveyTitle("B2B Client Survey Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                B2B Client Surveys
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleB2BClientSurvey}>Download Raw</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Button
                onClick={() => {
                  getSurvey("655869c8da7bea1936680388");
                  getSurveyResult("655869c8da7bea1936680388");
                  setSurveyTitle("B2B Vendor Survey Report");
                  setSurveyOpen(!surveyOpen);
                }}
              >
                B2B Vendor Surveys
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={handleB2BProviderSurvey}>Download Raw</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <ThriveModal
        open={surveyOpen}
        width={"1000px"}
        height={"600px"}
        contentComponent={
          <>
            <ThriveTitle
              title={surveyTitle}
              setOpen={setSurveyOpen}
            ></ThriveTitle>
            <Box sx={{ position: "absolute", marginTop: "-42px" }}>
              <Button
                sx={{ marginLeft: "10px" }}
                color={"warning"}
                variant="contained"
                onClick={() => toPDF()}
              >
                Print
              </Button>
            </Box>

            <Box
              sx={{
                margin: 1,
                height: "530px",
                overflow: "auto",
                backgroundColor: "white",
              }}
            >
              {surveyOpen && surveyContent && surveyResult ? (
                <SurveyJsPanel
                  surveyTitle={surveyTitle}
                  pdfref={targetRef}
                  surveyContent={surveyContent}
                  surveyResult={surveyResult}
                ></SurveyJsPanel>
              ) : (
                <></>
              )}
            </Box>
          </>
        }
      ></ThriveModal>
      {/* <ThriveModal
        open={bussNeedsSurveyOpen}
        width={"1000px"}
        height={"600px"}
        contentComponent={
          <>
            <ThriveTitle
              title={surveyTitle}
              setOpen={setBussNeedsSurveyOpen}
            ></ThriveTitle>
            <Box
              sx={{
                margin: 1,
                height: "530px",
                overflow: "auto",
                backgroundColor: "white",
              }}
            >
              {bussNeedsSurveyOpen && surveyContent && surveyResult ? (
                <BusinessNeedsAssessmentPanel
                  surveyContent={surveyContent}
                  surveyResult={surveyResult}
                ></BusinessNeedsAssessmentPanel>
              ) : (
                <></>
              )}
            </Box>
          </>
        }
      ></ThriveModal> */}
    </>
  );
};

export default Reports;
