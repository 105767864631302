import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ThriveCaseSelection from "../common/ThriveCaseSelection";
const UserActivity = (props: any) => {
  const [displayCases, setDisplayCases] = useState<any>([]);
  const [listingCases, setListingCases] = useState<any[]>([]);
  const [checkedCases, setCheckedCases] = useState<any[]>([]);
  const [selectedCase, setSelectedCase] = useState({ _id: "", firm: "" });

  const handleSortChange = (currentListing: any, sortby: any) => {
    if (props.caseActivities.length === 0) return currentListing;
    let sortedCaseIds = [];
    if (sortby === "recent") {
      for (let activity of props.caseActivities) {
        if (sortedCaseIds.indexOf(activity.case_id) === -1) {
          sortedCaseIds.push(activity.case_id);
        }
        if (sortedCaseIds.length === currentListing.length) {
          break;
        }
      }
    }

    let newListingCases: any[] = sortedCaseIds.map((caseId: any) => {
      return currentListing.find((caseItem: any) => {
        return caseItem._id === caseId;
      });
    });

    return newListingCases;
  };

  useEffect(() => {
    setListingCases(handleSortChange(props.openCases, "recent"));
  }, [props.openCases]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "710px",
        backgroundColor: "#e7e7e747",
        borderRadius: "15px",
        // overflowY: "scroll",
        // overflow: "hidden",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "15px 15px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h6">
          Recent Cases
        </Typography>
      </Box>
      <Button
        sx={{ width: "100%" }}
        onClick={() => {
          props.setAllCaseCardOpen(true);
        }}
      >
        {" "}
        View All Cases
      </Button>
      {listingCases.length > 0 ? (
        <ThriveCaseSelection
          cases={listingCases}
          displayCases={displayCases}
          setDisplayCases={setDisplayCases}
          caseActivities={props.caseActivities}
          checkedCases={checkedCases}
          setCheckedCases={setCheckedCases}
          selectedCase={selectedCase}
          setSelectedCase={setSelectedCase}
          enableControl={false}
          enableSelection={false}
          enableMultiSelection={false}
          title=""
          status_default="either"
          sortby_default="recent"
          setOpen={() => {}}
          handleNext={() => {}}
        ></ThriveCaseSelection>
      ) : (
        <h1>No Open Cases</h1>
      )}
    </Box>
  );
};

export default UserActivity;
