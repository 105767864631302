import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import axios from "axios";
import { useLocation } from "react-router-dom";

const PublicB2BClientSurvey = () => {
  const surveyJson = {
    title: "Small Business Survey (Post B2B Meeting)",
    logoPosition: "right",
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "text",
            name: "question1",
            title: "Contact Name",
          },
          {
            type: "text",
            name: "question2",
            title: "Small Business Name",
          },
          {
            type: "text",
            name: "question3",
            title: "Small Business Contact Person",
          },
          {
            type: "text",
            name: "question4",
            title: "Professional Business Provider Name",
          },
          {
            type: "text",
            name: "question5",
            title: "Place of Meeting",
          },
          {
            type: "dropdown",
            name: "question6",
            title: "Time Spent",
            choices: [
              {
                value: "30 minutes",
                text: "30 minutes",
              },
              {
                value: "60 minutes",
                text: "60 minutes",
              },
              {
                value: "90 minutes",
                text: "90 minutes",
              },
              {
                value: "120 minutes",
                text: "120 minutes",
              },
            ],
          },
          {
            type: "text",
            name: "question7",
            title: "Contact Date",
            inputType: "date",
          },
          {
            type: "text",
            name: "question8",
            title: "Today's Date",
            inputType: "date",
          },
        ],
        title: "Contact",
      },
      {
        name: "page2",
        elements: [
          {
            type: "rating",
            name: "question9",
            title:
              "The Meeting was well planned (Communications and Scheduling)",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question10",
            title:
              "The Content of the meeting was informative and useful for me",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question11",
            title: "My business needs were met",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question12",
            title:
              "The provider I met with was prepared, professional and knowledgeable",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question13",
            title: "Were you satisfied with the overall meeting experience?",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
          {
            type: "rating",
            name: "question14",
            title:
              "How likely are you to recommend the Professional Business Provider",
            description:
              "Use a scale of 1-5 where 1 indicates you are very unsatisfied, and 5 means you are very satisfied.",
            showCommentArea: true,
            commentText: "Notes",
          },
        ],
        title: "Characteristics",
      },
      {
        name: "page3",
        elements: [
          {
            type: "comment",
            name: "question15",
            title: "What questions were you left with after this meeting?",
          },
          {
            type: "comment",
            name: "question16",
            title: "Next Steps",
          },
        ],
        title: "Goals",
      },
      {
        name: "page4",
        elements: [
          {
            type: "comment",
            name: "question17",
            title:
              "Do you have any suggestions or additional comments regarding your meeting?",
          },
        ],
        title: "Comments and Approval",
      },
    ],
    showProgressBar: "both",
    showTOC: true,
  };

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      // Add JWT token to Authorization header
    },
  };

  const survey = new Model(surveyJson);
  survey.data = {
    question8: new Date().toISOString().slice(0, 10),
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId");
  const referralID = queryParams.get("referralId");

  survey.onComplete.add((sender, options) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/survey/public_result`,
        {
          activity_type: "B2B Client Survey (by Client)",
          surveyResult: {
            survey_result: sender.data,
            case_id: caseId,
            survey_id: "65585ca5da7bea193668034e",
            referral_id: referralID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
        } else {
          console.log("API Error");
        }
      });
  });

  return <Survey model={survey} />;
};

export default PublicB2BClientSurvey;
