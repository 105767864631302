import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Cookies from "js-cookie";

export const SurveyContent = (props: any) => {
  const [originalSurveyData, setOriginalSurveyData] = useState<any>({});
  const [availableEvents, setAvailableEvents] = useState<any>([]);
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      // Add JWT token to Authorization header
    },
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/events/past?period=2w`,
        axiosConfig
      )
      .then((res) => {
        setAvailableEvents(res.data.events);
      });
    if (props.survey_id) {
      const token: string | undefined = Cookies.get("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/survey/result?survey_result_id=${props.survey_id}`,
          axiosConfig
        )
        .then(async (res) => {
          if (res.status === 200) {
            // console.log(res.data.survey_result);
            setOriginalSurveyData(res.data.survey_result);
          } else {
            console.log("API Error at getting survey result");
          }
        });
    }
  }, [props.survey_id]);
  const eventsList = availableEvents.map((event: any) => {
    return `${dayjs(event.start.local).format("MM-DD-YYYY")} - ${
      event.name.text
    }`;
  });

  const surveyJson = {
    elements: [
      {
        type: "panel",
        name: "contact-information",
        elements: [
          {
            type: "text",
            name: "first-name",
            title: "First name",
            isRequired: true,
          },
          {
            type: "text",
            name: "last-name",
            title: "Last name",
            isRequired: true,
          },
          {
            type: "text",
            name: "email",
            title: "Email Address",
            isRequired: true,
          },
        ],
      },
      {
        name: "q2",
        title:
          "How likely are you to recommend this conference to a friend or colleague?",
        type: "rating",
        rateCount: 10,
        rateMax: 10,
        description:
          "Use a scale of 1-10 where 1 indicates you are not at all likely to recommend, and 10 means you are extremely likely to recommend.",
      },
      {
        name: "q3",
        title: "Overall how satisfied are you with the conference?",
        type: "rating",
        rateCount: 10,
        rateMax: 10,
        description:
          "Use a scale of 1-10 where 1 indicates you are very unsatisfied, and 10 means you are very satisfied.",
      },
      {
        name: "q4",
        title: "How likely are you to join us at the conference next year?",
        type: "rating",
        rateCount: 10,
        rateMax: 10,
        description:
          "Use a scale of 1-10 where 1 indicates you are not at all likely to rejoin, and 10 means you are extremely likely to rejoin.",
      },
      {
        name: "q5",
        title: "Quality of venue?",
        type: "rating",
        rateCount: 10,
        rateMax: 10,
        description:
          "Use a scale of 1-10 where 1 indicates you are very unsatisfied, and 10 means you are vary satisfied.",
      },
      {
        name: "q6",
        title:
          "What other topics would you like to see covered at the next conference?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q7",
        title: "Any other comments or suggestions?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q8",
        title: "How did you hear about the conference?",
        type: "radiogroup",
        choices: [
          "Direct Email Message",
          "Website",
          "Social Media",
          "Eventbrite",
          "Business Forward Consultant",
          "Word of Mouth",
          "Google",
        ],
        showOtherItem: true,
        otherPlaceholder: "Please specify...",
        otherText: "Other",
      },
      {
        name: "q9",
        title:
          "Can we use your comments, name and company name on conference promotional materials in the future?",
        type: "radiogroup",
        choices: ["Yes", "No"],
      },
    ],
  };

  const survey = new Model(surveyJson);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId");
  const referralID = queryParams.get("referralId");
  survey.onComplete.add((sender, options) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/survey/event_survey_result`,
        {
          activity_type: "Event Survey",
          surveyResult: {
            survey_result: {
              ...sender.data,
              q1: "Oakland County Defense & Advanced Manufacturing Conference",
            },
            case_id: caseId,
            survey_id: "654b20727276d3350fde26ba",
            referral_id: referralID,
            activity_date: dayjs().format("YYYY-MM-DD"),
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
        } else {
          console.log("API Error");
        }
      });
  });

  survey.data = originalSurveyData;
  return (
    <Box sx={{ textAlign: "left" }}>
      <Survey model={survey} />
    </Box>
  );
};

const PublicConferenceSurvey = () => {
  return (
    <>
      <Box sx={{ maxWidth: "600px", margin: "auto" }}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Nov. 13th Defense & Advanced Manufacturing Conference Post-Event
          Survey
        </Typography>
        <br></br>
        <Typography sx={{ textAlign: "left" }}>
          Thank you for attending the second annual Defense & Advanced
          Manufacturing Conference! Please take a moment to provide feedback on
          your experience at our event. Your feedback will help improve the
          event next year.
        </Typography>
      </Box>

      <SurveyContent></SurveyContent>
    </>
  );
};
export default PublicConferenceSurvey;
