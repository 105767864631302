import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const TagManagement = () => {
  const [tags, setTags] = useState([]);
  const [ptags, setPtags] = useState([]);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    //Get tags from backend
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/tags`, axiosConfig)
      .then((res) => {
        setTags(res.data.tags);
      });

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/tags/provider`, axiosConfig)
      .then((res) => {
        setPtags(res.data.tags);
      });
  }, []);

  //   const handleClick = () => {
  //     console.info("You clicked the Chip.");
  //   };

  const handleDelete = (tag: string) => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    console.info("Deleted tag: " + tag);
    axios
      .delete(`${process.env.REACT_APP_API_DOMAIN}/tags/${tag}`, axiosConfig)
      .then(() => {
        window.location.reload();
      });
  };

  const handleProviderDelete = (tag: string) => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    console.info("Deleted tag: " + tag);
    axios
      .delete(
        `${process.env.REACT_APP_API_DOMAIN}/tags/provider/${tag}`,
        axiosConfig
      )
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Stack spacing={10}>
      <Box>
        <Typography variant="h4">Resource Tags</Typography>
        <Paper>
          {tags.map((tag, index) => {
            return (
              <Chip
                sx={{ margin: "5px", fontSize: "1.2rem" }}
                key={index}
                label={tag}
                // onClick={handleClick}
                onDelete={() => {
                  handleDelete(tag);
                }}
              />
            );
          })}
        </Paper>
      </Box>
      <Box>
        <Typography variant="h4">Provider Tags</Typography>
        <Paper>
          {ptags.map((tag) => {
            return (
              <Chip
                sx={{ margin: "5px", fontSize: "1.2rem" }}
                key={tag}
                label={tag}
                // onClick={handleClick}
                onDelete={() => {
                  handleProviderDelete(tag);
                }}
              />
            );
          })}
        </Paper>
      </Box>
    </Stack>
  );
};

export default TagManagement;
