import { Box, Card, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

const EmailViewer = (props: any) => {
  const editorRef = useRef(null);
  const [email, setEmail] = useState({
    htmlContent: "",
    attachments: [],
  });
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  useEffect(() => {
    if (props.activity) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/email?email_id=${props.activity.email_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setEmail(res.data.email);
          } else {
            console.log("API Error");
          }
        });
    }
  }, [props.activity]);

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          {props.activity.activity_type}
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setEditorOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>

      <Box>
        {email.attachments && email.attachments.length > 0 && (
          <Stack sx={{ margin: 0, marginLeft: "10px" }} spacing={0.5}>
            {email.attachments.map((attachment: any, index: number) => {
              return (
                <Card
                  key={index}
                  sx={{ backgroundColor: "#f1f1f1", maxWidth: "fit-content" }}
                >
                  <a
                    href={attachment.download}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    {attachment.name}
                  </a>
                </Card>
              );
            })}
          </Stack>
        )}
        <Box
          sx={{
            margin: "10px",
            border: "solid 1px #c4c4c4",
            borderRadius: "0 0 10px 10px",
            overflow: "auto",
            height: "500px",
          }}
        >
          <Editor
            tinymceScriptSrc={`${process.env.REACT_APP_API_DOMAIN}/javascripts/tinymce/tinymce.min.js`}
            //@ts-ignore
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={email.htmlContent}
            init={{
              forced_root_block: "",
              height: 500,
              menubar: false,
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              relative_urls: false,
              remove_script_host: false,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default EmailViewer;
