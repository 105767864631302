import React, { useState, useEffect } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";

const ActivityBarChart = (props: any) => {
  let angle = -90;
  let fontSize = 10;
  let dx = -4;
  let dy = 0;
  if (window.location.pathname === "/dashboard/statistics") {
    angle = -15;
    fontSize = 12;
    dx = 0;
    dy = 20;
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        className="bg-white rounded-2xl"
        data={props.data}
        margin={{ top: 30, bottom: 80, right: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="key"
          interval={0}
          angle={angle}
          dy={dy}
          dx={dx}
          tick={{ fontSize: fontSize, fontWeight: "bold", textAnchor: "end" }}
        />
        <YAxis allowDecimals={false} />
        <Tooltip cursor={props.barClickable ? false : true} />
        <Bar
          dataKey="value"
          fill="#9D2362"
          barSize={20}
          background={props.barClickable ? { fill: "#eee" } : undefined}
          className={props.barClickable ? "cursor-pointer" : ""}
          onClick={(e) => {
            if (props.barClickable) {
              if (e.key === "Initiate Contact") {
                props.setBarKey("Initial Contacts");
              } else {
                props.setBarKey(e.key);
              }

              props.setCardOpen(true);
            }
          }}
          isAnimationActive={false}
        >
          <LabelList dataKey="value" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ActivityBarChart;
