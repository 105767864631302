import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NavbarContextType {
  isNavbarOpen: boolean;
  setIsNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

interface NavbarProviderProps {
  children: ReactNode;
}

export const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  return (
    <NavbarContext.Provider value={{ isNavbarOpen, setIsNavbarOpen }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = (): NavbarContextType => {
  const context = useContext(NavbarContext);

  if (context === undefined) {
    throw new Error('useNavbarContext must be used within a NavbarProvider');
  }

  return context;
};
