import React, { useContext, useEffect, useState } from "react";
import styles from "../../assets/styles/home.module.css";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { login, twofalogin, validate } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Typography } from "@mui/material";
import Cookies from "js-cookie";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#9e1f63",
      contrastText: "#fff",
    },
  },
});
const HomePage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState();
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [resendCountDown, setResendCountDown] = useState(60);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    validate().then((res) => {
      if (res) {
        navigate("/dashboard");
      }
    });
  }, [navigate]);
  const loginSubmit = async () => {
    setMessage("");
    try {
      const user = await login(username, password);
      if (user) {
        setUser(user);
        setCurrentUser(user);
        const token: string | undefined = Cookies.get("token");
        if (token !== "undefined") {
          navigate("/dashboard");
        }
        setResendCountDown(60);
        setInterval(() => {
          setResendCountDown((prev) => prev - 1);
        }, 1000);
      } else {
        setMessage("Invalid username or password, try again.");
      }
    } catch (error) {
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  };

  const twoFactorSubmit = async () => {
    const user = await twofalogin(username, password, twoFactorCode);
    if (Cookies.get("token") && Cookies.get("token") !== "undefined") {
      setUser(user);
      setCurrentUser(user);
      navigate("/dashboard");
    } else {
      setMessage("Invalid verification code, try again later.");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.login}>
        {currentUser ? (
          <Stack spacing={3}>
            <TextField
              sx={{ textAlign: "center" }}
              label="Verification Code"
              type="search"
              onChange={(e) => {
                setTwoFactorCode(e.target.value);
              }}
            />
            <Typography variant="body1" color="error">
              {message}
            </Typography>
            <Button disabled={resendCountDown > 0} onClick={loginSubmit}>
              Get new code {resendCountDown > 0 ? `in ${resendCountDown}s` : ""}
            </Button>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color={"secondary"}
                onClick={twoFactorSubmit}
              >
                Submit
              </Button>
            </ThemeProvider>
          </Stack>
        ) : (
          <>
            <div>
              <h1>THRIVE Drive User Log in</h1>
            </div>
            <Stack spacing={3}>
              <TextField
                label="Username"
                type="search"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <TextField
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Typography variant="body1" color="error">
                {message}
              </Typography>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color={"secondary"}
                  onClick={loginSubmit}
                >
                  Continue
                </Button>
              </ThemeProvider>
            </Stack>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
