import { Box, Typography } from "@mui/material";
import { StyledButton } from "./Buttons";

const NoticeDialog = (props: any) => {
  return (
    <Box sx={{ textAlign: "center", zIndex: "999" }}>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          {props.title}
        </Typography>
      </Box>
      <Typography sx={{ marginTop: "20px" }} variant="h6">
        {props.body}
      </Typography>
      <StyledButton
        sx={{ bottom: 0, marginTop: "20px" }}
        variant="contained"
        onClick={() => {
          props.setOpen(false);
          props.setDataFlag(!props.dataFlag);
        }}
      >
        Close
      </StyledButton>
    </Box>
  );
};

export default NoticeDialog;
