import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ThriveTitle from "../../common/ThriveTitle";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";

const ResourceHis = (props: any) => {
  const [resourceReferrals, setResourceReferrals] = useState<any[]>([]);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/resources/detail?id=${props.hisResource.id}`,
        axiosConfig
      )
      .then((res) => {
        setResourceReferrals(res.data.resourceReferrals);
      });
  }, [props.hisResource]);
  return (
    <>
      <ThriveTitle
        title={`${props.hisResource.orgName} History`}
        setOpen={props.setOpen}
      />
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", maxHeight: "595px", padding: "10px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {resourceReferrals.map((row: any, index: number) => {
              if (row["client"]) {
                return (
                  <TableRow
                    key={index}
                    onClick={() => {
                      // Open a new tab with the case details
                      window.open(`/case?id=${row["case_id"]}`, "_blank");
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{row["client"]["Company Name"]}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {dayjs(row["created_at"]).format("YYYY-MM-DD")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceHis;
