import {
  Box,
  Button,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const ContentChange = (props: any) => {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          CONTENT CHANGE
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setCardOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          margin: "auto",
          marginTop: { xs: "10px", md: "10px" },
          overflowY: "scroll",
          maxHeight: "500px",
        }}
      >
        {props.activity.activity_change ? (
          <>
            {Object.keys(props.activity.activity_change).map(
              (changedItem: any, index: number) => {
                return (
                  <Box key={index}>
                    <Stack>
                      <Typography variant="h5">{changedItem}</Typography>
                      <Stack
                        spacing={3}
                        direction={"row"}
                        sx={{ margin: "auto", alignItems: "center" }}
                      >
                        <Typography>From:</Typography>
                        <TextField
                          multiline
                          rows={4}
                          defaultValue={
                            props.activity.activity_change[changedItem][0]
                          }
                        />
                      </Stack>

                      <Stack
                        spacing={3}
                        direction={"row"}
                        sx={{
                          margin: "auto",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Typography>To: </Typography>
                        <TextField
                          multiline
                          rows={4}
                          defaultValue={
                            props.activity.activity_change[changedItem][1]
                          }
                        />
                      </Stack>
                    </Stack>
                  </Box>
                );
              }
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          bottom: 20,
          width: "100%",
          textAlign: "center",
          position: "fixed",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "200px",
            fontSize: "20px",
            backgroundColor: "#9E1F63FF",
          }}
          onClick={() => {
            props.setCardOpen(false);
          }}
        >
          Close
        </Button>
      </Box>
    </>
  );
};

export default ContentChange;
