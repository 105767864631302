export const defaultTemplate = `<div role="replace" style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:24px;mso-ansi-font-size:16px;"><span style="font-size:16px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#777777;line-height:150%;mso-line-height-alt:24px;mso-ansi-font-size:16px;">Type your message here...</span></p><p style="Margin:0;mso-line-height-alt:24px;mso-ansi-font-size:16px;"><span style="font-size:16px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#777777;line-height:150%;mso-line-height-alt:24px;mso-ansi-font-size:16px;">&nbsp;</span></p><p style="Margin:0;mso-line-height-alt:24px;mso-ansi-font-size:16px;"><span style="font-size:16px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#777777;line-height:150%;mso-line-height-alt:24px;mso-ansi-font-size:16px;">Kindest Regards,</span></p></div>
`;

const now = `<table style="background: #fffffe; background-color: #fffffe; width: 100%;" role="presentation" border="0" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td style="border: none; direction: ltr; font-size: 0; padding: 0px 32px 16px 32px; text-align: left;"> 
<div class="xc536 ogf c" style="font-size: 0; text-align: left; direction: ltr; display: inline-block; vertical-align: middle; width: 100%;">
<table style="border: none; vertical-align: middle;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="x" style="font-size: 0; word-break: break-word;" align="left">
<div style="text-align: left;">
<p style="margin: 0; text-align: left; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;"><span style="font-size: 16px; font-family: Inter,Arial,sans-serif; font-weight: 400; color: #777777; line-height: 150%; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;">Type your message here...</span></p>
<p style="margin: 0; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;"><span style="font-size: 16px; font-family: Inter,Arial,sans-serif; font-weight: 400; color: #777777; line-height: 150%; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;">&nbsp;11123</span></p>
<p style="margin: 0; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;"><span style="font-size: 16px; font-family: Inter,Arial,sans-serif; font-weight: 400; color: #777777; line-height: 150%; mso-line-height-alt: 24px; mso-ansi-font-size: 16px;">Kindest Regards,</span></p>
</div>
</td>
</tr>
</tbody>
</table>
</div>
</td>
</tr>
</tbody>
</table>`;
export const beforeTemplate = (firm: any) => {
  return `<!doctype html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
<title></title>
<!--[if !mso]><!-->
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<!--<![endif]-->
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style type="text/css">
#outlook a{padding:0;}body{margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}table,td{border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;}img{border:0;height:auto;line-height:100%;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;}p{display:block;margin:0;}
</style>
<!--[if mso]> <noscript><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml></noscript>
<![endif]-->
<!--[if lte mso 11]>
<style type="text/css">
.ogf{width:100% !important;}
</style>
<![endif]-->
<!--[if !mso]><!-->
<link href="https://fonts.googleapis.com/css?family=Inter:400,700" rel="stylesheet" type="text/css">
<style type="text/css">

</style>
<!--<![endif]-->
<style type="text/css">
@media only screen and (min-width:599px){.xc568{width:568px!important;max-width:568px;}.xc536{width:536px!important;max-width:536px;}.xc258{width:258px!important;max-width:258px;}.xc0{width:0px!important;max-width:0;}.xc310{width:310px!important;max-width:310px;}.pc100{width:100%!important;max-width:100%;}}
</style>
<style media="screen and (min-width:599px)">.moz-text-html .xc568{width:568px!important;max-width:568px;}.moz-text-html .xc536{width:536px!important;max-width:536px;}.moz-text-html .xc258{width:258px!important;max-width:258px;}.moz-text-html .xc0{width:0px!important;max-width:0;}.moz-text-html .xc310{width:310px!important;max-width:310px;}.moz-text-html .pc100{width:100%!important;max-width:100%;}
</style>
<style type="text/css">
@media only screen and (max-width:598px){table.fwm{width:100%!important;}td.fwm{width:auto!important;}}
</style>
<style type="text/css">
u+.emailify .gs{background:#000;mix-blend-mode:screen;display:inline-block;padding:0;margin:0;}u+.emailify .gd{background:#000;mix-blend-mode:difference;display:inline-block;padding:0;margin:0;}p{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}a[x-apple-data-detectors]{color:inherit!important;text-decoration:none!important;}u+.emailify a{color:inherit!important;text-decoration:none!important;}#MessageViewBody a{color:inherit!important;text-decoration:none!important;}td.b .klaviyo-image-block{display:inline;vertical-align:middle;}
@media only screen and (max-width:599px){.emailify{height:100%!important;margin:0!important;padding:0!important;width:100%!important;}u+.emailify .glist{margin-left:1em!important;}td.ico.v>div.il>a.l.m,td.ico.v .mn-label{padding-right:0!important;padding-bottom:16px!important;}td.x{padding-left:0!important;padding-right:0!important;}.fwm img{max-width:100%!important;height:auto!important;}.aw img{width:auto!important;margin-left:auto!important;margin-right:auto!important;}.ah img{height:auto!important;}td.b.nw>table,td.b.nw a{width:auto!important;}td.stk{border:0!important;}td.u{height:auto!important;}br.sb{display:none!important;}.thd-1 .i-thumbnail{display:inline-block!important;height:auto!important;overflow:hidden!important;}.hd-1{display:block!important;height:auto!important;overflow:visible!important;}.ht-1{display:table!important;height:auto!important;overflow:visible!important;}.hr-1{display:table-row!important;height:auto!important;overflow:visible!important;}.hc-1{display:table-cell!important;height:auto!important;overflow:visible!important;}div.r.pr-16>table>tbody>tr>td,div.r.pr-16>div>table>tbody>tr>td{padding-right:16px!important}div.r.pl-16>table>tbody>tr>td,div.r.pl-16>div>table>tbody>tr>td{padding-left:16px!important}div.g.mb-0>table>tbody>tr>td{padding-bottom:0px!important}}
@media (prefers-color-scheme:light) and (max-width:599px){.ds-1.hd-1{display:none!important;height:0!important;overflow:hidden!important;}}
@media (prefers-color-scheme:dark) and (max-width:599px){.ds-1.hd-1{display:block!important;height:auto!important;overflow:visible!important;}}
</style>
<meta name="color-scheme" content="light dark">
<meta name="supported-color-schemes" content="light dark">
<!--[if gte mso 9]>
<style>a:link,span.MsoHyperlink{mso-style-priority:99;color:inherit;text-decoration:none;}a:visited,span.MsoHyperlinkFollowed{mso-style-priority:99;color:inherit;text-decoration:none;}li{text-indent:-1em;}table,td,p,div,span,ul,ol,li,a{mso-hyphenate:none;}sup,sub{font-size:100% !important;}
</style>
<![endif]-->
</head>
<body lang="en" link="#DD0000" vlink="#DD0000" class="emailify" style="mso-line-height-rule:exactly;mso-hyphenate:none;word-spacing:normal;background-color:#f5f5f5;"><div class="bg" style="background-color:#f5f5f5;" lang="en">
<!--[if mso | IE]>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="r-outlook -outlook pr-16-outlook pl-16-outlook -outlook" role="presentation" style="width:600px;" width="600"><tr><td style="line-height:0;font-size:0;mso-line-height-rule:exactly;">
<![endif]--><div class="r  " style="background:#fffffe;background-color:#fffffe;margin:0px auto;max-width:600px;">
<table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fffffe;background-color:#fffffe;width:100%;"><tbody><tr><td style="border:none;direction:ltr;font-size:0;padding:16px 16px 16px 16px;text-align:left;">
<!--[if mso | IE]>
<table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="c-outlook -outlook -outlook" style="vertical-align:middle;width:568px;">
<![endif]--><div class="xc568 ogf c" style="font-size:0;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;">
<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#9d2362;border:none;vertical-align:middle;" width="100%"><tbody><tr><td align="left" class="i" style="font-size:0;padding:0;word-break:break-word;">
<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0;"><tbody><tr><td style="width:96px;"> <img alt src="https://www.thrive-drive.org:8443/images/Thrive_logo.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" title width="96" height="auto">
</td></tr></tbody></table>
</td></tr></tbody></table></div>
<!--[if mso | IE]>
</td></tr></table>
<![endif]-->
</td></tr></tbody></table></div>
<!--[if mso | IE]>
</td></tr></table>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="r-outlook -outlook pr-16-outlook pl-16-outlook -outlook" role="presentation" style="width:600px;" width="600"><tr><td style="line-height:0;font-size:0;mso-line-height-rule:exactly;">
<![endif]--><div class="r  " style="background:#fffffe;background-color:#fffffe;margin:0px auto;max-width:600px;">
<table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fffffe;background-color:#fffffe;width:100%;"><tbody><tr><td style="border:none;direction:ltr;font-size:0;padding:0px 32px 16px 32px;text-align:left;">
<!--[if mso | IE]>
<table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="c-outlook -outlook -outlook" style="vertical-align:middle;width:536px;">
<![endif]--><div class="xc536 ogf c" style="font-size:0;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;">
<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:none;vertical-align:middle;" width="100%"><tbody><tr><td align="left" class="x" style="font-size:0;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:24px;mso-ansi-font-size:16px;"><span style="font-size:16px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#777777;line-height:150%;mso-line-height-alt:24px;mso-ansi-font-size:16px;">Hello ${firm["Contact"]},</span></p></div>
</td></tr></tbody></table></div>
<!--[if mso | IE]>
</td></tr></table>
<![endif]-->
</td></tr></tbody></table></div>
<!--[if mso | IE]>
</td></tr></table>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="r-outlook -outlook pr-16-outlook pl-16-outlook -outlook" role="presentation" style="width:600px;" width="600"><tr><td style="line-height:0;font-size:0;mso-line-height-rule:exactly;">
<![endif]--><div class="r  " style="background:#fffffe;background-color:#fffffe;margin:0px auto;max-width:600px;">
<table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fffffe;background-color:#fffffe;width:100%;"><tbody><tr><td style="border:none;direction:ltr;font-size:0;padding:0px 32px 16px 32px;text-align:left;">
<!--[if mso | IE]>
<table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="c-outlook -outlook -outlook" style="vertical-align:middle;width:536px;">
<![endif]-->
<!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="c-outlook -outlook -outlook" style="vertical-align:middle;width:536px;">
<![endif]--><div class="xc536 ogf c" style="font-size:0;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;">
<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:none;vertical-align:middle;" width="100%"><tbody><tr><td align="left" class="x" style="font-size:0;word-break:break-word;">`;
};

export const afterTemplate = (user: any, firm: any) => {
  return `</td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table><![endif]-->
  </td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <table align="center" border="0" cellpadding="0" cellspacing="0" class="r-outlook -outlook pr-16-outlook pl-16-outlook -outlook" role="presentation" style="width:600px;" width="600"><tr><td style="line-height:0;font-size:0;mso-line-height-rule:exactly;">
  <![endif]--><div class="r  " style="background:#fffffe;background-color:#fffffe;margin:0px auto;max-width:600px;">
  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fffffe;background-color:#fffffe;width:100%;"><tbody><tr><td style="border:none;direction:ltr;font-size:0;padding:16px 0px 16px 0px;text-align:left;">
  <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:600px;">
  <![endif]--><div class="pc100 ogf" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
  <!--[if mso | IE]>
  <table border="0" cellpadding="0" cellspacing="0" role="presentation"><tr><td style="vertical-align:middle;width:600px;">
  <![endif]--><div class="pc100 ogf c" style="font-size:0;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100.0000%;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"><tbody><tr><td style="border:none;vertical-align:middle;padding:0px 32px 0px 32px;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style width="100%"><tbody><tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:20px;mso-ansi-font-size:14px;"><span style="font-size:14px;font-family:Inter,Arial,sans-serif;font-weight:700;color:#000000;line-height:143%;mso-line-height-alt:20px;mso-ansi-font-size:14px;">${
    user.firstName + " " + user.lastName
  }</span></p></div>
  </td></tr><tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:16px;mso-ansi-font-size:14px;"><span style="font-size:13px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#777777;line-height:115%;mso-line-height-alt:16px;mso-ansi-font-size:14px;">${
    user.title
  }</span></p></div>
  </td></tr><tr><td class="s  m" style="font-size:0;padding:0;padding-bottom:4px;word-break:break-word;" aria-hidden="true"><div style="height:8px;line-height:8px;">&#8202;</div>
  </td></tr><tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><span style="font-size:13px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#111111;line-height:138%;mso-line-height-alt:18px;mso-ansi-font-size:14px;">${
    user.phone
  }</span></p></div>
  </td></tr><tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><span style="font-size:13px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#111111;line-height:138%;mso-line-height-alt:18px;mso-ansi-font-size:14px;">${
    user.email
  }</span></p></div>
  </td></tr><tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><span style="font-size:13px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#111111;line-height:138%;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><a href="${
    user.meeting_url
      ? user.meeting_url
      : "https://outlook.office365.com/owa/calendar/OaklandThriveClintMeetingPage@NETORGFT13100098.onmicrosoft.com/bookings/"
  }">Click to Schedule Your Consultation!</a></span></p></div>
  </td></tr><tr><td class="s" style="font-size:0;padding:0;padding-bottom:0;word-break:break-word;" aria-hidden="true"><div style="height:4px;line-height:4px;">&#8202;</div>
  </td></tr><tr><td class="s" style="font-size:0;padding:0;padding-bottom:0;word-break:break-word;" aria-hidden="true"><div style="height:4px;line-height:4px;">&#8202;</div>
  </td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]--></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]-->
  </td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <table align="center" border="0" cellpadding="0" cellspacing="0" class="r-outlook -outlook pr-16-outlook pl-16-outlook -outlook" role="presentation" style="width:600px;" width="600"><tr><td style="line-height:0;font-size:0;mso-line-height-rule:exactly;">
  <![endif]--><div class="r  " style="background:#eeeeee;background-color:#eeeeee;margin:0px auto;max-width:600px;">
  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#eeeeee;background-color:#eeeeee;width:100%;"><tbody><tr><td style="border:none;direction:ltr;font-size:0;padding:16px 16px 16px 16px;text-align:left;">
  <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:568px;">
  <![endif]--><div class="pc100 ogf" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
  <!--[if mso | IE]>
  <table border="0" cellpadding="0" cellspacing="0" role="presentation"><tr><td style="vertical-align:middle;width:568px;">
  <![endif]--><div class="pc100 ogf c" style="font-size:0;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100.0000%;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:none;vertical-align:middle;" width="100%"><tbody><tr><td align="left" class="o" style="font-size:0;padding:0;word-break:break-word;">
  <!--[if mso | IE]>
  <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation"><tr><td>
  <![endif]-->
  <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"><tbody><tr class="e  m"><td style="padding:0 16px 0 0;vertical-align:middle;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:20px;"><tbody><tr><td style="font-size:0;height:20px;vertical-align:middle;width:20px;"> <a href="https://url.avanan.click/v2/___https:/www.instagram.com/oaklandthrive/___.YXAzOm9ha2dvdjphOm86MWFlZGI5NzNkZjU1MTUyNGM1YzQ2NGQ4MTI5OTMzMmM6Njo2ZDc1Ojc5YmM3MDhhMGViMzg1ZmM1ZDUwNmU0YTQ4MTUyYWJiOGE5NzA5YjQ1NWFmMzFjOWIwMTRhZmI3YWRkMzU2ODA6aDpU" target="_blank"> <img alt="Instagram" title height="20" src="https://cdn.gifo.wisestamp.com/s/inst/E4405F/48/0/background.png" style="display:block;" width="20"></a>
  </td></tr></tbody></table>
  </td></tr></tbody></table>
  <!--[if mso | IE]>
  </td><td>
  <![endif]-->
  <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"><tbody><tr class="e  m"><td style="padding:0 16px 0 0;vertical-align:middle;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:20px;"><tbody><tr><td style="font-size:0;height:20px;vertical-align:middle;width:20px;"> <a href="https://url.avanan.click/v2/___https:/www.facebook.com/oaklandthrive___.YXAzOm9ha2dvdjphOm86MWFlZGI5NzNkZjU1MTUyNGM1YzQ2NGQ4MTI5OTMzMmM6Njo2MmEwOmE4MDEzNjA1NzY3ZDlhMmEzNDg2YmRhNThhN2JmM2JiZGQ2NWVlYjUzZTlmNTVjZWNlNzg2ODA3ZDZiMjMzNDU6aDpU" target="_blank"> <img alt="Facebook" title height="20" src="https://cdn.gifo.wisestamp.com/s/fb/3b5998/48/0/background.png" style="display:block;" width="20"></a>
  </td></tr></tbody></table>
  </td></tr></tbody></table>
  <!--[if mso | IE]>
  </td><td>
  <![endif]-->
  <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"><tbody><tr class="e  m"><td style="padding:0 16px 0 0;vertical-align:middle;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:20px;"><tbody><tr><td style="font-size:0;height:20px;vertical-align:middle;width:20px;"> <a href="https://url.avanan.click/v2/___https:/twitter.com/OaklandThrive___.YXAzOm9ha2dvdjphOm86MWFlZGI5NzNkZjU1MTUyNGM1YzQ2NGQ4MTI5OTMzMmM6Njo0NjEzOmY0MmQ4MmMwMjVhNzRjNTkzYWY2NWY3NzViYTE0ZWY3ZmJjMGJiNDA0MDFiNTkwMzg4NDAyOTk5ZmVjOTk2MzM6aDpU" target="_blank"> <img alt="Twitter" title height="20" src="https://cdn.gifo.wisestamp.com/s/tw/55acee/48/0/background.png" style="display:block;" width="20"></a>
  </td></tr></tbody></table>
  </td></tr></tbody></table>
  <!--[if mso | IE]>
  </td><td>
  <![endif]-->
  <table align="left" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"><tbody><tr class="e  "><td style="padding:0;padding-right:0;vertical-align:middle;">
  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:20px;"><tbody><tr><td style="font-size:0;height:20px;vertical-align:middle;width:20px;"> <a href="https://url.avanan.click/v2/___https:/www.linkedin.com/company/oakland-thrive___.YXAzOm9ha2dvdjphOm86MWFlZGI5NzNkZjU1MTUyNGM1YzQ2NGQ4MTI5OTMzMmM6Njo3YmRjOmM5YjYxNTMzMmEwZjQ1YWYxYTk0ZmE2ZWM4OWI2ZDk1ZTc4MDkyMWRjNDI0ZTI0NjQxZTMzMzQ4YmUxMGRlNWI6aDpU" target="_blank"> <img alt="LinkedIn" title height="20" src="https://cdn.gifo.wisestamp.com/s/ld/0077b5/48/0/background.png" style="display:block;" width="20"></a>
  </td></tr></tbody></table>
  </td></tr></tbody></table>
  <tr><td align="left" class="x  m" style="font-size:0;padding-bottom:4px;word-break:break-word;"><div style="text-align:left;"><p style="Margin:0;text-align:left;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><span style="font-size:13px;font-family:Inter,Arial,sans-serif;font-weight:400;color:#111111;line-height:138%;mso-line-height-alt:18px;mso-ansi-font-size:14px;"><a href="https://thrive-drive.org/case?id=${
    firm.openCase
  }">reference#:${firm.openCase}</a></span></p></div>
  </td></tr>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]-->
  </td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]--></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]-->
  </td></tr></tbody></table></div>
  <!--[if mso | IE]>
  </td></tr></table>
  <![endif]--></div>
  <div style="font-size: 10px;
  text-align: center;"><h3 style="text-decoration-line: underline;"><a style="cursor:pointer;" href="https://www.thrive-drive.org/unsubscribe?id=643c6d72578d630030df61e6" target="_blank">Unsubscribe</a></h3><p>You are receiving this email because of your history of grant application. You have the ability to opt out (unsubscribe)</p><p> at anytime, and you will no longer receive message fron Oakland Thrive Program. </p><p>Contact support@thrive-drive.org for more information</p></div>
  </body>
  </html>`;
};

export const DirectEmailEmptyBody = (
  user: any,
  firm: any,
  theCase: any,
  referral: any,
  provider: any,
  resource: any,
  event: any,
  template_block: any
) => {
  const htmlbody = `
    ${beforeTemplate(firm)}
    ${defaultTemplate}
    ${afterTemplate(user, firm)}`;
  return htmlbody;
};
