import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { assistances } from "../Interaction";
import Cookies from "js-cookie";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DaysLeft } from "../../Dashboard/EventList/TaskCard";
import moment from "moment";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { StyledTextarea } from "../../common/Textareas";

const decodeTimeLine = (timeline: any) => {
  switch (timeline) {
    case "30 days":
      return 30;
    case "60 days":
      return 60;
    case "90 days":
      return 90;
    default:
      return "On going";
  }
};

const timelineSelections = ["30 days", "60 days", "90 days", "On going"];
const actions = ["Direct Consulting", "Resource Referral", "B2B Referral"];

const compareByDate = (a: any, b: any) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

const getBusinessActionPlan = (activty: any) => {
  const disAnswers = activty.filter((activityItem: any) => {
    return activityItem.activity_type === "Business Action Plan";
  });
  disAnswers.sort(compareByDate);
  if (disAnswers.length > 0) {
    return disAnswers[0]["action_plan_id"];
  } else {
    return "";
  }
};

const BusinessActionPlan = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const navigate = useNavigate();
  const [actionPlan, setActionPlan] = React.useState([
    {
      businessObjective: "",
      timeLine: "",
      action: "Direct Consulting",
      completion: false,
      resource: "",
      note: "",
      startDate: dayjs(),
      updateDate: new Date(),
    },
  ]);
  const existedActionPlanID = getBusinessActionPlan(props.activity);

  React.useEffect(() => {
    if (existedActionPlanID) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/actionPlan?action_plan_id=${existedActionPlanID}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            let actionPlan = res.data.content;
            actionPlan.map((action: any) => {
              action["daysLeft"] =
                typeof decodeTimeLine(action.timeLine) === "number"
                  ? moment(action["startDate"])
                      .add(decodeTimeLine(action.timeLine), "days")
                      .diff(moment(), "days")
                  : 9999;
            });

            let sortedActionPlan = actionPlan.sort(
              (a: any, b: any) => a.daysLeft - b.daysLeft
            );

            setActionPlan(sortedActionPlan);
          } else {
            console.log("API Error at getting survey result");
          }
        });
    }
  }, []);

  const handleSumbit = () => {
    let simplifiedActionPlan = actionPlan.filter((item) => {
      return item.businessObjective;
    });
    // console.log(simplifiedActionPlan);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/actionPlan`,
        {
          activity_type: "Business Action Plan",
          action_plan: {
            content: simplifiedActionPlan,
            case_id: props.case._id,
            _id: existedActionPlanID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          props.setCardOpen(false);
          props.setDataFlag(!props.dataFlag);
        } else {
          console.log("API Error");
        }
      });
    // props.setCardOpen(false);
  };

  const handleChange = (event_value: any, index: number, field: any) => {
    let tempArray = [...actionPlan];
    let tempObject = tempArray[index];
    // console.log(event_value);
    //@ts-ignore
    tempObject[field] = event_value;
    if (event_value === "B2B Referral") {
      tempObject["timeLine"] = "30 days";
    }
    setActionPlan(tempArray);
  };

  const handleAddRow = () => {
    let newBusinessObjectiveRow = {
      businessObjective: "",
      timeLine: "",
      action: "",
      completion: false,
      resource: "",
      note: "",
      startDate: dayjs(),
      updateDate: new Date(),
    };
    setActionPlan([...actionPlan, newBusinessObjectiveRow]);
  };

  const handleResourceSelection = () => {
    // console.log("Resource Selection");
    let simplifiedActionPlan = actionPlan.filter((item) => {
      return item.businessObjective;
    });
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/actionPlan`,
        {
          activity_type: "Business Action Plan",
          action_plan: {
            content: simplifiedActionPlan,
            case_id: props.case._id,
            _id: existedActionPlanID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          navigate(
            `/dashboard/resources?case_id=${props.case._id}&action_plan_id=`
          );

          // console.log(res.data);
          // props.setCardOpen(false);
          // props.setDataFlag(!props.dataFlag);
        } else {
          console.log("API Error");
        }
      });
  };

  const handleB2BSelection = () => {
    // console.log("B2B Selection");
    let simplifiedActionPlan = actionPlan.filter((item) => {
      return item.businessObjective;
    });
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/actionPlan`,
        {
          activity_type: "Business Action Plan",
          action_plan: {
            content: simplifiedActionPlan,
            case_id: props.case._id,
            _id: existedActionPlanID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          navigate(
            `/dashboard/providers?case_id=${props.case._id}&action_plan_id=`
          );

          // console.log(res.data);
          // props.setCardOpen(false);
          // props.setDataFlag(!props.dataFlag);
        } else {
          console.log("API Error");
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          Business Action Plan(s)
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setCardOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>
      <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 520 } }}>
        {actionPlan.map((action: any, index: number) => {
          return (
            <TableContainer key={index} component={"table"}>
              {action.daysLeft && (
                <caption>
                  <Stack
                    direction={"row"}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <AccessAlarmIcon
                      sx={{ fontSize: "44px" }}
                      color={
                        action.daysLeft < 0
                          ? "error"
                          : action.daysLeft < 7
                          ? "warning"
                          : "success"
                      }
                    ></AccessAlarmIcon>
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {action.businessObjective}
                      </Typography>
                      <DaysLeft action={action}></DaysLeft>
                    </Box>
                    <Box></Box>
                  </Stack>
                </caption>
              )}

              <TableBody
                sx={{ display: "table", width: "100%", tableLayout: "fixed" }}
              >
                <TableRow key={index}>
                  <TableCell sx={{ width: "240px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ display: "block", width: "100%" }}
                      >
                        <DatePicker
                          sx={{ width: "50px" }}
                          label="Action Plan Date"
                          value={dayjs(action.startDate)}
                          onChange={(value: any) => {
                            let tempArray = [...actionPlan];
                            let tempObject = tempArray[index];
                            //@ts-ignore
                            tempObject["startDate"] = value;
                            setActionPlan(tempArray);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Timeline
                      </InputLabel>
                      <Select
                        disabled={action.action === "B2B Referral"}
                        label="Timeline"
                        onChange={(e: any) => {
                          handleChange(e.target.value, index, "timeLine");
                        }}
                        value={
                          action.action === "B2B Referral"
                            ? "30 days"
                            : action.timeLine
                        }
                      >
                        {timelineSelections.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Action
                      </InputLabel>
                      <Select
                        label="Action"
                        onChange={(e: any) => {
                          handleChange(e.target.value, index, "action");
                        }}
                        value={action.action}
                      >
                        {actions.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    {action.action === "Direct Consulting" ? (
                      <Button
                        disabled
                        variant="contained"
                        sx={{ width: "150px" }}
                      >
                        Direct Consulting
                      </Button>
                    ) : (
                      <></>
                    )}
                    {action.action === "Resource Referral" ? (
                      <Button
                        variant="contained"
                        onClick={handleResourceSelection}
                        sx={{ width: "150px" }}
                      >
                        Resource Referral
                      </Button>
                    ) : (
                      <></>
                    )}
                    {action.action === "B2B Referral" ? (
                      <Button
                        variant="contained"
                        onClick={handleB2BSelection}
                        sx={{ width: "150px" }}
                      >
                        B2B<br></br> Referral
                      </Button>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction={"row"}>
                      <Typography sx={{ margin: "auto", marginRight: "0px" }}>
                        Completed?
                      </Typography>
                      <Checkbox
                        checked={action.completion}
                        onChange={(e: any) => {
                          handleChange(e.target.checked, index, "completion");
                        }}
                      ></Checkbox>
                      <RemoveCircleOutlineIcon
                        sx={{ color: "red", margin: "auto" }}
                        onClick={() => {
                          setActionPlan(
                            actionPlan.filter((item, temp_index) => {
                              return temp_index !== index;
                            })
                          );
                        }}
                      ></RemoveCircleOutlineIcon>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    border: "5px solid #941566",
                    borderTop: "hidden",
                    borderLeft: "hidden",
                    borderRight: "hidden",
                  }}
                  key={index + "_note"}
                >
                  <TableCell sx={{ paddingTop: 0 }}>
                    <Stack direction={"row"} sx={{ width: "900px" }}>
                      <FormControl sx={{ margin: "auto", width: "250px" }}>
                        <InputLabel id="demo-multiple-chip-label">
                          Business Objective
                        </InputLabel>
                        <Select
                          label="Business Objective"
                          onChange={(e: any) => {
                            handleChange(
                              e.target.value,
                              index,
                              "businessObjective"
                            );
                          }}
                          value={action.businessObjective}
                        >
                          {assistances.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <StyledTextarea
                        sx={{
                          marginTop: "8px",
                          marginLeft: "30px",
                          width: { xs: "300px", md: "670px" },
                        }}
                        minRows={1.5}
                        placeholder="Business Action Plan Note"
                        value={action.note}
                        onChange={(e) => {
                          handleChange(e.target.value, index, "note");
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          );
        })}

        <Button
          sx={{
            width: "100%",
            textAlign: "center",
          }}
          onClick={handleAddRow}
        >
          Add Business Objective
        </Button>
        <Box
          sx={{
            bottom: 20,
            width: "100%",
            textAlign: "center",
            position: "fixed",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "20px",
              backgroundColor: "#9E1F63FF",
            }}
            onClick={handleSumbit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BusinessActionPlan;
