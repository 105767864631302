import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Cookies from "js-cookie";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import ReportTable from "./ReportTable";
dayjs.extend(utc);
dayjs.extend(dayOfYear);

const extractTextFromHTML = (html: any) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const textContent = doc.body.textContent || "";
  const lines = textContent.split("\n").filter((line) => line.trim() !== "");
  return lines.join("\n");
};

const arrayToObj = (arr: any) => {
  let obj: any = {};
  arr.map((item: any) => {
    obj[item._id] = item;
    return null;
  });
  return obj;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
  },
  text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 5,
  },
  table_row: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
  },
  table_row_odd: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
    backgroundColor: "rgb(235, 235, 235)",
  },
  table_text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 5,
    flex: 1,
  },
  checkbox_text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 2,
    flex: 1,
  },
  section_title: {
    fontSize: 18,
    color: "black",
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
    paddingVertical: 5,
    fontWeight: "bold", // Bold font weight for section titles
    backgroundColor: "#e0e0e0", // Light grey background color
    borderLeftWidth: 6, // Left border
    borderLeftColor: "rgb(158, 31, 99)", // Border color matching the page title background
  },
  page_title: {
    fontSize: 24,
    color: "white",
    backgroundColor: "rgb(158, 31, 99)",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 15,
    paddingVertical: 10,
    fontWeight: "bold", // Bold font weight
    textTransform: "uppercase", // Uppercase text
  },
  section_space: {
    marginTop: 40, // Adds space between section
  },

  image: {
    width: 100,
    height: 100,
  },
  resource_image: { maxWidth: "100%", maxHeight: "200px", padding: "10px" },
  mainTitle: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  header: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%", // Make the line span the full width of the page
    marginTop: -20, // Add some margin at the bottom
    marginBottom: 10,
  },
  hr_nomargin: {
    borderBottomColor: "black",
    borderBottomWidth: 5,
    width: "100%", // Make the line span the full width of the page
    // marginTop: 10, // Add some margin at the bottom
    marginBottom: 10,
  },
  half_left: { textAlign: "left", width: "49%", height: "100%" },
  half_right: { textAlign: "left", width: "49%", height: "100%" },
  checkbox: {
    width: 10,
    height: 10,
    border: "1 solid black",
    display: "flex",
  },
  one_thired: {
    textAlign: "left",
    width: "33%",
    height: "100%",
    minHeight: "100px",
  },
  two_third: { textAlign: "left", width: "66%", height: "100%" },
  surveyTable: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  surveyTableCellHeader: {
    backgroundColor: "#E4E4E4",
    fontSize: 12,
    fontWeight: "bold",
  },
  surveyTableCell: {
    fontSize: 10,
  },
  surveyTableRow: {
    flexDirection: "row",
  },
  surveyTableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  text_left: {
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 5,
    flex: 1,
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
    width: "33%",
  },
  text_right: {
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: 12,

    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
    width: "66%",
  },
});

const BFCReportPdf = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const startDate = props.startDate;
  const endDate = props.endDate;
  const [caseToFirmMapping, setCaseToFirmMapping] = useState<any>({});
  const [emails, setEmails] = useState<any>([]);
  const [dataFlag, setDataFlag] = useState(false);
  const [caseInteractions, setCaseInteractions] = useState<any>({});
  const [reportData, setReportData] = useState<any>({});

  const startDateString = startDate ? startDate.format("YYYY-MM-DD") : "";
  const endDateShow = endDate || dayjs();
  const endDateString = endDateShow.format("YYYY-MM-DD");

  useEffect(() => {
    if (startDate === null && endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/bfcReport?userId=${props.user._id}`,
          axiosConfig
        )
        .then((result) => {
          setEmails(result.data.emailActivitiesMapping);
          setCaseToFirmMapping(result.data.caseToFirmMapping);
          setCaseInteractions(result.data.caseToInteractionsMapping);
          setReportData(result.data.reportData);
          setDataFlag(true);
        });
    } else if (startDate !== null && endDate === null) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/bfcReport?userId=${
            props.user._id
          }&startDate=${startDate.toDate()}`,
          axiosConfig
        )
        .then((result) => {
          setEmails(result.data.emailActivitiesMapping);
          setCaseToFirmMapping(result.data.caseToFirmMapping);
          setCaseInteractions(result.data.caseToInteractionsMapping);
          setReportData(result.data.reportData);
          setDataFlag(true);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/bfcReport?userId=${
            props.user._id
          }&startDate=${startDate.toDate()}&endDate=${endDate.toDate()}`,
          axiosConfig
        )
        .then((result) => {
          setEmails(result.data.emailActivitiesMapping);
          setCaseToFirmMapping(result.data.caseToFirmMapping);
          setCaseInteractions(result.data.caseToInteractionsMapping);
          setReportData(result.data.reportData);
          setDataFlag(true);
        });
    }
  }, []);

  if (dataFlag) {
    return (
      <>
        <PDFViewer width="100%" height="700px">
          <Document>
            <Page size="LETTER" style={styles.page} key="reportData">
              <View>
                <Text style={styles.page_title}>Report Data</Text>
                {startDate && (
                  <Text style={styles.section_title}>
                    {startDateString} to {endDateString}
                  </Text>
                )}
              </View>
              <ReportTable data={reportData} />
            </Page>
            {Object.keys(caseInteractions).map(
              (caseId: any, caseInteractionIndex: number) => {
                if (!caseToFirmMapping[caseId]) {
                  return <></>;
                }
                const caseActivities = caseInteractions[caseId].activities;
                const caseActionPlans = caseInteractions[caseId].actionPlans;
                const bussAssessmentSurvey =
                  caseInteractions[caseId].surveyContent;
                const surveyResult = caseInteractions[caseId].surveyResult;

                const firmName =
                  caseToFirmMapping && caseToFirmMapping[caseId]
                    ? caseToFirmMapping[caseId]["Company Name"] ||
                      "Name not available"
                    : "Name not available";
                return (
                  <Page size="LETTER" style={styles.page} key={`${caseId}`}>
                    <View>
                      <Text style={styles.page_title}>{firmName}</Text>
                    </View>
                    <View style={styles.section_space}>
                      <Text style={styles.section_title}>Activities</Text>
                    </View>
                    {caseActivities.map((item: any, index: number) => {
                      return (
                        <View key={`${item}_${index}`} wrap={false}>
                          <View>
                            <Text>
                              {new Intl.DateTimeFormat("en-US").format(
                                new Date(item.created_at)
                              )}{" "}
                              - {item.activity_type}
                            </Text>
                            <View style={styles.hr_nomargin}></View>
                          </View>

                          <View style={styles.table_row}>
                            <Text style={styles.text_left}>Assistance:</Text>
                            <Text style={styles.text_right}>
                              {item.assistance_requested
                                ? item.assistance_requested.join(", ")
                                : ""}
                            </Text>
                          </View>

                          {item.activity_type === "Event Invitation" ||
                          item.activity_type === "Email Communication" ||
                          item.activity_type ===
                            "Provider Referral Initiated" ||
                          item.activity_type === "Providers Referral Email" ? (
                            <View style={styles.table_row_odd}>
                              <Text style={styles.text_left}>Email:</Text>
                              <Text style={styles.text_right}>
                                {extractTextFromHTML(
                                  emails[item["email_id"]]["htmlContent"]
                                )}
                              </Text>
                            </View>
                          ) : (
                            <View style={styles.table_row_odd}>
                              <Text style={styles.text_left}>Notes:</Text>
                              <Text style={styles.text_right}>
                                {item.activity_note}
                              </Text>
                            </View>
                          )}

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.text}>
                              Contact Time:{item.activity_contact_time}
                            </Text>
                            <Text style={styles.text}>
                              Prep Time:{item.activity_prep_time}
                            </Text>
                            <Text style={styles.text}>
                              Travel Time:{item.activity_travel_time}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                    <View style={styles.section_space}>
                      <Text style={styles.section_title}>Action Plans</Text>
                    </View>
                    {caseActionPlans.map(
                      (actionPlan: any, actionPlanIndex: number) => {
                        return (
                          actionPlan.content.length > 0 &&
                          actionPlan.content.map(
                            (actionPlanContent: any, contentIndex: any) => {
                              const contentStartDate =
                                actionPlanContent.startDate;
                              let formattedDate;

                              if (contentStartDate) {
                                const date = new Date(contentStartDate);
                                formattedDate = !isNaN(date.getTime())
                                  ? new Intl.DateTimeFormat("en-US").format(
                                      date
                                    )
                                  : "Invalid date";
                              } else {
                                formattedDate = "No date provided";
                              }

                              return (
                                <View
                                  key={`${actionPlanIndex}_${contentIndex}`}
                                  wrap={false}
                                >
                                  <View>
                                    <Text style={{ fontSize: 14 }}>
                                      {formattedDate} -{" "}
                                      {actionPlanContent.action}
                                    </Text>
                                    <View style={styles.hr_nomargin}></View>
                                  </View>

                                  <View style={styles.table_row}>
                                    <Text style={styles.text_left}>
                                      Business Objective:
                                    </Text>
                                    <Text style={styles.text_right}>
                                      {actionPlanContent.businessObjective}
                                    </Text>
                                  </View>

                                  <View style={styles.table_row}>
                                    <Text style={styles.text_left}>
                                      Time Line:
                                    </Text>
                                    <Text style={styles.text_right}>
                                      {actionPlanContent.timeLine}
                                    </Text>
                                  </View>

                                  <View style={styles.table_row}>
                                    <Text style={styles.text_left}>Note:</Text>
                                    <Text style={styles.text_right}>
                                      {actionPlanContent.note}
                                    </Text>
                                  </View>
                                </View>
                              );
                            }
                          )
                        );
                      }
                    )}
                    <View style={styles.section_space}>
                      <Text style={styles.section_title}>
                        Business Assessments
                      </Text>
                    </View>
                    {surveyResult && (
                      <View style={styles.surveyTable}>
                        <View style={styles.surveyTableRow}>
                          <View
                            style={[
                              styles.surveyTableCol,
                              styles.surveyTableCellHeader,
                            ]}
                          >
                            <Text>Question</Text>
                          </View>
                          <View
                            style={[
                              styles.surveyTableCol,
                              styles.surveyTableCellHeader,
                            ]}
                          >
                            <Text>Answer</Text>
                          </View>
                        </View>
                        {bussAssessmentSurvey.map(
                          (content: any, index: number) => (
                            <View
                              style={styles.surveyTableRow}
                              key={content.name}
                            >
                              <View style={styles.surveyTableCol}>
                                <Text style={styles.surveyTableCell}>
                                  {content.pageTitle}
                                  {" - "}
                                  {content.title}
                                </Text>
                              </View>
                              <View style={styles.surveyTableCol}>
                                <Text style={styles.surveyTableCell}>
                                  {surveyResult[content.name] || ""}
                                </Text>
                              </View>
                            </View>
                          )
                        )}
                      </View>
                    )}
                  </Page>
                );
              }
            )}
          </Document>
        </PDFViewer>
      </>
    );
  } else {
    return <></>;
  }
};

export default BFCReportPdf;
