import React from "react";
import { NavLink } from "react-router-dom";

const NavBarItem = (props: any) => {
  return (
    <div className="p-2 font-bold text-gray-400">
      <NavLink
        target={props.newTab ? "_blank" : undefined}
        to={props.toURL}
        className={({ isActive }) =>
          isActive
            ? "text-indigo-800 [&>div>div:nth-child(2)]:bg-indigo-100 [&>div>div:nth-child(2)]:rounded-full"
            : "hover:text-indigo-800"
        }
      >
        <div className="flex items-center">
          <div className="px-1">{props.icon}</div>
          <div className="p-3 text-left flex flex-wrap hover:bg-indigo-100 hover:rounded-full">
            {props.text}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default NavBarItem;
