import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, settings } from "survey-core";
import "survey-core/defaultV2.min.css";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import findObjectDifferences from "../../../utils/utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import ThriveModal from "../../common/ThriveModal";
import ThriveTitle from "../../common/ThriveTitle";
import SurveyJsPanel from "../../Dashboard/Reports/SurveyJsPanel";
settings.titleTags.panel = "h6";

//This is the actual survey content but not used in production, for development purpose only
// const surveyJson = {
//   pages: [
//     {
//       name: "page1",
//       title: "Finance/Fiscal",
//       elements: [
//         {
//           name: "q1",
//           title: "Does your business need assistance?",

//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q2",
//           title: "How did your business perform in the last quarter?",
//           type: "radiogroup",
//           choices: [
//             "Profitability",
//             "Return on Investment",
//             "Operating Efficiently",
//             "Positive Cash Flow",
//             "Negative Cash Flow",
//             "Breaking Even",
//           ],
//         },
//         {
//           type: "checkbox",
//           name: "q3",
//           title: "What are your financial goals? ",
//           description: "(Select all that apply)",
//           choices: [
//             "Better Cash Flow Management ",
//             "Investing More in Marketing",
//             "Reducing Overall Expenses",
//             "Investing Back Into the Business",
//             "Optimizing the Service Pricing ",
//             "Diversifying Stream of Income ",
//             "Saving for Retirement",
//           ],
//         },
//         {
//           type: "checkbox",
//           name: "q4",
//           title: "What is your most pressing challenge(s)?",
//           description: "(Select all that apply)",
//           choices: [
//             "Revenue, Cash Flow ",
//             "Supply Chain Issues ",
//             "Customer Acquisition ",
//             "Healthcare",
//             "Workforce - Employee Staffing and Recruiting",
//             "Scaling - Preparation and Planning ",
//             "Lack of Time ",
//             "Fiscal Management",
//             "Taxes",
//             "Operational Compliance (Policies and Procedures)",
//           ],
//         },
//         {
//           name: "page1_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//     {
//       name: "page2",
//       title: "Human Resources",
//       elements: [
//         {
//           name: "q5",
//           title: "Does your business need assistance?",

//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q6",
//           title: "What are your top staffing challenges?",
//           type: "radiogroup",
//           choices: [
//             "Finding qualified candidates",
//             "High turnover",
//             "Keeping employees engaged",
//           ],
//           showOtherItem: true,
//         },
//         {
//           type: "checkbox",
//           name: "q7",
//           title: "What kind of training would you be interested in?",
//           description: "(Select all that apply)",
//           choices: [
//             "Managerial",
//             "Quality Training - (ex,. Quality of Service, Customer Satisfaction)",
//             "Job Safety Training - OSHEA - Job Safety ",
//             "Onboarding Training",
//             "Technical & Skills Training",
//             "Compliance Training",
//             "Discrimination Training",
//             "Business Conduct Training",
//           ],
//         },
//         {
//           name: "q7-onboard-training",
//           type: "checkbox",
//           title: "What kind of Onboarding Training would you be interested in?",
//           visibleIf: "{q7} allof ['Onboarding Training']",
//           choices: ["New Hires", "Promotions"],
//         },
//         {
//           name: "q7-tech-skill",
//           type: "checkbox",
//           title:
//             "What kind of Technical & Skills Training would you be interested in?",
//           visibleIf: "{q7} allof ['Technical & Skills Training']",
//           choices: ["New skills training", "Computer"],
//         },
//         {
//           name: "page2_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//     {
//       name: "page3",
//       title: "Marketing",
//       elements: [
//         {
//           name: "q8",
//           title: "Does your business need assistance?",
//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q9",
//           title: "Does your business have a website?",
//           type: "radiogroup",
//           choices: ["Yes", "No"],
//         },
//         {
//           name: "q10",
//           title: "Do you have a marketing plan?",
//           type: "radiogroup",
//           choices: ["Yes", "No", "In Development "],
//         },
//         {
//           type: "checkbox",
//           name: "q11",
//           title: "What kind of training would you be interested in?",
//           description: "(Select all that apply)",
//           choices: [
//             "Marketing Fundamentals - 5 Marketing",
//             "Social Media",
//             "Brand Development and Awareness",
//             "Introduction to Digital Marketing - Digital marketing basics ",
//             "Market Research",
//             "Website Creation",
//             "Video Marketing",
//             "Advertising",
//             "Marketing and Business Strategy ",
//             "Marketing Basics",
//           ],
//         },
//         {
//           name: "q11-social-media",
//           type: "checkbox",
//           title: "What kind of Social Media would you be interested in?",
//           description: "(Select all that apply)",
//           visibleIf: "{q11} allof ['Social Media']",
//           choices: [
//             "Basics of social media",
//             "Starting a social media campaign",
//             "Managing a social media campaign",
//           ],
//         },
//         {
//           name: "q11-brand-development",
//           type: "checkbox",
//           title:
//             "What kind of Brand Development and Awareness would you be interested in?",
//           visibleIf: "{q11} allof ['Brand Development and Awareness']",
//           choices: [
//             "Establishing brand awareness",
//             "Reshaping brand awareness",
//             "Building brand awareness",
//           ],
//         },
//         {
//           type: "checkbox",
//           name: "q12",
//           title: "What is your most pressing challenge(s)?",
//           description: "(Select all that apply)",
//           choices: [
//             "Social Media Marketing",
//             "Website Creation",
//             "Website Management",
//             "Video Management",
//             "Marketing and Business Strategy",
//           ],
//         },
//         {
//           name: "page3_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//     {
//       name: "page4",
//       title: "Operations",
//       elements: [
//         {
//           name: "q13",
//           title: "Does your business need assistance?",
//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q14",
//           title: "Describe your management team?",
//           type: "radiogroup",
//           choices: [
//             "Functional - Operational teams (ex. accounting, marketing,  HR)",
//             "Cross-functional - Inter-working team",
//             "Cross-functional - Inter-departmental teams",
//             "Self-managed",
//             "Virtual",
//             "Contract",
//             "Task Force",
//             "Executive Management",
//           ],
//         },
//         {
//           type: "checkbox",
//           name: "q15",
//           title: "What kind of training would you be interested in?",
//           description: "(Select all that apply)",
//           choices: [
//             "Compliance Training",
//             "Marketing Training",
//             "Customer Service Training",
//             "Leaderships Training",
//             "Soft Skills Training",
//             "Financial Management Training",
//             "Media - PR Training",
//           ],
//         },
//         {
//           type: "checkbox",
//           name: "q16",
//           title: "What is your most pressing challenge(s)?",
//           description: "(Select all that apply)",
//           choices: [
//             "Compliance Training",
//             "Marketing Training",
//             "Customer Service Training",
//             "Leaderships Training",
//             "Soft Skills Training",
//             "Financial Management Training",
//             "Media - PR Training",
//           ],
//           showOtherItem: true,
//         },
//         {
//           name: "page4_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//     {
//       name: "page5",
//       title: "Information Technology",
//       elements: [
//         {
//           name: "q17",
//           title: "Does your business need assistance?",
//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q18",
//           title:
//             "What CRM (Customer Relationship Management) System are you currently using to collect data?",
//           description: "(Select all that apply)",
//           type: "checkbox",
//           choices: [
//             "Sales Forces",
//             "HubSpot",
//             "Zendesk",
//             "Oracle NetSuite",
//             "Google Suite",
//             "Zoho",
//           ],
//           showNoneItem: true,
//           showOtherItem: true,
//         },
//         {
//           type: "checkbox",
//           name: "q19",
//           title: "What kind of training would you be interested in?",
//           description: "(Select all that apply)",
//           choices: [
//             "Excel",
//             "CRM System for my industry",
//             "Google Suite",
//             "Microsoft 365",
//           ],
//           showOtherItem: true,
//         },
//         {
//           type: "checkbox",
//           name: "q20",
//           title: "What is your most pressing challenge(s)?",
//           description: "(Select all that apply)",
//           choices: [
//             "Capturing Leads",
//             "Capturing Sales",
//             "Capturing Client Information ",
//             "Maintaining Client Information ",
//             "Using spreadsheets",
//           ],
//           showOtherItem: true,
//         },
//         {
//           name: "page5_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//     {
//       name: "page6",
//       title: "New Business (Start Up Phase)",
//       elements: [
//         {
//           name: "q21",
//           title: "Does your business need assistance?",
//           type: "radiogroup",
//           choices: ["Yes", "NO"],
//         },
//         {
//           name: "q22",
//           title: "What is your target market?",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//         {
//           type: "radiogroup",
//           name: "q23",
//           title: "Do you have a marketing plan?",
//           choices: ["Yes", "No", "In Progress "],
//         },
//         {
//           type: "radiogroup",
//           name: "q24",
//           title: "How many employees do you have?",

//           choices: ["0-10", "11-49", "50-100", "100-250", "250+"],
//           showOtherItem: true,
//         },
//         {
//           type: "checkbox",
//           name: "q25",
//           title: "What is your most pressing challenge(s)?",
//           description: "(Select all that apply)",
//           choices: [
//             "Revenue, Cash Flow",
//             "Supply Chain Issues",
//             "Customer Acquisition Process",
//             "Healthcare",
//             "Workforce - Employee Staffing and Recruiting",
//             "Lack of Time",
//             "Fiscal Management",
//             "Taxes Regulations",
//             "Operational Compliance",
//             "HR Basics",
//           ],
//         },
//         {
//           name: "q25-supply-chain",
//           type: "checkbox",
//           title: "What kind of Supply Chain issues do you face?",
//           visibleIf: "{q25} allof ['Supply Chain Issues']",
//           choices: ["Access to supplies", "Timely receipt of supplies"],
//           showOtherItem: true,
//         },
//         {
//           name: "q25-customer-acquisition",
//           type: "checkbox",
//           title: "What kind of Customer Acquisition Process challenge?",
//           visibleIf: "{q25} allof ['Customer Acquisition Process']",
//           choices: [
//             "Identifying my target market",
//             "Identifying where my target market is",
//             "Optimizing my website",
//             "Producing frequent quality content",
//             "Creating a referral strategy",
//             "Tracking my customers",
//           ],
//         },
//         {
//           name: "page6_note",
//           title: "Notes",
//           type: "comment",
//           rows: 3,
//           autoGrow: true,
//           allowResize: false,
//         },
//       ],
//     },
//   ],
//   showTOC: true,
// };

const compareByDate = (a: any, b: any) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

const getSurveyResult = (activty: any) => {
  const disAnswers = activty.filter((activityItem: any) => {
    return activityItem.activity_type === "Business Assessment";
  });
  disAnswers.sort(compareByDate);
  if (disAnswers.length > 0) {
    return disAnswers[0]["survey_result_id"];
  } else {
    return null;
  }
};

const setSurveyValue = (survey: any, data: any) => {
  survey.data = data["survey_result"];
};

const BusinessNeedsAssessment = (props: any) => {
  const [graphSurveyResultOpen, setGraphSurveyResultOpen] = useState(false);
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const [originalSurveyData, setOriginalServeyData] = useState({
    survey_result: {},
    _id: "",
    survey_date: dayjs(),
  });
  const [surveyDate, setSurveyDate] = useState(dayjs());
  const [surveyJson, setSurveyJson] = useState({});

  const getSurveyContent = async (survey_id: any) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/survey?id=${survey_id}`,
      axiosConfig
    );

    if (res.status === 200) {
      return res.data.survey.survey_content;
    } else {
      console.log("API Error at getting survey data");
      return {};
    }
  };

  const currentBNASurveyId = "6504ae6817baa329c061dee7";
  useEffect(() => {
    const fetchSurveyData = async () => {
      const existedSurveyID = getSurveyResult(props.activity);
      if (existedSurveyID) {
        axios
          .get(
            `${process.env.REACT_APP_API_DOMAIN}/survey/result?survey_result_id=${existedSurveyID}`,
            axiosConfig
          )
          .then(async (res) => {
            if (res.status === 200) {
              survey.data = res.data.survey_result;
              setSurveyJson(await getSurveyContent(currentBNASurveyId));
              // setSurveyJson(await getSurveyContent(res.data.survey_id));
              setOriginalServeyData(res.data);
              setSurveyDate(res.data.survey_date);
            } else {
              console.log("API Error at getting survey result");
            }
          });
      } else {
        setSurveyJson(await getSurveyContent(currentBNASurveyId));
      }
    };

    fetchSurveyData().catch(console.error);
  }, [props.activity]);

  const survey = new Model(surveyJson);
  survey.onComplete.add((sender, options) => {
    if (
      Object.keys(
        findObjectDifferences(
          {
            survey_result: originalSurveyData.survey_result,
            survey_date: originalSurveyData.survey_date,
          },
          {
            survey_result: sender.data,
            survey_date: surveyDate,
          }
        )
      ).length > 0
    ) {
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/survey/result`,
          {
            activity_type: "Business Assessment",
            activity_date: surveyDate,
            surveyResult: {
              _id: originalSurveyData._id,
              survey_result: sender.data,
              survey_date: surveyDate,
              case_id: props.case._id,
              survey_id: currentBNASurveyId,
            },
          },
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            props.setCardOpen(false);
            props.setDataFlag(!props.dataFlag);
          } else {
            console.log("API Error");
          }
        });
    } else {
      console.log("No difference");
    }

    props.setCardOpen(false);
  });
  survey.data = originalSurveyData.survey_result;
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          Business Needs Assessment
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setCardOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>
      <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 520 } }}>
        <Box sx={{ textAlign: "center" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Box></Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                sx={{ display: "block" }}
              >
                <DatePicker
                  label="Business Needs Assessment Date"
                  value={dayjs(surveyDate)}
                  onChange={(value) => {
                    setSurveyDate(value as Dayjs);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {originalSurveyData._id && (
              <Button
                variant="outlined"
                endIcon={
                  <img
                    src={`${process.env.REACT_APP_API_DOMAIN}/images/bar_chart_icon.png`}
                    width={30}
                    height={30}
                  />
                }
                sx={{
                  color: "#9E1F63FF",
                  borderColor: "#9E1F63FF",
                }}
                onClick={() => {
                  setGraphSurveyResultOpen(true);
                }}
              >
                Graph Results
              </Button>
            )}
          </Stack>
        </Box>
        <Survey model={survey} />
      </Box>
      <ThriveModal
        open={graphSurveyResultOpen}
        setOpen={setGraphSurveyResultOpen}
        contentComponent={
          <>
            <ThriveTitle
              title="Discovery Question Survey Result"
              setOpen={setGraphSurveyResultOpen}
            ></ThriveTitle>
            <Box sx={{ margin: 1, height: "580px", overflow: "auto" }}>
              {graphSurveyResultOpen &&
              surveyJson && [originalSurveyData.survey_result] ? (
                <SurveyJsPanel
                  surveyContent={surveyJson}
                  surveyResult={[originalSurveyData.survey_result]}
                ></SurveyJsPanel>
              ) : (
                <></>
              )}
            </Box>
          </>
        }
      ></ThriveModal>
    </>
  );
};

export default BusinessNeedsAssessment;
