import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

const ActivityCard = (props: any) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#ffffff",
          height: 100,
          cursor: typeof props.setOpen === "function" ? "pointer" : "",
        }}
        onClick={() => {
          if (typeof props.setOpen === "function") {
            props.setOpen(true);
          }
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 60, height: 60, marginLeft: 2 }}
          image={`${process.env.REACT_APP_API_DOMAIN}/images/${props.imageName}.png`}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="subtitle2">
              {props.title}
            </Typography>
            <Typography variant="h4" color="text.secondary" component="div">
              {props.count}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
};

export default ActivityCard;
