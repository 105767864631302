import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { Link } from "react-router-dom";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const decodeTimeLine = (timeline: any) => {
  switch (timeline) {
    case "30 days":
      return 30;
    case "60 days":
      return 60;
    case "90 days":
      return 90;
    default:
      return "On going";
  }
};

export const DaysLeft = (props: any) => {
  const actionDate = moment(props["action"]["startDate"]).add(
    decodeTimeLine(props["action"].timeLine),
    "days"
  );
  props.action["daysLeft"] =
    typeof decodeTimeLine(props.action.timeLine) === "number"
      ? moment(props.action["startDate"])
          .add(decodeTimeLine(props.action.timeLine), "days")
          .diff(moment(), "days")
      : 9999;

  const displayDaysLeft =
    props["action"]["daysLeft"] !== 9999
      ? (props["action"]["daysLeft"] >= 0
          ? props["action"]["daysLeft"]
          : Math.abs(props["action"]["daysLeft"])) +
        (props["action"]["daysLeft"] > 1
          ? " days left"
          : props["action"]["daysLeft"] === 1
          ? " day left"
          : " days late")
      : "On going";

  return (
    <Typography sx={{ textAlign: "center" }}>{displayDaysLeft} </Typography>
  );
};

const B2BTaskCard = (props: any) => {
  const firm = props.firm;
  const [open, setOpen] = React.useState(false);

  return props.action ? (
    <Table sx={{ backgroundColor: "white" }}>
      <TableBody>
        <TableRow onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
          <TableCell sx={{ padding: "6px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ padding: "0" }}>
            <Stack>
              <Box sx={{ color: "#b3197a", fontSize: "16px" }}>
                {firm ? firm["Company Name"] : ""}
              </Box>
              <Box sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                B2B - {props.action["businessObjective"]}{" "}
              </Box>
              <Box sx={{ fontWeight: "bold" }}>
                {firm ? firm["Contact"] : ""}
              </Box>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: "150px", paddingLeft: 0 }}>
            <Stack direction={"row"}>
              <AccessAlarmIcon
                sx={{ fontSize: "24px", margin: "auto" }}
                color={
                  props.action.daysLeft < 0
                    ? "error"
                    : props.action.daysLeft < 7
                    ? "warning"
                    : "success"
                }
              ></AccessAlarmIcon>
              <DaysLeft action={props.action}></DaysLeft>
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Link to={`/case?id=${props.action.case_id}`}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ textDecoration: "underline", color: "#9E1F63FF" }}
                  >
                    {firm ? firm["Company Name"] : ""}
                  </Typography>
                </Link>

                <Typography variant="subtitle2">
                  {props.action["note"]}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  ) : (
    <></>
  );
};

export default B2BTaskCard;
