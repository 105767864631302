import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: "#E4E4E4",
    padding: 10,
    fontSize: 12,
    fontWeight: "bold",
  },
  tableCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 10,
  },

  noBorder: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  noBottomBorder: {
    borderBottomWidth: 0,
  },
  noTopBorder: {
    borderTopWidth: 0,
  },
});

const ReportTable = (props: any) => {
  return (
    <View style={styles.table}>
      {/* Table Header */}
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text></Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text></Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text></Text>
        </View>
      </View>
      {/* Table Rows */}
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]}>
          <Text>One-on-One Support</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>Consulting Sessions</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.consultingSessions}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol} />
        <View style={styles.tableCol}>
          <Text>Companies Served</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.companiesServed}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]}>
          <Text>Trainings/Workshops</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>Training Sessions</Text>
        </View>
        <View style={styles.tableCol}>
          <Text></Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Companies Trained</Text>
        </View>
        <View style={styles.tableCol}>
          <Text></Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol} />
        <View style={styles.tableCol}>
          <Text>Trainings by Type</Text>
        </View>
        <View style={styles.tableCol}>
          <Text></Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]}>
          <Text>Referrals</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>Referrals to Resource Partners</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.resourceReferrals}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Referrals for 15-minute Introductory B2B Consultation</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.b2b15min}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Referrals for B2B Consultation</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.b2bProviderReferrals}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol} />
        <View style={styles.tableCol}>
          <Text>Completed B2B Referrals</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.b2bReferralsCompletedCount}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]}>
          <Text>Referral Types</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>Legal</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes.Legal}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Acct.</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes.Accounting}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Business Consultant</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes["Business Consultant"]}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Mktg</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes.Marketing}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>HR</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes.HR}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol]} />
        <View style={styles.tableCol}>
          <Text>IT</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.referralTypes.IT}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]}>
          <Text>Company Demographics</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>Minority-Owned</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.minorityOwned}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.noBottomBorder]} />
        <View style={styles.tableCol}>
          <Text>Woman-Owned</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.womenOwned}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol} />
        <View style={styles.tableCol}>
          <Text>Veteran-Owned</Text>
        </View>
        <View style={styles.tableCol}>
          <Text>{props.data.veteranOwned}</Text>
        </View>
      </View>
    </View>
  );
};

export default ReportTable;
