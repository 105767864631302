import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledButton } from "../../common/Buttons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { EventType } from "../../../interfaces";
const EventCard = (props: any) => {
  const event: EventType = props.event;
  const [attendeeOpen, setattendeeOpen] = useState(false);
  const findSurveyResult = (email: string) => {
    if (!props.surveyResults) {
      return null;
    }
    const theSurvey = props.surveyResults.find((survey_result: any) => {
      return (
        survey_result.survey_result.email.toLocaleLowerCase() ===
        email.toLocaleLowerCase()
      );
    });
    if (!theSurvey) {
      return null;
    }
    return theSurvey._id;
  };

  const exportEventSurvey = () => {
    if (props.surveyResults) {
      let csvContent: any[] = [];
      let csvHeading = [
        "First Name",
        "Last Name",
        "Email",
        "What event did you attend?",
        "How likely are you to recommend this event to a friend or colleague?",
        "Overall how satisfied are you with the event?",
        "How likely are you to join Oakland Thrive for another event?",
        "Quality of venue?",
        "What other training topics would you like to see offered by Oakland Thrive/Business Forward?",
        "Any other comments or suggestions?",
        "How did you hear about this event/Oakland Thrive?",
        "Other",
        "Can we use your comments, name and company name on Oakland Thrive promotional materials in the future?",
      ];
      csvContent.push(csvHeading);

      for (const surveyItem of props.surveyResults) {
        let csvAnswer = [];
        csvAnswer.push(
          surveyItem.survey_result["first-name"]
            ? surveyItem.survey_result["first-name"]
            : ""
        );
        csvAnswer.push(
          surveyItem.survey_result["last-name"]
            ? surveyItem.survey_result["last-name"]
            : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.email ? surveyItem.survey_result.email : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q1 ? surveyItem.survey_result.q1 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q2 ? surveyItem.survey_result.q2 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q3 ? surveyItem.survey_result.q3 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q4 ? surveyItem.survey_result.q4 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q5 ? surveyItem.survey_result.q5 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q6 ? surveyItem.survey_result.q6 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q7 ? surveyItem.survey_result.q7 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q8 ? surveyItem.survey_result.q8 : ""
        );
        csvAnswer.push(
          surveyItem.survey_result["q8-Comment"]
            ? surveyItem.survey_result["q8-Comment"]
            : ""
        );
        csvAnswer.push(
          surveyItem.survey_result.q9 ? surveyItem.survey_result.q9 : ""
        );
        csvContent.push(csvAnswer);
      }

      let csvString = ``;
      for (const row of csvContent) {
        let rowString = `\n`;
        for (const item of row) {
          rowString = rowString + `"${item}",`;
        }
        csvString = csvString + rowString;
      }

      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Create a hidden anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = `${event.name.text} survey.csv`;
      document.body.appendChild(a);

      // Trigger the download
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };
  return (
    <Paper sx={{ width: "100%", marginBottom: "20px" }}>
      <Stack direction={"row"}>
        <Stack sx={{ width: "30%", padding: "10px" }} spacing={1}>
          <img
            src={event.logo ? event.logo.url : ""}
            alt="Imgae not available"
          ></img>
          {props.displayButton ? (
            <>
              <StyledButton
                variant="contained"
                onClick={() => {
                  props.setInvitationOpen(true);
                  props.setSelectedEvent(event);
                }}
              >
                Send Invitation
              </StyledButton>
              <a target="_blank" href={event.url} style={{ width: "100%" }}>
                <StyledButton sx={{ width: "100%" }} variant="contained">
                  View Event
                </StyledButton>
              </a>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <Stack sx={{ width: "70%" }} spacing={1}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {event.name.text}
          </Typography>
          <Stack direction={"row"} sx={{ textAlign: "left", color: "gray" }}>
            <LocationOnIcon style={{ color: "gray", fontSize: "20px" }} />
            <Typography variant="subtitle2">
              {event.locationInfo.localized_address_display}
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ textAlign: "left", color: "gray" }}>
            <CalendarMonthIcon style={{ color: "gray", fontSize: "20px" }} />
            <Typography variant="subtitle2">
              {dayjs(event.start.local).format("YYYY-MM-DD h:mm A") +
                " - " +
                dayjs(event.end.local).format("h:mm A")}
            </Typography>
          </Stack>
          <Typography sx={{ textAlign: "left" }}>
            {event.description.text}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {/* <Box sx={{ textAlign: "left" }}>
            <Stack direction={"row"}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setattendeeOpen(!attendeeOpen)}
              >
                {attendeeOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                <Typography variant="h6">Attendees</Typography>
              </IconButton>
            </Stack>
          </Box> */}
          {event.attendeeInfo && (
            <Collapse in={attendeeOpen} timeout="auto" unmountOnExit>
              <Box sx={{ textAlign: "right", marginRight: "10px" }}>
                <StyledButton
                  onClick={exportEventSurvey}
                  size="small"
                  variant="contained"
                >
                  Export Survey Results
                </StyledButton>
              </Box>

              <Box sx={{ margin: 1 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Survey Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {event.attendeeInfo.map((row: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.profile.name}
                          </TableCell>
                          <TableCell align="right">
                            {row.profile.email}
                          </TableCell>
                          <TableCell align="right">
                            {findSurveyResult(row.profile.email) ? (
                              <>
                                <Button
                                  onClick={() => {
                                    props.setSurveyResultOpen(true);
                                    props.setOpenSurvey(
                                      findSurveyResult(row.profile.email)
                                    );
                                  }}
                                >
                                  View Result
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default EventCard;
