const zipcodes: string[] = [
  "10022",
  "29142",
  "32779",
  "33050",
  "40884",
  "42357",
  "46328",
  "47073",
  "47375",
  "48009",
  "48012",
  "48015",
  "48017",
  "48021",
  "48024",
  "48025",
  "48026",
  "48027",
  "48030",
  "48033",
  "48034",
  "48036",
  "48037",
  "48038",
  "48042",
  "48043",
  "48044",
  "48047",
  "48066",
  "48067",
  "48068",
  "48069",
  "48070",
  "48071",
  "48072",
  "48073",
  "48074",
  "48075",
  "48076",
  "48080",
  "48081",
  "48083",
  "48084",
  "48085",
  "48087",
  "48088",
  "48089",
  "48091",
  "48092",
  "48093",
  "48094",
  "48098",
  "48099",
  "48106",
  "48107",
  "48126",
  "48150",
  "48152",
  "48154",
  "48165",
  "48167",
  "48168",
  "48170",
  "48174",
  "48176",
  "48178",
  "48185",
  "48186",
  "48188",
  "48201",
  "48203",
  "48204",
  "48205",
  "48207",
  "48208",
  "48215",
  "48219",
  "48220",
  "48221",
  "48224",
  "48225",
  "48226",
  "48234",
  "48235",
  "48236",
  "48237",
  "48238",
  "48239",
  "48240",
  "48243",
  "48272",
  "48283",
  "483/5",
  "48301",
  "48302",
  "48303",
  "48304",
  "48306",
  "48307",
  "48308",
  "48309",
  "48310",
  "48311",
  "48313",
  "48314",
  "48315",
  "48316",
  "48320",
  "48322",
  "48323",
  "48324",
  "48325",
  "48326",
  "48327",
  "48328",
  "48329",
  "48330",
  "48331",
  "48332",
  "48333",
  "48334",
  "48335",
  "48336",
  "48340",
  "48341",
  "48342",
  "48343",
  "48344",
  "48346",
  "48347",
  "48348",
  "48350",
  "48356",
  "48357",
  "48359",
  "48360",
  "48361",
  "48362",
  "48363",
  "48366",
  "48367",
  "48370",
  "48371",
  "48374",
  "48375",
  "48376",
  "48377",
  "48380",
  "48381",
  "48382",
  "48383",
  "48386",
  "48389",
  "48390",
  "48392",
  "48393",
  "48430",
  "48438",
  "48442",
  "48455",
  "48462",
  "48532",
  "48557",
  "48632",
  "48705",
  "48706",
  "48731",
  "48843",
  "48855",
  "48895",
  "48913",
  "48930",
  "49009",
  "49070",
  "49072",
  "49073",
  "49220",
  "49329",
  "49338",
  "49390",
  "49393",
  "49426",
  "90013",
  "93720",
  "94603",
  "94621",
];

export default zipcodes;
