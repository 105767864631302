import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import clsx from "clsx";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridRow, GridRowParams } from "@mui/x-data-grid";
import { Box, Button, Stack, TextField } from "@mui/material";

const ExploreArchiveFirm = () => {
  const [hisFirm, setHisFirm] = useState<any>([]);
  const [displayedFirms, setDisplayedFirms] = useState<any>([]);
  const [selectedRoww, setSelectedRoww] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/firms/history`, axiosConfig)
      .then((res) => {
        // console.log(res.data.firms);
        setHisFirm(res.data.firms);
        setDisplayedFirms(res.data.firms);
      });
  }, []);

  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "title",
      headerName: "Company Name",
      headerAlign: "center",
      minWidth: 200,
      renderCell: (cellValues) => {
        return (
          <div className="flex flex-col items-start">
            <div style={{ whiteSpace: "pre-wrap" }}>
              <p
                style={{
                  color: "#9E1F63FF",
                  fontSize: "22px",
                  textAlign: "left",
                }}
              >
                {cellValues.row.title}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "contact",
      headerName: "Contact",
      headerAlign: "center",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (
          <>
            <p style={{ fontSize: "18px" }}>{cellValues.row.contact}</p>
          </>
        );
      },
    },
    {
      flex: 1,
      field: "city",
      headerName: "City",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <>
            <p style={{ paddingLeft: "20px", fontSize: "18px" }}>
              {cellValues.row.city}
            </p>
          </>
        );
      },
    },
  ];

  let rows: any[] = [];
  let counter: number = 0;
  for (let firm of displayedFirms) {
    let descriptionText: string = "";
    if (firm.hasOwnProperty("capabilityNarrative")) {
      descriptionText = descriptionText + firm["capabilityNarrative"] + " ";
    }
    if (firm.hasOwnProperty("salesforceDescription")) {
      if (
        firm["salesforceDescription"] != null &&
        firm["salesforceDescription"].hasOwnProperty("descriptionText")
      ) {
        descriptionText =
          descriptionText + firm["salesforceDescription"]["descriptionText"];
      }
    }
    rows.push({
      id: counter,
      title: firm["Company Name"],
      contact: firm["Contact"],
      city: firm["City"],
      firm_id: firm["_id"],
    });
    counter += 1;
  }

  const handleSearchSubmit = () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/firms/his_search`,
        {
          term: search,
        },
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
        setDisplayedFirms(res.data.firms);
      });
  };

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          width: "50%",
          marginBottom: "20px",
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          label="Search Company..."
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Button
          style={{ backgroundColor: "#9e1f63", color: "white" }}
          variant="contained"
          onClick={handleSearchSubmit}
        >
          Search
        </Button>
        <Button
          style={{ backgroundColor: "#9e1f63", color: "white" }}
          variant="contained"
          onClick={() => {
            setSearch("");
            setDisplayedFirms(hisFirm);
          }}
        >
          Reset
        </Button>
      </Stack>
      <Box
        style={{
          height: 800,
        }}
        sx={{
          height: 400,
          width: 1,
          "& .odd": {
            bgcolor: "#e8e8e8",
          },
          "& .even": {
            bgcolor: "#f8f7f7",
          },
          ".MuiDataGrid-columnHeaders": {
            fontSize: "22px",
            bgcolor: "#0F95B3",
            color: "white",
            textAlign: "left",
          },
          ".MuiSvgIcon-root": {},
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          rows={rows}
          columns={columns}
          rowHeight={100}
          disableRowSelectionOnClick
          components={{ Row: CustomRow }}
          onRowClick={(row: any) => {
            window.open(
              `/dashboard/archivedcases/${row["row"]["firm_id"]}`,
              "_blank"
            );
          }}
          rowSelectionModel={selectedRoww}
          onRowSelectionModelChange={(row: any) => {
            setSelectedRoww(row);
          }}
        />
      </Box>
    </>
  );
};

export default ExploreArchiveFirm;
