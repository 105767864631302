import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Theme, useTheme } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";
import dayjs, { Dayjs } from "dayjs";
import { StyledTextarea } from "../common/Textareas";
import ThriveTitle from "../common/ThriveTitle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//List of interaction types
export const interaction_types = [
  "No Response",
  "Attempted Contact",
  "Community Interaction",
  "Follow-up",
  "Scheduled Visit",
  "Web Intake",
  "Childcare Business Assessment",
];

export const assistances = [
  "No Response",
  "Accounting",
  "Advertising",
  "Branding",
  "Business Management",
  "Business Planning Assistance",
  "Buy Business",
  "Certification",
  "Customer Relations",
  "eCommerce",
  "Entity Formation",
  "Equity Investment",
  "Fiscal Management",
  "Franchising",
  "Government Contracting",
  "Grants",
  "Human Resources",
  "IT Cybersecurity",
  "Insurance/Benefits",
  "Intellectual Property",
  "International Trade",
  "Legal Issues",
  "Loans",
  "Market Research",
  "Marketing",
  "Marketing Plan Assistance",
  "Mentoring",
  "Merchandising/Product Distribution",
  "Non-profit Issues",
  "Patent/Copyright Assistance",
  "Procurement",
  "Product Development",
  "Property Development",
  "Regulatory Compliance",
  "Sales",
  "Scaling",
  "Sell Business",
  "Site Location Assistance",
  "Social Media",
  "Staffing",
  "Startup Assistance",
  "Strategic Planning Assistance",
  "Sucession Planning",
  "Sustainability",
  "Tax Planning",
  "Technology Training",
  "Web Site Development",
];

function getStyles(
  name: string,
  assistance_requested: readonly string[],
  theme: Theme
) {
  if (assistance_requested) {
    return {
      fontWeight:
        assistance_requested.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  } else {
    return {
      fontWeight: theme.typography.fontWeightRegular,
    };
  }
}

interface MyWrapperProps {
  children: ReactNode;
  title: string;
}

const GroupWrapper: React.FC<MyWrapperProps> = ({ children, title }) => {
  return (
    <Box
      sx={{
        border: "1px solid #d7d7d7",
        borderRadius: "10px",
      }}
      className="row-span-1 rounded-lg shadow-md inset-0"
    >
      <Box
        sx={{
          backgroundColor: "#b6d8ff",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <Typography variant="subtitle2" sx={{ marginLeft: "5px" }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ padding: "10px" }}>{children}</Box>
    </Box>
  );
};

const Interaction = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const theme = useTheme();
  const [interactionForm, setInteractionForm] = React.useState({
    interaction_id: "",
    interaction_date: dayjs(),
    interaction_type: "",
    interaction_counselor: `${props.user.firstName} ${props.user.lastName}`,
    interaction_contact: "",
    assistance_requested: [] as string[],
    contact_time: 0 as number,
    prep_time: 0,
    travel_time: 0,
    interaction_note: "",
  });

  useEffect(() => {
    if (props.activity) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user?id=${props.activity.user_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setInteractionForm({
              interaction_id: props.activity._id,
              interaction_date: dayjs(props.activity.interaction_date) as Dayjs,
              interaction_type: props.activity.activity_type,
              interaction_counselor: `${res.data.user.firstName} ${res.data.user.lastName}`,
              interaction_contact: "",
              assistance_requested: props.activity
                .assistance_requested as string[],
              contact_time: props.activity.activity_contact_time as number,
              prep_time: props.activity.activity_prep_time,
              travel_time: props.activity.activity_travel_time,
              interaction_note: props.activity.activity_note,
            });
          } else {
            console.log("API Error");
          }
        });
    }
  }, [props.activity]);
  const handleChange = (
    event: SelectChangeEvent<typeof interactionForm.assistance_requested>
  ) => {
    const {
      target: { value },
    } = event;
    setInteractionForm({
      ...interactionForm,
      assistance_requested:
        typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleDelete = () => {
    if (interactionForm.interaction_id) {
      axios
        .delete(
          `${process.env.REACT_APP_API_DOMAIN}/activity?id=${interactionForm.interaction_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            props.setCardOpen(false);
            props.setDataFlag(!props.dataFlag);
          } else {
            console.log("API Error");
          }
        });
    }
  };

  const handleSumbit = () => {
    const postData = {
      _id: interactionForm.interaction_id,
      interaction_date: interactionForm.interaction_date
        ? interactionForm.interaction_date
        : new Date(),
      activity_type: interactionForm.interaction_type
        ? interactionForm.interaction_type
        : "No Response",
      activity_note: interactionForm.interaction_note,
      activity_contact_time: interactionForm.contact_time,
      activity_prep_time: interactionForm.prep_time,
      activity_travel_time: interactionForm.travel_time,
      assistance_requested: interactionForm.assistance_requested,
      firm_id: props.firm._id,
      case_id: props.case._id,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/activity`,
        postData,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          props.setCardOpen(false);
          props.setDataFlag(!props.dataFlag);
        } else {
          console.log("API Error");
        }
      });
  };
  return (
    <>
      <ThriveTitle
        title={"Interaction"}
        setOpen={props.setCardOpen}
      ></ThriveTitle>

      <Box
        sx={{
          padding: "5px",
          overflowY: "auto",
          height: { xs: 570, md: 570 },
        }}
      >
        <Stack direction={"column"} spacing={{ xs: 2, md: 3 }}>
          <GroupWrapper title="Contact Info">
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ md: 3, xs: 1 }}
              sx={{ alignItems: "center" }}
            >
              <Box sx={{ width: { xs: "100%", md: "25%" } }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Interaction Date"
                      value={interactionForm.interaction_date}
                      onChange={(value) => {
                        setInteractionForm({
                          ...interactionForm,
                          interaction_date: value as Dayjs,
                        });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              <Box sx={{ width: { xs: "100%", md: "25%" }, paddingTop: "8px" }}>
                <FormControl fullWidth>
                  <InputLabel>Interaction Type</InputLabel>
                  <Select
                    label="Interaction Type"
                    value={interactionForm.interaction_type}
                    onChange={(e) => {
                      setInteractionForm({
                        ...interactionForm,
                        interaction_type: e.target.value,
                      });
                    }}
                  >
                    {interaction_types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "25%" }, paddingTop: "8px" }}>
                <FormControl fullWidth>
                  <InputLabel>Counselor</InputLabel>
                  <Select
                    label="Counselor"
                    value={interactionForm.interaction_counselor}
                  >
                    <MenuItem value={interactionForm.interaction_counselor}>
                      {interactionForm.interaction_counselor}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "25%" }, paddingTop: "8px" }}>
                <FormControl fullWidth>
                  <InputLabel>Contact Person</InputLabel>
                  <Select label="Contact Person" value={props.firm.Contact}>
                    <MenuItem value={props.firm.Contact}>
                      {props.firm.Contact}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </GroupWrapper>

          <GroupWrapper title="Assistance">
            <Stack>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">
                    Assistance Requested
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={interactionForm.assistance_requested}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Assistance Requested"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {assistances.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(
                          name,
                          interactionForm.assistance_requested,
                          theme
                        )}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </GroupWrapper>

          <GroupWrapper title="Time">
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ md: 3, xs: 1 }}
              sx={{ alignItems: "center" }}
            >
              <Box sx={{ width: { xs: "100%", md: "25%" } }}>
                <FormControl fullWidth>
                  <InputLabel>Contact Time</InputLabel>
                  <Select
                    label="Contact Time"
                    value={interactionForm.contact_time}
                    onChange={(e) => {
                      setInteractionForm({
                        ...interactionForm,
                        contact_time: parseInt(e.target.value as string),
                      });
                    }}
                  >
                    <MenuItem value={0}>No Response</MenuItem>
                    <MenuItem value={5}>5 mins</MenuItem>
                    <MenuItem value={10}>10 mins</MenuItem>
                    <MenuItem value={15}>15 mins</MenuItem>
                    <MenuItem value={30}>30 mins</MenuItem>
                    <MenuItem value={60}>1 hour</MenuItem>
                    <MenuItem value={75}>1 hour and 15 mins</MenuItem>
                    <MenuItem value={90}>1 hour and 30 mins</MenuItem>
                    <MenuItem value={105}>1 hour and 45 mins</MenuItem>
                    <MenuItem value={120}>2 hours</MenuItem>
                    <MenuItem value={135}>2 hours and 15 mins</MenuItem>
                    <MenuItem value={150}>2 hours and 30 mins</MenuItem>
                    <MenuItem value={165}>2 hours and 45 mins</MenuItem>
                    <MenuItem value={180}>3 hours</MenuItem>
                    <MenuItem value={195}>3 hours and 15 mins</MenuItem>
                    <MenuItem value={210}>3 hours and 30 mins</MenuItem>
                    <MenuItem value={225}>3 hours and 45 mins</MenuItem>
                    <MenuItem value={240}>4 hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "25%" } }}>
                <FormControl fullWidth>
                  <InputLabel>Prep Time</InputLabel>
                  <Select
                    label="Prep Time"
                    value={interactionForm.prep_time}
                    onChange={(e) => {
                      setInteractionForm({
                        ...interactionForm,
                        prep_time: parseInt(e.target.value as string),
                      });
                    }}
                  >
                    <MenuItem value={0}>No Response</MenuItem>
                    <MenuItem value={5}>5 mins</MenuItem>
                    <MenuItem value={10}>10 mins</MenuItem>
                    <MenuItem value={15}>15 mins</MenuItem>
                    <MenuItem value={30}>30 mins</MenuItem>
                    <MenuItem value={60}>1 hour</MenuItem>
                    <MenuItem value={75}>1 hour and 15 mins</MenuItem>
                    <MenuItem value={90}>1 hour and 30 mins</MenuItem>
                    <MenuItem value={105}>1 hour and 45 mins</MenuItem>
                    <MenuItem value={120}>2 hours</MenuItem>
                    <MenuItem value={135}>2 hours and 15 mins</MenuItem>
                    <MenuItem value={150}>2 hours and 30 mins</MenuItem>
                    <MenuItem value={165}>2 hours and 45 mins</MenuItem>
                    <MenuItem value={180}>3 hours</MenuItem>
                    <MenuItem value={195}>3 hours and 15 mins</MenuItem>
                    <MenuItem value={210}>3 hours and 30 mins</MenuItem>
                    <MenuItem value={225}>3 hours and 45 mins</MenuItem>
                    <MenuItem value={240}>4 hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "25%" } }}>
                <FormControl fullWidth>
                  <InputLabel>Travel Time</InputLabel>
                  <Select
                    label="Travel Time"
                    value={interactionForm.travel_time}
                    onChange={(e) => {
                      setInteractionForm({
                        ...interactionForm,
                        travel_time: parseInt(e.target.value as string),
                      });
                    }}
                  >
                    <MenuItem value={0}>No Response</MenuItem>
                    <MenuItem value={5}>5 mins</MenuItem>
                    <MenuItem value={10}>10 mins</MenuItem>
                    <MenuItem value={15}>15 mins</MenuItem>
                    <MenuItem value={30}>30 mins</MenuItem>
                    <MenuItem value={60}>1 hour</MenuItem>
                    <MenuItem value={75}>1 hour and 15 mins</MenuItem>
                    <MenuItem value={90}>1 hour and 30 mins</MenuItem>
                    <MenuItem value={105}>1 hour and 45 mins</MenuItem>
                    <MenuItem value={120}>2 hours</MenuItem>
                    <MenuItem value={135}>2 hours and 15 mins</MenuItem>
                    <MenuItem value={150}>2 hours and 30 mins</MenuItem>
                    <MenuItem value={165}>2 hours and 45 mins</MenuItem>
                    <MenuItem value={180}>3 hours</MenuItem>
                    <MenuItem value={195}>3 hours and 15 mins</MenuItem>
                    <MenuItem value={210}>3 hours and 30 mins</MenuItem>
                    <MenuItem value={225}>3 hours and 45 mins</MenuItem>
                    <MenuItem value={240}>4 hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </GroupWrapper>

          <GroupWrapper title="Contact Notes">
            <StyledTextarea
              sx={{ width: "100%" }}
              minRows={12}
              // maxRows={10}
              placeholder="Interaction Notes"
              value={interactionForm.interaction_note}
              onChange={(e) => {
                setInteractionForm({
                  ...interactionForm,
                  interaction_note: e.target.value,
                });
              }}
            />
          </GroupWrapper>

          <Stack
            sx={{
              bottom: 20,
              width: "100%",
              textAlign: "center",
            }}
            direction={"row"}
            justifyContent={
              interactionForm.interaction_id ? "space-between" : "center"
            }
          >
            <Button
              variant="contained"
              sx={{
                width: "200px",
                fontSize: "20px",
                backgroundColor: "#9E1F63FF",
              }}
              onClick={handleSumbit}
            >
              Save
            </Button>
            {interactionForm.interaction_id && (
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  fontSize: "20px",
                  backgroundColor: "#9E1F63FF",
                }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Interaction;
