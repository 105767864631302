import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import ActivityDetailWithoutLink from "../Dashboard/ActivityDetailWithoutLink";
import ThriveTitle from "./ThriveTitle";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StyledButton } from "./Buttons";
import ActivityDetail from "../Dashboard/ActivityDetail";
import axios from "axios";
import Cookies from "js-cookie";

interface Props {
  title: string;
  cases: any[];
  displayCases: any[];
  setDisplayCases: any;
  caseActivities: any[];
  checkedCases: number[];
  setCheckedCases: any;
  selectedCase: any;
  setSelectedCase: any;
  enableControl: boolean;
  enableSelection: boolean;
  enableMultiSelection: boolean;
  status_default: string;
  sortby_default: string;
  setOpen: any;
  handleNext: any;
}

const ThriveCaseSelection: React.FC<Props> = ({
  title,
  cases,
  displayCases,
  setDisplayCases,
  caseActivities,
  checkedCases,
  setCheckedCases,
  selectedCase,
  setSelectedCase,
  enableControl,
  enableSelection,
  enableMultiSelection,
  status_default,
  sortby_default,
  setOpen,
  handleNext,
}: Props) => {
  const statusMap: { [key: string]: string } = {
    open: "Open",
    completed: "Closed",
  };
  const [firms, setFirms] = React.useState<any>([]);
  const [sortby, setSortby] = React.useState<string>(sortby_default);
  const [status, setStatus] = React.useState<string>(status_default);
  const [search, setSearch] = React.useState<string>("");
  const fullyChecked = Array.from(Array(displayCases.length).keys());

  React.useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    if (cases.length === 0) {
      return;
    } else {
      const firmdIds = cases.map((openCase: any) => {
        if (openCase) {
          return openCase.firm;
        } else {
          return "";
        }
      });
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/firms/massive_get`,
          { ids: firmdIds.join(",") },
          axiosConfig
        )
        .then((res) => {
          setFirms(res.data.firms);
          setDisplayCases(handleControlCases(cases, status, sortby, search));
        });
    }
  }, [cases]);

  React.useEffect(() => {
    setDisplayCases(handleControlCases(cases, status, sortby, search));
  }, [search]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedCases.indexOf(value);
    const newChecked = [...checkedCases];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedCases(newChecked);
  };

  const handleToggleAll = () => {
    if (checkedCases.length < displayCases.length) {
      setCheckedCases(fullyChecked);
    } else {
      setCheckedCases([]);
    }
  };

  const handleSortChange = (currentListing: any, sortby: any) => {
    if (caseActivities.length === 0) return currentListing;
    let sortedCaseIds = [];
    let newListingCases: any[] = [];
    if (sortby === "recent") {
      for (let activity of caseActivities) {
        if (sortedCaseIds.indexOf(activity.case_id) === -1) {
          sortedCaseIds.push(activity.case_id);
        }
      }

      newListingCases = sortedCaseIds.map((caseId: any) => {
        return currentListing.find((caseItem: any) => {
          return caseItem._id === caseId;
        });
      });
    } else if (sortby === "name") {
      let temp_firms = [...firms];
      temp_firms.sort((a: any, b: any) => {
        const nameA = a["Company Name"].toUpperCase(); // ignore upper and lowercase
        const nameB = b["Company Name"].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      newListingCases = temp_firms.map((firm: any) => {
        return currentListing.find((caseItem: any) => {
          return caseItem._id === firm.openCase;
        });
      });
    }

    return newListingCases;
  };

  const handleControlCases = (
    cases: any,
    status: string,
    sortby: string,
    search: string
  ) => {
    let tmp_cases;
    // Handle status
    if (status === "either") {
      tmp_cases = cases;
    } else {
      tmp_cases = cases.filter((caseItem: any) => {
        return caseItem.status === status;
      });
    }

    // Handle sorting
    tmp_cases = handleSortChange(tmp_cases, sortby);

    // Remove undefined elements
    tmp_cases = tmp_cases.filter(function (element: any) {
      return element !== undefined;
    });

    // Handle search
    if (search !== "") {
      const search_firms = firms.filter((firm: any) => {
        return firm["Company Name"]
          ? firm["Company Name"].toLowerCase().includes(search.toLowerCase())
          : false;
      });

      tmp_cases = tmp_cases.filter((caseItem: any) => {
        return search_firms.find((firm: any) => {
          return firm._id === caseItem.firm;
        });
      });
    }

    return tmp_cases ? tmp_cases : [];
  };

  return firms.length > 0 ? (
    <>
      {title ? (
        <ThriveTitle title={title} setOpen={setOpen}></ThriveTitle>
      ) : (
        <></>
      )}

      <List
        dense
        sx={{
          width: "100%",
          margin: "auto",
          bgcolor: "white",
          overflow: "auto",
          height: "85%",
        }}
      >
        {enableControl ? (
          <ListItem>
            <Stack direction={"row"} spacing={1}>
              <FormControl sx={{ width: "100px" }}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  value={status}
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setDisplayCases(
                      handleControlCases(cases, e.target.value, sortby, search)
                    );
                  }}
                >
                  <MenuItem value={"either"}>Either</MenuItem>
                  <MenuItem value={"open"}>Open</MenuItem>
                  <MenuItem value={"completed"}>Closed</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100px" }}>
                <InputLabel id="sort-label">Sort By</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sortby}
                  label="Sort By"
                  onChange={(e: any) => {
                    setSortby(e.target.value);
                    setDisplayCases(
                      handleControlCases(cases, status, e.target.value, search)
                    );
                  }}
                >
                  <MenuItem value={"recent"}>Recent</MenuItem>
                  <MenuItem value={"name"}>Name</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ width: "500px" }}
                label="Search Company..."
                variant="outlined"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Stack>
          </ListItem>
        ) : (
          <> </>
        )}

        <ListItem disablePadding>
          {enableSelection && enableMultiSelection ? (
            <>
              <Checkbox
                edge="end"
                onChange={handleToggleAll}
                indeterminate={
                  checkedCases.length > 0 &&
                  checkedCases.length < displayCases.length
                }
                checked={
                  JSON.stringify(checkedCases.sort()) ===
                  JSON.stringify(fullyChecked.sort())
                }
              />
              <ListItemButton>
                {JSON.stringify(checkedCases.sort()) ===
                JSON.stringify(fullyChecked.sort())
                  ? "DESELECT ALL"
                  : "SELECT ALL"}
              </ListItemButton>
            </>
          ) : (
            <></>
          )}
        </ListItem>
        {displayCases.length === 0 ? (
          <Paper sx={{ width: "100%", textAlign: "center", height: "80%" }}>
            <Typography>No Matching Case</Typography>
          </Paper>
        ) : (
          <>
            {displayCases.map((caseItem: any, value: number) => {
              const labelId = `checkbox-list-secondary-label-${caseItem._id}`;
              return (
                <ListItem key={value} disablePadding>
                  {enableSelection && enableMultiSelection ? (
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(value)}
                      checked={checkedCases.indexOf(value) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  ) : (
                    <></>
                  )}

                  <ListItemButton>
                    {enableSelection ? (
                      <ActivityDetailWithoutLink
                        key={labelId}
                        case={caseItem}
                        firm={firms.find((firm: any) => {
                          return firm._id === caseItem.firm;
                        })}
                        selectedCase={
                          enableMultiSelection ? checkedCases : selectedCase
                        }
                        setSelectedCase={
                          enableMultiSelection
                            ? setCheckedCases
                            : setSelectedCase
                        }
                        index={caseItem._id}
                        index2={value}
                        multiselect={enableMultiSelection}
                      />
                    ) : (
                      <ActivityDetail
                        key={labelId}
                        case={caseItem}
                        showStatus={statusMap[caseItem.status]}
                        firm={firms.find((firm: any) => {
                          return firm._id === caseItem.firm;
                        })}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </>
        )}
      </List>
      {enableSelection ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <StyledButton
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "20px",
              backgroundColor: "#9E1F63FF",
            }}
            onClick={handleNext}
            disabled={checkedCases.length === 0 && selectedCase._id === ""}
          >
            Next
          </StyledButton>
        </Box>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default ThriveCaseSelection;
