const FieldValueItem = (props: any) => {
  return (
    <div className="flex flex-row font-bold items-center justify-start">
      <div className="w-[170px] flex-shrink-0 px-1 py-2 mr-1 print:w-[100px] break-word">
        {props.field}
      </div>
      <div className="grow px-1 py-2 break-word print:break-all">
        {props.value}
      </div>
    </div>
  );
};

export default FieldValueItem;
