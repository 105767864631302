import { Card, CardContent, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";

const CaseCard = (props: any) => {
  const [firm, setFirm] = React.useState(props.firm);
  React.useEffect(() => {
    if (!props.firm["Company Name"]) {
      const token: string | undefined = Cookies.get("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/firms/id?id=${props.case.firm}`,
          axiosConfig
        )
        .then((res) => {
          setFirm(res.data.firm);
        });
    }
  }, []);
  return (
    <Link to={`/case?id=${props.case._id}`}>
      <Card sx={{ height: 140, width: 140, cursor: "pointer", margin: "5px" }}>
        <CardContent>
          <Typography variant="subtitle1" component="div">
            {firm["Company Name"]}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {firm["City"]}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default CaseCard;
