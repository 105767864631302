import CaseCard from "./CaseCard";

interface caseMatrix {
  [key: string]: any[];
}

const stages = [
  "Case Created",
  "Initial Contact",
  "Business Assessment",
  "Business Action Plan",
  "Referral",
  "Provider Referral Notice Email",
  "Case Closed",
];

const stagesDisplay = [
  "Case Created",
  "Initiate Contact",
  "Business Assessment",
  "Business Action Plan",
  "Resource Referral",
  "B2B Referral",
  "Closed Cases",
];

const findMostAdvancedStages = (localCaseActivities: any) => {
  let mostAdvancedStage = 0;
  for (let index in localCaseActivities) {
    if (
      stages.indexOf(localCaseActivities[index]["activity_type"]) >
      mostAdvancedStage
    ) {
      mostAdvancedStage = stages.indexOf(
        localCaseActivities[index]["activity_type"]
      );
    }
  }
  return stages[mostAdvancedStage];
};

function createArrayFromZeroToX(x: number) {
  return Array.from({ length: x }, (_, index) => index);
}

const caseManager = (openCases: any, caseActivities: any) => {
  let caseMatrix: caseMatrix = {};
  if (openCases && caseActivities) {
    stages.map((stage: any, index: number) => {
      caseMatrix[stage] = [];
      openCases.map((caseItem: any, index: number) => {
        let localCaseActivities = caseActivities.filter((activity: any) => {
          return activity.case_id === caseItem._id;
        });
        let currentCaseStage = findMostAdvancedStages(localCaseActivities);
        if (currentCaseStage === stage) {
          caseMatrix[stage].push(caseItem);
        }
        return null;
      });
      return null;
    });
  }
  return caseMatrix;
};

const IDEACoreCRM = (props: any) => {
  const handleSortChange = (currentListing: any, sortby: any) => {
    // Get the most recent 2 month of activities for cases to limit the number of cases to display
    // const recentActivities = props.caseActivities.filter((activity: any) => {
    //   return (
    //     new Date(activity.created_at).getTime() >
    //     new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).getTime()
    //   );
    // });

    let sortedCaseIds = [];
    if (sortby === "recent") {
      for (let activity of props.caseActivities) {
        if (sortedCaseIds.indexOf(activity.case_id) === -1) {
          sortedCaseIds.push(activity.case_id);
        }
      }
    }

    let newListingCases: any[] = sortedCaseIds.map((caseId: any) => {
      return currentListing.find((caseItem: any) => {
        return caseItem._id === caseId;
      });
    });

    // Remove undefined elements
    newListingCases = newListingCases.filter(function (element: any) {
      return element !== undefined;
    });

    return newListingCases;
  };

  const caseMatrix = caseManager(
    handleSortChange(props.openCases, "recent"),
    props.caseActivities
  );
  const rows = createArrayFromZeroToX(
    Math.max(
      ...stages.map((stage: any) => {
        return caseMatrix[stage].length;
      })
    )
  );
  // console.log(rows);
  // console.log(props.openCases);
  // console.log(props.caseActivities);
  // console.log(caseMatrix);
  return (
    <div
      className="grid grid-rows-[670px] bg-white rounded-lg shadow-md inset-0"
      style={{ overflowY: "auto" }}
    >
      <table style={{ overflow: "auto", backgroundColor: "#ededed69" }}>
        <thead
          style={{
            backgroundColor: "#bd358c",
            color: "white",
            position: "sticky",
            top: 0,
          }}
        >
          <tr>
            {stagesDisplay.map((stage: any, index: number) => {
              return (
                <th key={index} style={{ width: "150px" }}>
                  {stage}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((caseItem: any, row: any) => {
            return (
              <tr key={row} style={{ height: 140 }}>
                {stages.map((stage: any, index: number) => {
                  return (
                    <td key={index}>
                      {caseMatrix[stage] ? (
                        caseMatrix[stage][row] ? (
                          <CaseCard
                            case={caseMatrix[stage][row]}
                            firm={props.firms.find((firm: any) => {
                              return firm._id === caseMatrix[stage][row].firm;
                            })}
                          ></CaseCard>
                        ) : (
                          ""
                        )
                      ) : (
                        <></>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IDEACoreCRM;
