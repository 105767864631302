import { IoIosSettings } from "react-icons/io";
import { HiDocumentText } from "react-icons/hi";
import { MdPhoneInTalk } from "react-icons/md";
import { RiChatForwardFill } from "react-icons/ri";
import { FaWallet } from "react-icons/fa";
import NavBarItem from "./NavBar/NavBarItem";
// import { UserContext } from "../../context/UserContext";
// import { useContext } from "react";
import { BsLink45Deg } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";
import { FaFolderOpen } from "react-icons/fa";
const NavBar = () => {
  // const { user } = useContext(UserContext);
  return (
    <div className="p-2 flex flex-col">
      <NavBarItem
        newTab={true}
        toURL={"https://linktr.ee/OaklandThriveMI"}
        icon={<BsLink45Deg />}
        text={"Internal Links"}
      />
      <NavBarItem
        toURL={"/dashboard/"}
        icon={<HiDocumentText />}
        text={"Dashboard"}
      />
      <NavBarItem
        toURL={"/dashboard/firms"}
        icon={<HiDocumentText />}
        text={"New Firm Visit Plan"}
      />
      <NavBarItem
        toURL={"/dashboard/exploreFirm"}
        icon={<FaWallet />}
        text={"Explore Firms"}
      />
      <NavBarItem
        toURL={"/dashboard/providers"}
        icon={<FaWallet />}
        text={"B2B Providers"}
      />
      <NavBarItem
        toURL={"/dashboard/resources"}
        icon={<IoIosSettings />}
        text={"Resources"}
      />
      <NavBarItem
        toURL={"/dashboard/events"}
        icon={<IoIosSettings />}
        text={"Events"}
      />
      <NavBarItem
        toURL={"/dashboard/statistics"}
        icon={<MdPhoneInTalk />}
        text={"Program Statistics"}
      />

      <NavBarItem
        toURL={"/dashboard/glossary"}
        icon={<RiChatForwardFill />}
        text={"Glossary of Terms"}
      />

      <NavBarItem
        toURL={"/dashboard/reports"}
        icon={<TbReportSearch />}
        text={"Reports"}
      />

      <NavBarItem
        toURL={"/dashboard/archivedcases"}
        icon={<FaFolderOpen />}
        text={"Archived Cases"}
      />
    </div>
  );
};

export default NavBar;
