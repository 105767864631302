import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditFirm from "../FirmListing/EditFirm";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { StyledTextarea } from "../../common/Textareas";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "1px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border

  "td, th": {
    border: 0,
  },
}));

const availableGrant = [
  "[EDCA] Small Business Recovery Grant Application",
  "[EDCA] Small Business Recovery Grant Application - Round 2",
  "SBSL",
  "MSBSG",
  "MSBR Restart Grant",
  "MSBR",
  "EIDL Grant",
  "MEDC Grant",
  "PPP Grant",
  "Saving Business Saving Lives Grant",
  "Stabilization Grant",
];

const grantVsDefination: { [key: string]: string } = {
  "PPP Grant":
    "Paycheck Protection Program – SBA grant to keep people employed during COVID",
  "EIDL Grant": "Economic Injury Disaster Loan – SBA Loan during COVID",
  SBSL: "Saving Business Saving Lives",
  SBSG: "Small Business Stabilization Grant",
  "MEDC Grant": "Pure Michigan Small Business Relief Initiative from MEDC",
  MSBR: "Michigan Small Business Recovery Grant",
  PPE: "Personal Protection Equipment Resilience Grant",
  "MSBR Restart Grant":
    "Michigan Small Business Restart Grant from State of Michigan",
  MSBSG: "Michigan Small Business Survival Grant from State of Michigan",
  RRG: "Restaurant Relief Grant from Oakland County",
};

const ArchiveCase = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  let emptyGrantsObject: { [key: string]: {} } = {};
  availableGrant.map((grant: string) => {
    emptyGrantsObject[grant] = {
      applied: false,
      decision: "Rejected",
      value: "",
    };
  });
  const [firm, setFirm] = useState<any>({
    Address: "",
    "Company Name": "",
    primaryIndustry: "",
    secondaryIndustry: "",
    ZIP: "",
    City: "",
    State: "",
    Phone: "",
    "Company Email": "",
    Website: "",
  });
  const [contacts, setContacts] = useState<any[]>([]);
  const [editedGrant, setEditedGrant] = useState<any>(emptyGrantsObject);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/firms/history?id=${id}`,
        axiosConfig
      )
      .then((res) => {
        let rawFirm = res.data.firm;
        setFirm({
          Website: "",
          ...res.data.firm,
          primaryIndustry: "",
          secondaryIndustry: "",
        });
        setContacts([
          {
            Contact: res.data.firm["Contact"],
            Email: res.data.firm["Email"],
            isDefault: true,
          },
        ]);
      });
  }, [id]);

  const handleContactChange = (index: number, value: string) => {
    const updatedContacts = [...contacts];
    updatedContacts[index]["Contact"] = value;
    setContacts(updatedContacts);
  };

  const handleEmailChange = (index: number, value: string) => {
    const updatedContacts = [...contacts];
    updatedContacts[index]["Email"] = value;
    setContacts(updatedContacts);
  };

  const handleSetDefaultContact = (index: number) => {
    const updatedContacts = [...contacts];
    updatedContacts.forEach((contact) => {
      contact.isDefault = false;
    });
    updatedContacts[index].isDefault = true;
    setContacts(updatedContacts);
    // props.setFirm({...props.firm, Contact: updatedContacts[index].Contact, Email: updatedContacts[index].Email})
  };

  const deleteContact = (index: number) => {
    const updatedContacts = [...contacts];
    const isDefaultContact = updatedContacts[index].isDefault;

    const result = updatedContacts.splice(index, 1);
    // console.log(result);

    if (isDefaultContact && updatedContacts.length > 0) {
      updatedContacts[0].isDefault = true;
      // props.setFirm({...props.firm, Contact: updatedContacts[0].Contact, Email: updatedContacts[0].Email})
    }
    setContacts(updatedContacts);
  };

  const addNewContact = () => {
    setContacts([...contacts, { Contact: "", Email: "", isDefault: false }]);
  };

  const updateAndSubmit = (firm: any, editedGrant: any, contacts: any) => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    let defaultContact = contacts.find((contact: any) => contact.isDefault);
    const additionalContacts = contacts.filter(
      (contact: any) => !contact.isDefault
    );
    if (!defaultContact && additionalContacts.length > 0) {
      defaultContact = additionalContacts[0];
      additionalContacts.shift();
    }
    let postData = {
      editedFirm: {
        ...firm,
        grants: editedGrant,
        Contact: defaultContact?.Contact,
        Email: defaultContact?.Email,
        additionalContacts: additionalContacts,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/firms/his_to_case`,
        postData,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          navigate(`/case?id=${res.data.caseId}`);
        } else {
          console.log("API Error");
        }
      });
  };

  return (
    <>
      <Box>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{
            width: "100%",
            margin: "auto",
            marginBottom: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={12} md={8} style={{ position: "relative" }}>
            <Typography variant="h6" fontWeight={"bold"}>
              Contact Information
            </Typography>
            <hr style={{ marginBottom: "10px" }}></hr>
            <div key={contacts.length}>
              {contacts.map((contact, index) => (
                <div key={index} className="flex items-center">
                  <TextField
                    sx={{
                      width: { xs: "100%", md: "48%" },
                      marginLeft: "1%",
                      marginBottom: "2px",
                    }}
                    label="Contact"
                    variant="standard"
                    defaultValue={contact.Contact}
                    onChange={(e) => {
                      handleContactChange(index, e.target.value);
                    }}
                  />
                  <TextField
                    sx={{
                      width: { xs: "100%", md: "48%" },
                      marginLeft: "1%",
                      marginBottom: "2px",
                    }}
                    label="Email"
                    variant="standard"
                    defaultValue={contact.Email}
                    onChange={(e) => {
                      handleEmailChange(index, e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={contact.isDefault}
                        onChange={() => handleSetDefaultContact(index)}
                      />
                    }
                    label="Primary"
                  />
                  {contacts.length > 1 && (
                    <IconButton
                      aria-label="Delete"
                      onClick={() => deleteContact(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
            <Button onClick={addNewContact}>Add New Contact</Button>

            <Typography
              variant="h6"
              sx={{ marginTop: "20px" }}
              fontWeight={"bold"}
            >
              Company Information
            </Typography>
            <hr style={{ marginBottom: "10px" }}></hr>
            <TextField
              sx={{
                width: "100%",
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Company Name"
              variant="standard"
              value={firm["Company Name"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  "Company Name": e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "98%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Address 1"
              variant="standard"
              value={firm["Address"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  Address: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "98%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Address 2"
              variant="standard"
              defaultValue={firm["Address 2"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  "Address 2": e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "24%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Zip"
              variant="standard"
              value={firm["ZIP"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  ZIP: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "24%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="City"
              variant="standard"
              value={firm["City"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  City: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "24%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="State"
              variant="standard"
              value={firm["State"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  State: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "24%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="County"
              variant="standard"
              defaultValue={firm["County"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  County: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Phone"
              variant="standard"
              value={firm["Phone"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  Phone: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Company Email"
              variant="standard"
              value={firm["Company Email"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  "Company Email": e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Website"
              variant="standard"
              value={firm["Website"] ? firm["Website"] : ""}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  Website: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Employees"
              variant="standard"
              defaultValue={firm["Employees"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  Employees: e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Annual Revenue"
              variant="standard"
              defaultValue={firm["Annual Revenue"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  "Annual Revenue": e.target.value,
                });
              }}
            />
            <TextField
              sx={{
                width: { xs: "100%", md: "32%" },
                marginLeft: "1%",
                marginBottom: "2px",
              }}
              label="Founding Year"
              variant="standard"
              defaultValue={firm["Founding Year"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  "Founding Year": e.target.value,
                });
              }}
            />
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel>Primary Industry</InputLabel>
              <Select
                value={firm["primaryIndustry"]}
                label="Primary Industry"
                onChange={(e) => {
                  setFirm({
                    ...firm,
                    primaryIndustry: e.target.value,
                  });
                }}
              >
                <MenuItem value={""}>No Primary Industry Category</MenuItem>
                <MenuItem
                  value={
                    "Accommodations - Bed and breakfast, or any other lodging facility"
                  }
                >
                  Accommodations - Bed and breakfast, or any other lodging
                  facility
                </MenuItem>
                <MenuItem value={"Business Services"}>
                  Business Services
                </MenuItem>
                <MenuItem
                  value={
                    "Construction and Maintenance Services - Grading, excavating, other construction and maintenance services"
                  }
                >
                  Construction and Maintenance Services - Grading, excavating,
                  other construction and maintenance services
                </MenuItem>
                <MenuItem value={"Engineering, Design and Development"}>
                  Engineering, Design and Development
                </MenuItem>
                <MenuItem value={"Entertainment and Recreation"}>
                  Entertainment and Recreation
                </MenuItem>
                <MenuItem value={"Food"}>Food</MenuItem>
                <MenuItem
                  value={"Healthcare - chiropractors, dental, health clinics"}
                >
                  Healthcare - chiropractors, dental, health clinics
                </MenuItem>
                <MenuItem
                  value={
                    "Maintenance & Repair - automobile repair, automobile dealership, cellular phone repair, landscaping and lawn maintenance"
                  }
                >
                  Maintenance & Repair - automobile repair, automobile
                  dealership, cellular phone repair, landscaping and lawn
                  maintenance
                </MenuItem>
                <MenuItem
                  value={
                    "Nonprofit - churches, religious centers, advocacy organizations, etc."
                  }
                >
                  Nonprofit - churches, religious centers, advocacy
                  organizations, etc.
                </MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
                <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                <MenuItem value={"Personal Services"}>
                  Personal Services
                </MenuItem>
                <MenuItem
                  value={
                    "Professional & Other Services - taxes, consulting, publishing, marketing"
                  }
                >
                  Professional & Other Services - taxes, consulting, publishing,
                  marketing
                </MenuItem>
                <MenuItem value={"Retail"}>Retail</MenuItem>
                <MenuItem value={"Technology"}>Technology</MenuItem>
                <MenuItem value={"Transportation - limousine services, etc."}>
                  Transportation - limousine services, etc.
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel>Secondary Industry</InputLabel>
              <Select
                label="Secondary Industry"
                value={firm["secondaryIndustry"]}
                onChange={(e) => {
                  setFirm({
                    ...firm,
                    secondaryIndustry: e.target.value,
                  });
                }}
              >
                <MenuItem value={""}>No Secondary Industry Category</MenuItem>
                <MenuItem
                  value={
                    "Business Services - Landscaping, extermination, janitorial and housekeeping services"
                  }
                >
                  Business Services - Landscaping, extermination, janitorial and
                  housekeeping services
                </MenuItem>
                <MenuItem
                  value={
                    "Business Services - Printing, market research, photography, advertising, public relations and related services"
                  }
                >
                  Business Services - Printing, market research, photography,
                  advertising, public relations and related services
                </MenuItem>
                <MenuItem
                  value={
                    "Business Services - Computer systems design, software, programming services"
                  }
                >
                  Business Services - Computer systems design, software,
                  programming services
                </MenuItem>
                <MenuItem
                  value={
                    "Business Services - Interior, industrial, graphic, and other specialized design services"
                  }
                >
                  Business Services - Interior, industrial, graphic, and other
                  specialized design services
                </MenuItem>
                <MenuItem
                  value={
                    "Entertainment and Recreation - Golf courses, skiing facilities, other recreation industries"
                  }
                >
                  Entertainment and Recreation - Golf courses, skiing
                  facilities, other recreation industries
                </MenuItem>
                <MenuItem
                  value={
                    "Entertainment and Recreation - Yoga studios, gyms, fitness studios, personal trainers"
                  }
                >
                  Entertainment and Recreation - Yoga studios, gyms, fitness
                  studios, personal trainers
                </MenuItem>
                <MenuItem value={"Personal Services - Childcare"}>
                  Personal Services - Childcare
                </MenuItem>
                <MenuItem
                  value={
                    "Personal Services - Barber shops, hair and nail salons"
                  }
                >
                  Personal Services - Barber shops, hair and nail salons
                </MenuItem>
                <MenuItem
                  value={
                    "Personal Services - Diet, weight loss, spas, massage, other personal care services"
                  }
                >
                  Personal Services - Diet, weight loss, spas, massage, other
                  personal care services
                </MenuItem>
                <MenuItem
                  value={
                    "Retail - Bookstores, hardware, anything sold that is not food"
                  }
                >
                  Retail - Bookstores, hardware, anything sold that is not food
                </MenuItem>
                <MenuItem
                  value={
                    "Retail - Clothing, shoe, sporting goods, resale and consignment stores"
                  }
                >
                  Retail - Clothing, shoe, sporting goods, resale and
                  consignment stores
                </MenuItem>
                <MenuItem
                  value={
                    "Retail - Jewelry, art, hobby, musical instrument, pet supply and other miscellaneous retail stores"
                  }
                >
                  Retail - Jewelry, art, hobby, musical instrument, pet supply
                  and other miscellaneous retail stores
                </MenuItem>
                <MenuItem
                  value={
                    "Retail - Pharmacies, cosmetics, optical goods, other health and personal care stores"
                  }
                >
                  Retail - Pharmacies, cosmetics, optical goods, other health
                  and personal care stores
                </MenuItem>
                <MenuItem value={"Retail - Groceries"}>
                  Retail - Groceries
                </MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" fontWeight={"bold"}>
              Grants Applications
            </Typography>
            <hr></hr>
            <TableContainer
              component={"table"}
              sx={{ backgroundColor: "white" }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Grant</StyledTableCell>
                  <StyledTableCell>Approved?</StyledTableCell>
                  <StyledTableCell align="right">$ Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {availableGrant.map((grant: any, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Checkbox
                        checked={editedGrant?.[grant]?.["applied"]}
                        onChange={(e) => {
                          setEditedGrant({
                            ...editedGrant,
                            [grant]: {
                              ...editedGrant[grant],
                              applied: e.target.checked,
                            },
                          });
                        }}
                      ></Checkbox>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="flex items-center">
                        {grant}
                        {grantVsDefination[grant] && (
                          <div className="group ml-1">
                            <InfoIcon
                              sx={{ color: "#0F95B3", fontSize: "20px" }}
                              className="group-hover:cursor-pointer"
                            />
                            <span className="absolute z-10 ml-2 scale-0 transition-all rounded bg-blueGreen p-2 text-xs text-white group-hover:scale-100">
                              {grantVsDefination[grant]}
                            </span>
                          </div>
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={
                          editedGrant?.[grant]?.["decision"] === "Approved"
                        }
                        onChange={(e) => {
                          setEditedGrant({
                            ...editedGrant,
                            [grant]: {
                              ...editedGrant[grant],
                              decision: e.target.checked
                                ? "Approved"
                                : "Rejected",
                            },
                          });
                        }}
                      ></Checkbox>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Input
                        sx={{ width: "70%", float: "right" }}
                        value={editedGrant?.[grant]?.["value"]}
                        onChange={(e) => {
                          setEditedGrant({
                            ...editedGrant,
                            [grant]: {
                              ...editedGrant[grant],
                              value: e.target.value,
                            },
                          });
                        }}
                      ></Input>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableContainer>
            <StyledTextarea
              sx={{ width: { xs: "300px", md: "100%" }, marginTop: "20px" }}
              minRows={8}
              maxRows={10}
              placeholder="Firm Notes"
              value={firm["notes"]}
              onChange={(e) => {
                setFirm({
                  ...firm,
                  notes: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ position: "relative" }}>
            <FormGroup>
              <Typography variant="h6" fontWeight={"bold"}>
                Certifications
              </Typography>
              <hr></hr>
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Certified Women Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Certified Women Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Certified Women Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Certified Women Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Certified Minority Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Certified Minority Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Certified Minority Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Certified Minority Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Certified Disabled Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Certified Disabled Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Certified Disabled Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Certified Disabled Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Certified Veteran Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Certified Veteran Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Certified Veteran Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Certified Veteran Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Certified LGBTQ+ Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Certified LGBTQ+ Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Certified LGBTQ+ Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Certified LGBTQ+ Owned"
              />
              <Typography variant="h6" fontWeight={"bold"}>
                Classifications
              </Typography>
              <hr></hr>
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Woman-Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Woman-Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Woman-Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Woman-Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Minority-Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Minority-Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Minority-Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Minority-Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Veteran-Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Veteran-Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Veteran-Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Veteran-Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Disabled-Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Disabled-Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Disabled-Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Disabled-Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["LGBTQ+ Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "LGBTQ+ Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "LGBTQ+ Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="LGBTQ+ Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Youth Owned"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Youth Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Youth Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Youth Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Low to Moderate Income Owned"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Low to Moderate Income Owned": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Low to Moderate Income Owned": "No",
                        });
                      }
                    }}
                  />
                }
                label="Low to Moderate Income Owned"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Non-English Business"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Non-English Business": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Non-English Business": "No",
                        });
                      }
                    }}
                  />
                }
                label="Non-English Business"
              />
              <Typography variant="h6" fontWeight={"bold"}>
                Active Business Channels
              </Typography>
              <hr></hr>
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Home-based"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Home-based": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Home-based": "No",
                        });
                      }
                    }}
                  />
                }
                label="Home-based"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Selling Online"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Selling Online": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Selling Online": "No",
                        });
                      }
                    }}
                  />
                }
                label="Selling Online"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["In-Person Sales"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "In-Person Sales": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "In-Person Sales": "No",
                        });
                      }
                    }}
                  />
                }
                label="In-Person Sales"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(
                      firm["Commercial Location"]
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Commercial Location": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Commercial Location": "No",
                        });
                      }
                    }}
                  />
                }
                label="Commercial Location"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={["Yes", "=Yes"].includes(firm["Mobile Business"])}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFirm({
                          ...firm,
                          "Mobile Business": "Yes",
                        });
                      } else {
                        setFirm({
                          ...firm,
                          "Mobile Business": "No",
                        });
                      }
                    }}
                  />
                }
                label="Mobile Business"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      {firm.openCase ? (
        <Button
          variant="contained"
          sx={{
            fontSize: "20px",
            backgroundColor: "#9E1F63FF",
          }}
          onClick={() => {
            navigate(`/case?id=${firm.openCase}`);
          }}
        >
          Go to Case
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{
            fontSize: "20px",
            backgroundColor: "#9E1F63FF",
          }}
          onClick={() => {
            updateAndSubmit(firm, editedGrant, contacts);
          }}
        >
          Save and Create Case
        </Button>
      )}
    </>
  );
};

export default ArchiveCase;
