import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";

interface ThriveTitleProps {
  title: string;
  setOpen: (open: boolean) => void;
}

const ThriveTitle: FC<ThriveTitleProps> = ({ title, setOpen }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#9E1F63FF",
        padding: "3px",
        margin: 0,
        color: "white",
        borderRadius: "5px 5px 0 0",
      }}
    >
      <Typography sx={{ display: "inline-block" }} variant="h4">
        {title}
      </Typography>
      <Box
        sx={{ display: "inline", float: "right", cursor: "pointer" }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
      </Box>
    </Box>
  );
};

export default ThriveTitle;
