import axios from "axios";
import Cookies from "js-cookie";

export const login = async (username: string, password: string) => {
  const url = `${process.env.REACT_APP_API_DOMAIN}/auth/login`;
  try {
    const response = await axios.post(url, {
      username: username,
      password: password,
    });
    const { user, token } = response.data;
    Cookies.set("token", token, {
      expires: 1,
      secure: true,
      sameSite: "Strict",
    });
    return user;
  } catch (error) {
    console.error(
      "You have an error in your code or there are Network issues.",
      error
    );
    return null;
  }
};

export const twofalogin = async (
  username: string,
  password: string,
  twoFactorCode: string
) => {
  const url = `${process.env.REACT_APP_API_DOMAIN}/auth/2fa`;
  try {
    const response = await axios.post(url, {
      username: username,
      password: password,
      code: twoFactorCode,
    });
    const { user, token } = response.data;
    Cookies.set("token", token, {
      expires: 1,
      secure: true,
      sameSite: "Strict",
    });
    return user;
  } catch (error) {
    console.error(
      "You have an error in your code or there are Network issues.",
      error
    );
    return null;
  }
};

export const validate = async (currentPath?: any) => {
  const token: string | undefined = Cookies.get("token");
  if (/^\/survey/.test(currentPath)) {
    return true; // Return true for the public survey routes
  }
  if (token && token !== "undefined") {
    try {
      // if (process.env.REACT_APP_NODE_ENV === "development") {
      //     return true
      // }
      const url = `${process.env.REACT_APP_API_DOMAIN}/auth/validate`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { valid } = response.data;
      return valid;
    } catch (error) {
      console.error(
        "You have an error in your code or there are Network issues.",
        error
      );
    }
  } else {
    return false;
  }
};

export const logout = () => {
  Cookies.remove("token");
};
