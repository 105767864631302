const cities: string[] = [
  "AUBURN HILLS",
  "BERKLEY",
  "BIRMINGHAM",
  "BLOOMFIELD HILLS",
  "CLARKSTON",
  "CLAWSON",
  "COMMERCE TOWNSHIP",
  "DAVISBURG",
  "FARMINGTON",
  "FARMINGTON HILLS",
  "FERNDALE",
  "FRANKLIN",
  "HAZEL PARK",
  "HIGHLAND",
  "HOLLY",
  "HUNTINGTON WOODS",
  "KEEGO HARBOR",
  "LAKE ORION",
  "LAKEVILLE",
  "LEONARD",
  "MADISON HEIGHTS",
  "MILFORD",
  "NEW HUDSON",
  "NOVI",
  "OAK PARK",
  "OAKLAND",
  "ORTONVILLE",
  "OXFORD",
  "PLEASANT RIDGE",
  "PONTIAC",
  "ROCHESTER",
  "ROCHESTER HILLS",
  "ROYAL OAK",
  "SOUTH LYON",
  "SOUTHFIELD",
  "TROY",
  "UNION LAKE",
  "WALLED LAKE",
  "WATERFORD",
  "WEST BLOOMFIELD",
  "WHITE LAKE",
  "WIXOM",
  "WOLVERINE LAKE",
];

export default cities;
