import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Box,
  Button,
  TableCell,
  Tooltip,
  Stack,
} from "@mui/material";
import MapContainer from "../../Map/MapContainer";
import ThriveModal from "../../common/ThriveModal";
import EditFirm from "./EditFirm";
import pulseLogo from "../../../assets/images/pulse_logo.png";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Link } from "react-router-dom";
import { StyledTextarea } from "../../common/Textareas";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "1px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "td, th": {
    border: 0,
  },
}));

export const currencyFormat = (num: any) => {
  if (num) {
    if (typeof num !== "number") {
      num = num.replaceAll(",", "");
      num = num.replaceAll("$", "");
      return "$" + parseInt(num);
    }

    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "Not Available";
  }
};

export const getGrantStatus = (firm: any) => {
  let arrayGrants: any = [];
  if (firm.grants) {
    for (let grantItem of Object.keys(firm.grants)) {
      arrayGrants.push({ ...firm["grants"][grantItem], grant: grantItem });
    }
  }

  return arrayGrants;
};

const PulseSurvey = (props: any) => {
  const surveyJson = {
    elements: [
      {
        name: "q1",
        title: "Is your business still in operation?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q2",
        title: "Owner / Manager Name:",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q3",
        title: "Business Phone #:",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q4",
        title: "Business Email Address:",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q5",
        title:
          "Current number of employees (including full-time, part-time and 1099)?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q6",
        title:
          "Overall, how has this business been affected by the Coronavirus pandemic?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q7",
        title:
          "In 2022, did this business have a change in operating revenues / sales / receipts, not including any financial assistance or loans?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q8",
        title:
          "Since 2022, did this business have a change in the number of paid employees?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q9",
        title: "Did this business have difficulties hiring paid employees?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
      {
        name: "q10",
        title:
          "In the next six months, do you think this business will need to do any of the following?",
        type: "comment",
        rows: 3,
        autoGrow: true,
        allowResize: false,
      },
    ],
  };

  const survey = new Model(surveyJson);
  survey.data = {
    q1: props.openFirm["survey_data"][
      "1. Is your business still in operation?"
    ],
    q2: props.openFirm["survey_data"]["a. Owner / Manager Name:"],
    q3: props.openFirm["survey_data"]["b. Business Phone #:"],
    q4: props.openFirm["survey_data"]["c. Business Email Address:"],
    q5: props.openFirm["survey_data"][
      "3. Current number of employees (including full-time, part-time and 1099)?"
    ],
    q6: props.openFirm["survey_data"][
      "4. Overall, how has this business been affected by the Coronavirus pandemic?"
    ],
    q7: props.openFirm["survey_data"][
      "5. In 2022, did this business have a change in operating revenues / sales / receipts, not including any financial assistance or loans?"
    ],
    q8: props.openFirm["survey_data"][
      "6. Since 2022, did this business have a change in the number of paid employees?"
    ],
    q9: props.openFirm["survey_data"][
      "7. Did this business have difficulties hiring paid employees?"
    ],
    q10: props.openFirm["survey_data"][
      "8. In the next six months, do you think this business will need to do any of the following? (Select all that apply)"
    ],
  };

  survey.onComplete.add((sender, options) => {
    props.setCardOpen(false);
  });
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          PULSE SURVEY QUESTIONS
        </Typography>
      </Box>
      <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 520 } }}>
        <Survey model={survey} />
      </Box>
    </>
  );
};

const MiNiFirmReport = (props: any) => {
  const openFirm = props.firm;
  const grants = getGrantStatus(props.firm);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const [pulseOpen, setPulseOpen] = React.useState<boolean>(false);

  const handleEditClick = () => {
    setEditOpen(true);
  };

  const handlePulseClick = () => {
    setPulseOpen(true);
  };

  const handleDirectEmail = () => {
    props.setDirectEmailOpen(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        style={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        {openFirm["surveyAnswered"] ? (
          <Box
            style={{
              float: "left",
              backgroundColor: "#fdb814",
              cursor: "pointer",
            }}
            onClick={handlePulseClick}
          >
            <Tooltip title="Pulse Survey Returned">
              <img
                src={pulseLogo}
                style={{ marginTop: "4px", marginBottom: "4px" }}
              />
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}

        <Typography sx={{ display: "inline-block" }} variant="h4">
          Firm Detail
        </Typography>
        {props.user.role === "admin" || props.user.role === "bfc" ? (
          <Stack
            direction={"row"}
            sx={{
              display: "inline-block",
              float: "right",
              marginTop: "3px",
            }}
            spacing={1}
          >
            {openFirm["openCase"] ? (
              <Link to={`/case?id=${openFirm["openCase"]}`}>
                <Button
                  sx={{
                    backgroundColor: "#fdb814",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#ffd061",
                      color: "gray",
                    },
                    fontSize: "12px",
                  }}
                >
                  Case Detail
                </Button>
              </Link>
            ) : (
              <></>
            )}

            <Button
              variant="contained"
              onClick={handleEditClick}
              sx={{
                backgroundColor: "#fdb814",
                color: "black",
                "&:hover": {
                  backgroundColor: "#ffd061",
                  color: "gray",
                },
                fontSize: "12px",
              }}
            >
              Edit
            </Button>
          </Stack>
        ) : (
          <></>
        )}
      </Box>

      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          margin: "auto",
          marginBottom: "10px",
          paddingRight: "10px",
          maxHeight: "300px",
        }}
      >
        <Grid item xs={12} md={3} style={{ position: "relative" }}>
          <Typography variant="h4" gutterBottom color={"#2398b3"}>
            {openFirm["Company Name"]}
          </Typography>
          {/* <div
                style={{
                  bottom: "10px",
                  position: "absolute",
                  left: "10px",
                }}
              >
                <img
                  style={{ width: "200px" }}
                  src={`${process.env.REACT_APP_API_DOMAIN}/images/detail_report3.png`}
                ></img>
              </div> */}
        </Grid>
        <Grid item xs={12} md={4} style={{ backgroundColor: "#f5f5f5" }}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Total Contracted Value:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["tempTotalContractedValue"])}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Current Contract Value:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["tempContractedValue"])}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Prior Contract Value:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["priorContractedValue"])}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Avg. Contract Value:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["avgContractValue"])}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Total Sub-Contracted Value:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["totalSubcontractedValue"])}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Annual Revenue:</StyledTableCell>
                <StyledTableCell align="right">
                  {currencyFormat(openFirm["Annual Revenue"])}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={5} style={{ backgroundColor: "#f5f5f5" }}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow>
                <StyledTableCell align="left">Financial Risk:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["prescreenScore"]
                    ? openFirm["prescreenScore"]
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Years In Business:
                </StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Founding Year"]
                    ? new Date().getFullYear() -
                      parseInt(openFirm["Founding Year"])
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Number of Employees:&nbsp;
                </StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Employees"]
                    ? openFirm["Employees"]
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">For-Profit:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["For-Profit"]
                    ? openFirm["For-Profit"]
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Home Based:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Home-based"]
                    ? openFirm["Home-based"]
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">
                  Downtown Location :
                </StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Downtown Location"]
                    ? openFirm["Downtown Location"]
                    : "Not Available"}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        rowSpacing={0}
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          margin: "auto",
          marginBottom: "10px",
          paddingRight: "10px",
        }}
      >
        <Grid item xs={12} md={7}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              {grants.map((grant: any, index: number) => {
                if (grant["decision"] === "Approved") {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {grant.grant}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {grant.decision}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyFormat(grant.value)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
              })}
              <StyledTableRow>
                <StyledTableCell align="left">&nbsp;</StyledTableCell>
                <StyledTableCell align="center">&nbsp;</StyledTableCell>
                <StyledTableCell align="right">&nbsp;</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>

          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow
                sx={
                  openFirm["do_not_contact"] === "Yes"
                    ? { backgroundColor: "lightpink !important" }
                    : {}
                }
              >
                <StyledTableCell align="left">Contact:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Contact"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow
                sx={
                  openFirm["do_not_contact"] === "Yes"
                    ? { backgroundColor: "lightpink !important" }
                    : {}
                }
              >
                <StyledTableCell align="left">Email:</StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ cursor: "pointer" }}
                  onClick={handleDirectEmail}
                >
                  {openFirm["Email"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Phone:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Phone"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Website:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Website"]}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Industry:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["secondaryIndustry"]
                    ? openFirm["secondaryIndustry"]
                    : openFirm["primaryIndustry"]}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <div>
            <h5>{`${openFirm["Address"]} ${openFirm["City"]}, ${openFirm["State"]} ${openFirm["ZIP"]}`}</h5>
            <MapContainer
              address={`${openFirm["Address"]} ${openFirm["City"]}, ${openFirm["State"]} ${openFirm["ZIP"]}`}
              style={{ height: "250px" }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        rowSpacing={0}
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          margin: "auto",
          marginBottom: "10px",
          paddingRight: "10px",
        }}
      >
        <Grid item xs={12} md={3}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow>
                <StyledTableCell align="left">Cage Code:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Cage Code"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">DUNS Number:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["dunsNumber"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">SAM ID:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["SAMID"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Case Number:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["openCase"]}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow>
                <StyledTableCell align="left">
                  B2B Accounting:&nbsp;
                </StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["B2B Accounting"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">B2B Attorney:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["B2B Attorney"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">B2B Consulting:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["B2B Consulting"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Legal Entity:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Legal Entity"]}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer component={"table"}>
            <TableBody sx={{ display: "table", width: "100%" }}>
              <StyledTableRow>
                <StyledTableCell align="left">Woman-Owned:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Woman-Owned"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Veteran-Owned:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Veteran-Owned"]}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Minority-Owned:</StyledTableCell>
                <StyledTableCell align="right">
                  {openFirm["Minority-Owned"]}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </TableContainer>
        </Grid>
        {openFirm["Childcare Details"] && (
          <>
            <Grid md={12} xs={12} sx={{ textAlign: "center" }}>
              <Box>
                <Typography>
                  -----------------------------------------Childcare
                  Details-----------------------------------------
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer component={"table"}>
                <TableBody sx={{ display: "table", width: "100%" }}>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      License Number:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["License Number"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      License Type:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["License Type"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Licensed/registered date:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {new Intl.DateTimeFormat("en-US").format(
                        new Date(
                          openFirm["Childcare Details"][
                            "Licensed/registered date"
                          ]
                        )
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Capacity:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Capacity"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Accepts Ages From:&nbsp;
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Accepts Ages From"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Accepts Ages To:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Accepts Ages To"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Licensed Ages From:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Licensed Ages From"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Licensed Ages To:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Licensed Ages To"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Quality Level:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Quality Level"]}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Accreditation:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Accreditation"]}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer component={"table"}>
                <TableBody sx={{ display: "table", width: "100%" }}>
                  <StyledTableRow>
                    <StyledTableCell align="left">Full-Time:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Full-Time"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Part-Time:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Part-Time"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Drop-In/Hourly:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Drop-In/Hourly"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Before School:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Before School"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      After School:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["After School"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Evening:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Evening"] ? "Yes" : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Overnight:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Overnight"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">24 Hours:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["24 Hours"] ? "Yes" : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Weekend:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Weekend"] ? "Yes" : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Child Development and Care (CDC) Subsidy:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"][
                        "Child Development and Care (CDC) Subsidy"
                      ]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer component={"table"}>
                <TableBody sx={{ display: "table", width: "100%" }}>
                  <StyledTableRow>
                    <StyledTableCell align="left">Cooperative:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Cooperative"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Faith-based:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Faith-based"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Montessori:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Montessori"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Reggio Inspired:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Reggio Inspired"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Preschool:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Preschool"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Strong Beginnings:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Strong Beginnings"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      GSRP-Great Start Readiness Program:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"][
                        "GSRP-Great Start Readiness Program"
                      ]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Early Head Start:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Early Head Start"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">Head Start:</StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Head Start"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      School Age ONLY:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["School Age ONLY"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Nature-based:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {openFirm["Childcare Details"]["Nature-based"]
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </TableContainer>
            </Grid>
          </>
        )}

        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <hr></hr>
          <StyledTextarea
            sx={{
              width: { xs: "300px", md: "900px" },
              margin: "auto",
              display: "block",
            }}
            minRows={8}
            maxRows={10}
            disabled
            placeholder="Firm Notes"
            value={props.firm["notes"]}
          />
        </Box>
      </Grid>
      <ThriveModal
        height={750}
        open={editOpen}
        setOpen={setEditOpen}
        contentComponent={
          <EditFirm
            setOpen={setEditOpen}
            firm={props.editedFirm}
            setEditedFirm={props.setEditedFirm}
            editedGrant={props.editedGrant}
            setEditedGrant={props.setEditedGrant}
            dataFlag={props.dataFlag}
            setDataFlag={props.setDataFlag}
          ></EditFirm>
        }
      ></ThriveModal>
      <ThriveModal
        open={pulseOpen}
        setOpen={setPulseOpen}
        closeByClick={true}
        contentComponent={
          <PulseSurvey
            openFirm={openFirm}
            setCardOpen={setPulseOpen}
          ></PulseSurvey>
        }
      ></ThriveModal>
    </Box>
  );
};

export default MiNiFirmReport;
