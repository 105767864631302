import React from "react";
import { Box, Stack } from "@mui/material";
import ActivityCard from "./ActivityCard";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserContext";

const ActivityCount = (props: any) => {
  const { user } = React.useContext(UserContext);
  return (
    <Box>
      <Stack
        sx={{ backgroundColor: "" }}
        direction={"row"}
        divider={<hr style={{ margin: "auto" }} />}
      >
        <ActivityCard
          title={user.role === "admin" ? "Open Case" : "My Firms"}
          count={props.userStats.openCases}
          imageName={"myfirms"}
          open={props.open}
          setOpen={props.setOpen}
        />
        <ActivityCard
          title={user.role === "admin" ? "Referrals" : "My Referrals"}
          count={props.userStats.referrals}
          imageName={"myreferrals"}
          open={false}
          setOpen={""}
        />
        <ActivityCard
          title={user.role === "admin" ? "Resources" : "My Resources"}
          count={props.userStats.resources}
          imageName={"myresources"}
          open={false}
          setOpen={""}
        />
        <ActivityCard
          title={user.role === "admin" ? "Closed Cases" : "Closed Cases"}
          count={props.userStats.finalizedEngagements}
          imageName={"myengagements"}
          open={false}
          setOpen={""}
        />
      </Stack>
    </Box>
  );
};

export default ActivityCount;
