import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridRow, GridRowParams } from "@mui/x-data-grid";

import PropTypes from "prop-types";
import clsx from "clsx";
import MiNiFirmReport from "./MiNiFirmReport";
import ThriveModal from "../../common/ThriveModal";
import pulseLogo from "../../../assets/images/pulse_logo.png";
import NoticeDialog from "../../common/NoticeDialog";
import { DirectEmailEmptyBody } from "../../common/EmailBodies/DirectEmailEmptyBody";
// import TheMap from "./map";

function CircularProgressWithLabel(props: any) {
  let color: string;
  if (props.value >= 80) {
    color = "#7e9d62";
  } else if (props.value >= 65) {
    color = "#fecc5b";
  } else if (props.value >= 50) {
    color = "#e57b30";
  } else {
    color = "#b85256";
  }
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ color: color }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={14}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function CustomRow(props: any) {
  const { className, index, ...other } = props;

  return (
    <GridRow
      index={index}
      className={clsx(className, index % 2 === 0 ? "odd" : "even")}
      {...other}
    />
  );
}

function ListingTable(props: any) {
  const [open, setOpen] = useState(false);
  const [directEmailOpen, setDirectEmailOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [dataFlag, setDataFlag] = useState(false);
  const [openFirm, setOpenFirm] = useState(0);
  const [editedFirm, setEditedFirm] = React.useState({});
  const [editedGrant, setEditedGrant] = React.useState({});
  const handleOpen = () => setOpen(true);
  let rows: any[] = [];
  let counter: number = 0;
  const firmList = props["firmData"]["firms"];
  const heightScore = Math.max(...firmList.map((o: any) => o.firmScore));
  const [selectedRoww, setSelectedRoww] = useState([]);
  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "title",
      headerName: "Firm Name",
      headerAlign: "center",
      minWidth: 200,
      renderCell: (cellValues) => {
        return (
          <div className="flex flex-col items-start">
            <div style={{ whiteSpace: "pre-wrap" }}>
              <p
                style={{
                  color: "#9E1F63FF",
                  fontSize: "22px",
                  textAlign: "left",
                }}
              >
                {cellValues.row.title}
              </p>
            </div>
            {cellValues.row.surveyAnswered === true && (
              <Tooltip title="Pulse Survey Returned">
                <img
                  src={pulseLogo}
                  style={{ marginTop: "4px", marginBottom: "4px" }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "capabilityNarrative",
      headerName: "Industry",
      headerAlign: "center",
      minWidth: 450,
      renderCell: (cellValues) => {
        return (
          <>
            <p style={{ fontSize: "18px" }}>
              {cellValues.row.capabilityNarrative}
            </p>
          </>
        );
      },
    },
    {
      flex: 1,
      field: "city",
      headerName: "City",
      headerAlign: "center",
      maxWidth: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <p style={{ paddingLeft: "20px", fontSize: "18px" }}>
              {cellValues.row.city}
            </p>
          </>
        );
      },
    },
    {
      flex: 1,
      field: "firmScore",
      headerName: "Firm Score",
      headerAlign: "center",
      maxWidth: 150,
      renderCell: (cellValues) => {
        return (
          <div style={{ paddingLeft: "40px" }}>
            <CircularProgressWithLabel
              value={(parseFloat(cellValues.row.firmScore) / heightScore) * 100}
            />
            {/*<h4 style={{paddingLeft:"20px"}}>{}</h4>*/}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setSelectedRoww(props.selectedRow);
  }, [props.selectedRow]);
  for (let firm of firmList) {
    let descriptionText: string = "";
    if (firm.hasOwnProperty("capabilityNarrative")) {
      descriptionText = descriptionText + firm["capabilityNarrative"] + " ";
    }
    if (firm.hasOwnProperty("salesforceDescription")) {
      if (
        firm["salesforceDescription"] != null &&
        firm["salesforceDescription"].hasOwnProperty("descriptionText")
      ) {
        descriptionText =
          descriptionText + firm["salesforceDescription"]["descriptionText"];
      }
    }
    rows.push({
      id: counter,
      title: firm["Company Name"],
      capabilityNarrative: firm["secondaryIndustry"]
        ? firm["secondaryIndustry"]
        : firm["primaryIndustry"],
      city: firm["City"],
      firmScore: firm["firmScore"],
      openCase: firm["openCase"] ? true : false,
      surveyAnswered: firm["surveyAnswered"],
    });
    counter += 1;
  }
  if (firmList.length === 0) {
    return <h3 style={{ textAlign: "center" }}>No firm found...</h3>;
  }

  return (
    <>
      <Box
        style={{
          height: 800,
        }}
        sx={{
          height: 400,
          width: 1,
          "& .odd": {
            bgcolor: "#e8e8e8",
          },
          "& .even": {
            bgcolor: "#f8f7f7",
          },
          ".MuiDataGrid-columnHeaders": {
            fontSize: "22px",
            bgcolor: "#0F95B3",
            color: "white",
          },
          ".MuiSvgIcon-root": {},
        }}
      >
        {props.selectable ? (
          <DataGrid
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            rows={rows}
            columns={columns}
            rowHeight={100}
            isRowSelectable={(params: GridRowParams) => !params.row.openCase}
            checkboxSelection
            disableRowSelectionOnClick
            components={{ Row: CustomRow }}
            onRowClick={(row) => {
              // @ts-ignore
              setOpenFirm(row.id);
              setEditedFirm(firmList[row.id]);
              setEditedGrant(firmList[row.id]["grants"]);
              handleOpen();
            }}
            rowSelectionModel={selectedRoww}
            onRowSelectionModelChange={(row: any) => {
              setSelectedRoww(row);
              props.handleFirmSelect(row);
            }}
          />
        ) : (
          <DataGrid
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            rows={rows}
            columns={columns}
            rowHeight={100}
            disableRowSelectionOnClick
            components={{ Row: CustomRow }}
            onRowClick={(row) => {
              // @ts-ignore
              setOpenFirm(row.id);
              setEditedFirm(firmList[row.id]);
              setEditedGrant(firmList[row.id]["grants"]);
              handleOpen();
            }}
          />
        )}
      </Box>
      <div>
        <ThriveModal
          open={open}
          setOpen={setOpen}
          closeByClick={true}
          contentComponent={
            <MiNiFirmReport
              firm={firmList[openFirm]}
              user={props.user}
              editedFirm={editedFirm}
              setEditedFirm={setEditedFirm}
              editedGrant={editedGrant}
              setEditedGrant={setEditedGrant}
              setDirectEmailOpen={setDirectEmailOpen}
            ></MiNiFirmReport>
          }
        ></ThriveModal>

        <ThriveModal
          open={messageOpen}
          setOpen={setMessageOpen}
          closeByClick={true}
          height={200}
          width={500}
          contentComponent={
            <NoticeDialog
              title={"Email Sent!"}
              setOpen={setMessageOpen}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></NoticeDialog>
          }
        ></ThriveModal>
      </div>
    </>
  );
}

export default ListingTable;
