import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import ThriveTitle from "../common/ThriveTitle";
import { StyledButtonMain } from "../common/Buttons";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";

const UserEditor = (props: any) => {
  const [user, setUser] = useState(props.user);

  const handleSaveUser = (event: any) => {
    event.preventDefault();

    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    if (!user._id) {
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/auth/register`,
          {
            ...user,
            title: "Business Forward Consultant, Oakland Thrive",
          },
          axiosConfig
        )
        .then((res) => {
          // console.log("res", res.data);
          props.setOpen(false);
          window.location.reload();
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/auth/update`,
          {
            ...user,
          },
          axiosConfig
        )
        .then((res) => {
          // console.log("res", res.data);
          props.setOpen(false);
          window.location.reload();
        });
    }
  };

  return (
    <form onSubmit={handleSaveUser}>
      <ThriveTitle title="Edit User" setOpen={props.setOpen}></ThriveTitle>
      <Stack sx={{ padding: "20px" }} spacing={2}>
        <TextField
          label={`Username`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.username ? user.username : ""}
          onChange={(e) => {
            setUser({ ...user, username: e.target.value });
          }}
          required
        ></TextField>
        <TextField
          type="password"
          label={`Password`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.password ? user.password : ""}
          onChange={(e) => {
            setUser({ ...user, password: e.target.value });
          }}
          required={!user._id}
        ></TextField>

        <Stack direction="row" spacing={2}>
          {" "}
          <TextField
            label={`First Name`}
            variant="outlined"
            fullWidth
            className="mt-2"
            value={user.firstName ? user.firstName : ""}
            onChange={(e) => {
              setUser({ ...user, firstName: e.target.value });
            }}
            required
          ></TextField>
          <TextField
            label={`Last Name`}
            variant="outlined"
            fullWidth
            className="mt-2"
            value={user.lastName ? user.lastName : ""}
            onChange={(e) => {
              setUser({ ...user, lastName: e.target.value });
            }}
            required
          ></TextField>
          <FormControl fullWidth>
            <InputLabel required id="role-label">{`Role`}</InputLabel>
            <Select
              labelId="role-label"
              id="demo-simple-select"
              label={`Role`}
              value={user.role ? user.role : ""}
              onChange={(e) => {
                setUser({ ...user, role: e.target.value });
              }}
              required
            >
              <MenuItem value={"bfc"}>Business Forward Consultant</MenuItem>
              <MenuItem value={"admin"}>{"Admin"}</MenuItem>
              <MenuItem value={"deleted"}>{"Disabled"}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TextField
          label={`Title`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.title ? user.title : ""}
          onChange={(e) => {
            setUser({ ...user, title: e.target.value });
          }}
          required
        ></TextField>
        <TextField
          label={`Email`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.email ? user.email : ""}
          onChange={(e) => {
            setUser({ ...user, email: e.target.value });
          }}
          required
        ></TextField>
        <TextField
          label={`Phone`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.phone ? user.phone : ""}
          onChange={(e) => {
            setUser({ ...user, phone: e.target.value });
          }}
          required
        ></TextField>
        <TextField
          label={`Meeting Link`}
          variant="outlined"
          fullWidth
          className="mt-2"
          value={user.meeting_url ? user.meeting_url : ""}
          onChange={(e) => {
            setUser({ ...user, meeting_url: e.target.value });
          }}
          required
        ></TextField>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            width: "100%",
            textAlign: "center",
          }}
        >
          <StyledButtonMain type="submit" fullWidth variant="contained">
            Save
          </StyledButtonMain>
        </Box>
      </Stack>
    </form>
  );
};

export default UserEditor;
