import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ThriveTitle from "../../common/ThriveTitle";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";

const ProviderHis = (props: any) => {
  const [providerReferrals, setProviderReferrals] = useState<any[]>([]);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/providers/detail?id=${props.hisProvider.id}`,
        axiosConfig
      )
      .then((res) => {
        let providerReferrals = res.data.providerReferrals;
        // Reorder the clients by 2hr
        providerReferrals.sort((a: any, b: any) => {
          return a["2hr"] === b["2hr"] ? 0 : a["2hr"] ? -1 : 1;
        });

        setProviderReferrals(providerReferrals);
      });
  }, [props.hisProvider]);

  return (
    <>
      <ThriveTitle
        title={`${props.hisProvider.orgName} History`}
        setOpen={props.setOpen}
      />
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", maxHeight: "595px", padding: "10px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                align="left"
              >
                15 minutes
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                align="left"
              >
                15 minutes Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                align="right"
              >
                2 hours
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                align="right"
              >
                2 hours Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providerReferrals.map((row: any, index: number) => {
              if (row["client"]) {
                return (
                  <TableRow
                    key={index}
                    onClick={() => {
                      // Open a new tab with the case details
                      window.open(`/case?id=${row["case_id"]}`, "_blank");
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{row["client"]["Company Name"]}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography>
                        {dayjs(row["created_at"]).format("YYYY-MM-DD")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {row["2hr"] ? (
                        <CheckCircleIcon color="success"></CheckCircleIcon>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {row["2hr_date"]
                          ? dayjs(row["2hr_date"]).format("YYYY-MM-DD")
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProviderHis;
