import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";
import findObjectDifferences from "../../../utils/utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { StyledTextarea } from "../../common/Textareas";

const InitialContact = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const [initialContact, setInitialContact] = React.useState({
    initial_contact_method: "email",
    initial_contact_note: "",
    initial_contact_date: dayjs(),
    _id: "",
  });
  const [originalInitialContact, setOriginalInitialContact] = React.useState({
    initial_contact_method: "email",
    initial_contact_note: "",
    initial_contact_date: dayjs(),
    _id: "",
  });

  useEffect(() => {
    const theActivity = props.activity.find((activityItem: any) => {
      return activityItem.activity_type === "Initial Contact";
    });
    // console.log(theActivity);
    if (theActivity) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/initial_contact?initial_contact_id=${theActivity.initial_contact_id}`,
          axiosConfig
        )
        .then((res: any) => {
          setInitialContact(res.data.initial_contact);
          setOriginalInitialContact(res.data.initial_contact);
        });
    }
  }, [props.activity]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMethod: string
  ) => {
    setInitialContact({ ...initialContact, initial_contact_method: newMethod });
  };

  const handleSumbit = () => {
    let initial_contact = {
      _id: initialContact ? initialContact._id : "",
      activity_type: "Initial Contact",
      case_id: props.caseID,
      initial_contact_note: initialContact.initial_contact_note,
      initial_contact_method: initialContact.initial_contact_method,
      initial_contact_date: initialContact.initial_contact_date,
    };

    let origina_initial_contact = {
      _id: originalInitialContact ? originalInitialContact._id : "",
      activity_type: "Initial Contact",
      case_id: props.caseID,
      initial_contact_note: originalInitialContact.initial_contact_note,
      initial_contact_method: originalInitialContact.initial_contact_method,
      initial_contact_date: originalInitialContact.initial_contact_date,
    };

    if (
      Object.keys(
        findObjectDifferences(origina_initial_contact, initial_contact)
      ).length > 0
    ) {
      console.log("Find difference");
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/initial_contact`,
          initial_contact,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            props.setCardOpen(false);
            props.setDataFlag(!props.dataFlag);
          } else {
            console.log("API Error");
          }
        });
    } else {
      console.log("No difference");
      props.setCardOpen(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          INITIATE CONTACT
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setCardOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          margin: "auto",
          marginTop: { xs: "10px", md: "40px" },
        }}
      >
        <Typography variant="h6">Contact Method</Typography>
        <ToggleButtonGroup
          color="primary"
          value={initialContact.initial_contact_method}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="email">Email</ToggleButton>
          <ToggleButton value="phone">Phone</ToggleButton>
          <ToggleButton value="inPerson">In-Person</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: "auto",
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">Contact Date</Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]} sx={{ display: "block" }}>
            <DatePicker
              label="Interaction Date"
              value={dayjs(initialContact.initial_contact_date)}
              onChange={(value) => {
                setInitialContact({
                  ...initialContact,
                  initial_contact_date: value as Dayjs,
                });
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: "auto",
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">Contact Notes</Typography>
        <StyledTextarea
          sx={{ width: { xs: "300px", md: "600px" } }}
          minRows={8}
          maxRows={10}
          placeholder="Initiate Contact Notes"
          value={initialContact.initial_contact_note}
          onChange={(e) => {
            setInitialContact({
              ...initialContact,
              initial_contact_note: e.target.value,
            });
          }}
        />
      </Box>

      <Box
        sx={{
          bottom: 20,
          width: "100%",
          textAlign: "center",
          position: "fixed",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "200px",
            fontSize: "20px",
            backgroundColor: "#9E1F63FF",
          }}
          onClick={handleSumbit}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default InitialContact;
