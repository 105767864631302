import React from "react";
import ListingTable from "./ListingTable";
import { UserContext } from "../../../context/UserContext";

const FirmListing = (props: any) => {
  const { user } = React.useContext(UserContext);
  return (
    <div>
      <div className={"whitebox-container"}>
        <ListingTable
          handleFirmSelect={props.handleFirmSelect}
          firmData={props.firmData}
          setSelectedRow={props.setSelectedRow}
          selectedRow={props.selectedRow}
          selectable={props.selectable}
          user={user}
          showOnlyAvailableFirms={props.showOnlyAvailableFirms}
        />
      </div>
    </div>
  );
};

export default FirmListing;
