import FirmWizard from "./FirmWizard";

const Firms = () => {
  return (
    <div className="grid grid-cols-1">
      <div className="min-h-[670px] h-auto">
        <FirmWizard />
      </div>
    </div>
  );
};

export default Firms;
