import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Link from "@mui/material/Link";

// function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//   event.preventDefault();
//   console.info("You clicked a breadcrumb.");
// }

const ThriveBreadcrumb = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const breadcrumbTitle = (currentPath: string) => {
    switch (currentPath) {
      case "/users":
        return "User Management";
      case "/dashboard":
        return "";
      case "/case":
        return "Case Detail";
      case "/firmReport":
        return "Firm Report";
      case "/dashboard/firms":
        return "Visit Plan";
      case "/dashboard/exploreFirm":
        return "Explore Firm";
      case "/dashboard/resources":
        return "Resources";
      case "/clientReferralSurvey":
        return "Referral Client Survey";
      default:
        return "";
    }
  };
  return (
    <Box className="print:hidden" sx={{ padding: "8px" }}>
      {currentPath === "/" ? (
        <></>
      ) : (
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">
              {breadcrumbTitle(currentPath)}
            </Typography>
          </Breadcrumbs>
        </div>
      )}
    </Box>
  );
};

export default ThriveBreadcrumb;
