import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";

const surveyJson = {
  showQuestionNumbers: "off",
  elements: [
    {
      type: "html",
      name: "parentQuestion",
      html: "<h4>How did I do? (Use a scale of 1-6 where 1 indicates you are not at all satisfied, and 6 means you are extremely satisfied)</h4>",
    },
    {
      name: "q1a",
      title: "Quality of customer service",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1b",
      title: "Quality of resources provided",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1c",
      title: "Quality of responsiveness",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1d",
      title: "Helpful for your business?",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q2",
      title:
        "How likely is it that you would recommend Business Forward to a friend or business colleague?",
      description:
        "Use a scale of 1-10 where 1 indicates you are not at all likely to recommend, and 10 means you are extremely likely to recommend.",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      type: "html",
      name: "additional comments",
      html: "<h4>Additional Comments</h4>",
    },
    {
      name: "q3",
      title:
        "Have you met your consultant in person at your place of business?",
      type: "radiogroup",
      choices: ["Yes", "No"],
    },
    {
      name: "q3SubQuestion1",
      title: "If not, what day(s) work best?",
      type: "checkbox",
      visibleIf: '{q3} = "No"',
      choices: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    },
    {
      name: "q3SubQuestion2",
      title: "What time(s)?",
      type: "checkbox",
      visibleIf: '{q3} = "No"',
      choices: [
        "Early morning",
        "Lunch",
        "Late Afternoon",
        "Early Evening",
        "Evening",
      ],
    },
    {
      name: "q4",
      title: "General comments",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
    {
      name: "q5",
      title: "Consultant Strengths",
      description: "What did I do best?",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
    {
      name: "q6",
      title: "Areas for improvement",
      description: "What should I do better?",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
  ],
};

// const compareByDate = (a: any, b: any) => {
//   return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
// };

const setSurveyValue = (survey: any, data: any) => {
  survey.data = data["survey_result"];
};

const ClientReferralSurvey = (props: any) => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  // const getSurveyResult = (activty: any) => {
  //   const disAnswers = activty
  //     ?.filter((activityItem: any) => {
  //       return activityItem.activity_type === "Client Referral Survey";
  //     })
  //     ?.filter((activityItem: any) => {
  //       return activityItem.referral_id === props.referralID;
  //     });
  //   disAnswers?.sort(compareByDate);
  //   if (disAnswers?.length > 0) {
  //     return disAnswers[0]["survey_result_id"];
  //   } else {
  //     return null;
  //   }
  // };

  const survey = new Model(surveyJson);

  useEffect(() => {
    if (props.activity) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/survey/result?survey_result_id=${props.activity.survey_result_id}`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setSurveyValue(survey, res.data);
          } else {
            console.log("API Error at getting survey result");
          }
        });
    }
  }, [props.activity]);

  survey.onComplete.add((sender, options) => {
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_DOMAIN}/survey/result`,
    //     {
    //       activity_type: "Client Referral Survey",
    //       surveyResult: {
    //         survey_result: sender.data,
    //         case_id: props.case._id,
    //         survey_id: "64f75c4287cfd9719d4904e1",
    //         referral_id: props.referralID,
    //       },
    //     },
    //     axiosConfig
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       props.setSurveyOpen(false);
    //     } else {
    //       console.log("API Error");
    //     }
    //   });
    props.setSurveyOpen(false);
  });

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          CLIENT REFERRAL SURVEY
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            props.setSurveyOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>

      <Box sx={{ overflowY: "scroll", height: { xs: 470, md: 520 } }}>
        <Survey model={survey} />
      </Box>
    </>
  );
};
export default ClientReferralSurvey;
