import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Cookies from "js-cookie";
import Provider from "./Provider";
import ThriveModal from "../../common/ThriveModal";
import ThriveCaseSelection from "../../common/ThriveCaseSelection";
import ThriveResourceEditor from "../../common/ThriveResourceEditor";
import { StyledButtonMain } from "../../common/Buttons";
import Tags from "../Resources/Tags";
import EmailEditor from "../../common/EmailEditor";
import { Provider15minsReferral } from "../../common/EmailBodies/Provider15minsReferral";
import NoticeDialog from "../../common/NoticeDialog";
import FactSheetsList from "../Resources/FactsheetsList";
import ResourceNotes from "../Resources/ResourceNotes";
import ProviderHis from "./ProviderHis";
export interface providerData {
  id: string;
  title: string;
  url: string;
  tags: string[];
  imagePath: string;
  orgName: string;
  address: string;
  category: string;
  description: string;
}

export interface tagsData {
  tag: string;
}

interface ProviderStatics {
  [tag: string]: number;
}

function ProviderList(props: any) {
  const [selectedProvider, setSelectedProvider] = useState<providerData[]>([]);
  const [listingProvider, setListingProviders] = useState<providerData[]>([]);
  const [availableProviders, setAvailableProviders] = useState<providerData[]>(
    []
  );

  //Edit Resource States
  const [resourceEditorOpen, setResourceEditorOpen] = useState(false);
  const [editingResource, setEditingResource] = useState<any>({});

  const [editorOpen, setEditorOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [checkedTags, setCheckedTags] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [providerCategories, setProviderCategories] = useState<string[]>([]);
  const [checkedProviderCategories, setCheckedProviderCategories] = useState<{
    [key: string]: boolean;
  }>({});

  const [providerStatics, setProviderStatics] = useState<ProviderStatics>({});
  const [selectUnselectAll, setSelectUnselectAll] = useState<boolean>(true);
  const [searchDescription, setSearchDescription] = useState<boolean>(false);

  const [cases, setCases] = useState([]);
  const [displayCases, setDisplayCases] = useState<any>([]);
  const [caseActivities, setCaseActivities] = useState([]);
  const [selectedCase, setSelectedCase] = useState({ _id: "", firm: "" });
  const [checkedCases, setCheckedCases] = useState<any[]>([]);
  const [cardOpen, setCardOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState<any>({});
  const [providerFactsheetOpen, setProviderFactsheetOpen] = useState(false);
  const [providerNotesOpen, setProviderNotesOpen] = useState(false);
  const [hisOpen, setHisOpen] = useState(false);
  const [hisProvider, setHisProvider] = useState<any>({});
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/providers`, axiosConfig)
      .then((res) => {
        setAvailableProviders(res.data.providers);
        const categories = res.data.providers
          .map((provider: providerData) => provider.category)
          .sort();
        setProviderCategories(Array.from(new Set(categories)));
      });

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        setCases(res.data.cases);
        let case_id: string = "";
        for (let item of res.data.cases) {
          case_id = case_id + item._id + ",";
        }

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/activity/massive_get`,
            { case_id: case_id },
            axiosConfig
          )
          .then((res) => {
            setCaseActivities(res.data.activities);
          });
      });
  }, []);

  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/tags/provider`, axiosConfig)
      .then((res) => {
        setAvailableTags(res.data.tags);
        setCheckedTags(new Array(res.data.tags.length).fill(false));
      });
  }, []);

  useEffect(() => {
    const checkedCategoryMap: { [key: string]: boolean } = {};
    providerCategories.forEach((value: string) => {
      checkedCategoryMap[value] = true;
    });
    setCheckedProviderCategories(checkedCategoryMap);
  }, [providerCategories]);

  useEffect(() => {
    //uncomment it when adding tags logic
    calProviderStatics();
    const allChecked = Object.values(checkedProviderCategories).every(
      (value) => value === true
    );
    if (allChecked) {
      setSelectUnselectAll(true);
    } else {
      setSelectUnselectAll(false);
    }
  }, [checkedProviderCategories]);

  //Keep tracking on the checkTags
  useEffect(() => {
    setListing();
  }, [checkedTags, availableProviders, checkedProviderCategories]); //[checkedTags, availableProviders, checkedProviderCategories]

  const calProviderStatics = () => {
    let tempProviderStatics: ProviderStatics = {};
    if (availableProviders.length > 0) {
      availableProviders
        .filter(
          (provider: providerData) =>
            checkedProviderCategories[provider.category]
        )
        .map((provider: providerData) => {
          if (provider.tags) {
            provider.tags.map((tag: string) => {
              if (!(tag in tempProviderStatics)) {
                tempProviderStatics[tag] = 0;
              }
              // @ts-ignore
              tempProviderStatics[tag] += 1;
              return null;
            });
          }
          return null;
        });
    }
    setProviderStatics(tempProviderStatics);
  };

  const handleTagChange = (tagIndex: number) => {
    const updatedCheckedTags = checkedTags.map((item: any, index: number) =>
      index === tagIndex ? !item : item
    );
    setSearchQuery("");
    setCheckedTags(updatedCheckedTags);
  };

  if (!listingProvider) return <p>No profile data</p>;

  function setListing() {
    if (availableProviders) {
      setListingProviders(
        availableProviders.filter(
          (provider) => checkedProviderCategories[provider.category]
        )
      );
      //uncomment it when we add tags logic and remove the above code.
      let tagFlag: boolean = true;
      checkedTags.map((tag: boolean) => {
        tagFlag = tagFlag && !tag;
        return null;
      });

      if (tagFlag) {
        setListingProviders(
          availableProviders.filter(
            (provider) => checkedProviderCategories[provider.category]
          )
        );
      } else {
        let currentListingProviders: providerData[] = [];
        availableProviders
          .filter(
            (provider: providerData) =>
              checkedProviderCategories[provider.category]
          )
          .map((provider: providerData) => {
            for (let index in checkedTags) {
              if (checkedTags[index]) {
                if (
                  // @ts-ignore
                  provider.tags &&
                  // @ts-ignore
                  provider.tags.includes(availableTags[index])
                ) {
                  currentListingProviders.push(provider);
                  break;
                }
              }
            }
            return null;
          });
        setListingProviders(currentListingProviders);
      }
    }
  }

  function handleTagClick(e: any) {
    //old code
    let currentTagCheck = availableTags.map((tag: string) => {
      return e.target.value === tag;
    });
    // @ts-ignore
    setSearchQuery("");
    setCheckedTags(currentTagCheck);
  }

  const handleSearchInputChange = (event: any) => {
    //old code
    // if (checkedTags.some((tag: boolean) => tag)) {
    //   const updatedTags = checkedTags.map(() => false);
    //   setCheckedTags(updatedTags);
    // }
    const searchValue = event.target.value;
    setSearchQuery(searchValue);
    if (searchValue === "") {
      setListingProviders(availableProviders);
    } else {
      const filteredProviders = availableProviders.filter((provider) => {
        return (
          provider.orgName.toLowerCase().includes(searchValue.toLowerCase()) ||
          (searchDescription &&
            provider.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        );
      });
      setListingProviders(filteredProviders);
    }
  };

  const style = {
    "& label.Mui-focused": {
      color: "#9D2362",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#9D2362",
      },
    },
  };

  const handleCategoryChange = (event: any) => {
    const { name, checked } = event.target;
    setCheckedProviderCategories((prevCheckedProviderCategories) => ({
      ...prevCheckedProviderCategories,
      [name]: checked,
    }));
  };

  const handleSelectUnselectAll = (event: any) => {
    const { checked } = event.target;
    const updatedCheckedProviderCategories: { [key: string]: boolean } = {};
    for (const key in checkedProviderCategories) {
      updatedCheckedProviderCategories[key] = checked;
    }
    setSelectUnselectAll(checked);
    setCheckedProviderCategories(updatedCheckedProviderCategories);
  };

  const handleSearchDescriptionChange = (event: any) => {
    const { checked } = event.target;
    setSearchDescription(checked);
    //when user check checkbox and there is already something in search box
    if (searchQuery !== "" && checked) {
      const filteredProviders = availableProviders.filter((provider) => {
        return (
          provider.orgName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          provider.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setListingProviders(filteredProviders);
    }

    //when user uncheck checkbox and there is already something in search box
    if (searchQuery !== "" && !checked) {
      const filteredProviders = availableProviders.filter((provider) => {
        return provider.orgName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
      setListingProviders(filteredProviders);
    }
  };
  const handleAddNewProvider = () => {
    setEditingResource({});
    setResourceEditorOpen(true);
  };

  const handleSortClick = () => {
    const sortedProviders = [...availableProviders].sort((a, b) => {
      const nameA = a.orgName.toUpperCase();
      const nameB = b.orgName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    setAvailableProviders(sortedProviders);
  };

  return (
    <div className="">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1 col-span-1">
          <div className="min-h-[670px]">
            {availableTags && (
              <Tags
                availableTags={availableTags}
                checkedTags={checkedTags}
                resourceStatics={providerStatics}
                onTagChange={handleTagChange}
              />
            )}
          </div>
        </div>
        <div className="col-start-2 col-span-2">
          <TextField
            label="Search Providers"
            value={searchQuery}
            onChange={handleSearchInputChange}
            style={{ marginBottom: "10px", borderColor: "#9D2362" }}
            className="bg-white w-full"
            color="secondary"
            sx={style}
          />
          <div className="flex justify-end">
            <input
              type="checkbox"
              id="search_description"
              name="search_description"
              checked={searchDescription}
              onChange={handleSearchDescriptionChange}
              className="accent-magenta"
            />
            <label htmlFor="sample" className="text-left ml-2">
              Include Description
            </label>
          </div>

          <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="grid grid-cols-2 mb-2">
              {Object.entries(checkedProviderCategories).map(([key, value]) => (
                <div key={key} className="flex items-center">
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={value}
                    onChange={handleCategoryChange}
                  />
                  <label htmlFor={key} className="text-left ml-2">
                    {key}
                  </label>
                </div>
              ))}
            </div>
            <hr className="border-t-2 border-gray-300 mt-4" />
            <div className="flex items-center justify-between">
              <div key="select_unselect_all" className="flex items-center mt-2">
                <input
                  type="checkbox"
                  name="Select/Unselect All"
                  checked={selectUnselectAll}
                  onChange={handleSelectUnselectAll}
                />
                <label htmlFor="select_unselect_all" className="text-left ml-2">
                  Select/Unselect All
                </label>
              </div>
              <div className="self-end">
                Total Showing = {listingProvider.length}
              </div>
            </div>
          </div>

          <Stack spacing={1}>
            {props.user.role === "admin" && (
              <StyledButtonMain
                variant="contained"
                onClick={handleAddNewProvider}
              >
                Add new Provider
              </StyledButtonMain>
            )}
            <div className="flex justify-end">
              <button
                className="text-magenta underline"
                onClick={handleSortClick}
              >
                Sort
              </button>
            </div>
            <Stack spacing={3}>
              {listingProvider.length > 0 ? (
                listingProvider.map((provider: providerData, index: number) => {
                  return (
                    <Provider
                      open={cardOpen}
                      setOpen={setCardOpen}
                      tagDisplay={true}
                      handleTagClick={handleTagClick}
                      providerContent={provider}
                      index={index}
                      key={index}
                      user={props.user}
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                      setResourceEditorOpen={setResourceEditorOpen}
                      setEditingResource={setEditingResource}
                      setProviderFactsheetOpen={setProviderFactsheetOpen}
                      setProviderNotesOpen={setProviderNotesOpen}
                      setHisOpen={setHisOpen}
                      setHisProvider={setHisProvider}
                    />
                  );
                })
              ) : (
                <>
                  <h3 className="text-lg font-bold text-magenta">
                    No record found
                  </h3>
                </>
              )}
            </Stack>
          </Stack>
        </div>
      </div>
      {/* Select Case Popup Box*/}
      <ThriveModal
        open={cardOpen}
        setOpen={setCardOpen}
        contentComponent={
          <ThriveCaseSelection
            cases={cases}
            displayCases={displayCases}
            setDisplayCases={setDisplayCases}
            caseActivities={caseActivities}
            checkedCases={checkedCases}
            setCheckedCases={setCheckedCases}
            selectedCase={selectedCase}
            setSelectedCase={setSelectedCase}
            enableControl={true}
            enableSelection={true}
            enableMultiSelection={false}
            setOpen={setCardOpen}
            status_default="open"
            sortby_default="recent"
            title={"Select Connecting Case"}
            handleNext={async () => {
              if (selectedCase !== null) {
                const token: string | undefined = Cookies.get("token");
                const axiosConfig = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
                  },
                };
                const res = await axios.get(
                  `${process.env.REACT_APP_API_DOMAIN}/firms/id?id=${selectedCase["firm"]}`,
                  axiosConfig
                );
                setSelectedFirm(res.data.firm);

                const theActivities = caseActivities.filter((item: any) => {
                  return item.case_id === selectedCase["_id"];
                });
                const BNAActivity = theActivities.find((item: any) => {
                  return item.activity_type === "Business Assessment";
                });
                if (BNAActivity) {
                  setCardOpen(false);
                  setEditorOpen(true);
                } else {
                  alert(
                    "Selected client does not have a completed Business Needs Assessment. Please complete the assessment before referring your client to a B2B Provider."
                  );
                }
              }
            }}
          ></ThriveCaseSelection>
        }
      ></ThriveModal>
      <ThriveModal
        height={750}
        open={editorOpen}
        setOpen={setEditorOpen}
        contentComponent={
          <EmailEditor
            setOpen={setEditorOpen}
            theCase={selectedCase}
            theFirm={selectedFirm}
            theReferral={{}}
            theSubject={
              "Business Forward - Providers List for " +
              selectedFirm["Company Name"]
            }
            editorTitle={"Providers Referral Email"}
            theSelectedEvent={{}}
            bodyGenerator={Provider15minsReferral}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            checkedCases={checkedCases}
            displayCases={displayCases}
            theSelectedProvider={selectedProvider}
            theBFC={{}}
          ></EmailEditor>
        }
      ></ThriveModal>
      <ThriveModal
        open={messageOpen}
        setOpen={setMessageOpen}
        closeByClick={true}
        height={200}
        width={500}
        contentComponent={
          <NoticeDialog
            title={"Email Sent!"}
            setOpen={setMessageOpen}
            setDataFlag={setDataFlag}
          ></NoticeDialog>
        }
      ></ThriveModal>
      <ThriveModal
        open={resourceEditorOpen}
        setOpen={setResourceEditorOpen}
        contentComponent={
          <ThriveResourceEditor
            setOpen={setResourceEditorOpen}
            editorType={"Provider"}
            title={"Provider Editor"}
            editingResource={editingResource}
          ></ThriveResourceEditor>
        }
      ></ThriveModal>

      {/* modal for provider factsheets */}
      <ThriveModal
        open={providerFactsheetOpen}
        setOpen={setProviderFactsheetOpen}
        contentComponent={
          <FactSheetsList
            setOpen={setProviderFactsheetOpen}
            resource={editingResource}
          ></FactSheetsList>
        }
      ></ThriveModal>

      {/* modal for resource notes */}
      <ThriveModal
        open={providerNotesOpen}
        setOpen={setProviderNotesOpen}
        contentComponent={
          <ResourceNotes
            setOpen={setProviderNotesOpen}
            resource={editingResource}
            editorType={"Provider"}
          ></ResourceNotes>
        }
      ></ThriveModal>

      {/* modal for provider his */}
      <ThriveModal
        open={hisOpen}
        setOpen={setHisOpen}
        contentComponent={
          <ProviderHis
            setOpen={setHisOpen}
            hisProvider={hisProvider}
          ></ProviderHis>
        }
      ></ThriveModal>
    </div>
  );
}

export default ProviderList;
