import { useEffect, useState } from "react";
import ThriveCaseSelection from "../common/ThriveCaseSelection";
import Cookies from "js-cookie";
import axios from "axios";

interface Props {
  currentCase: any;
  setOpen: any;
}

const MergeCase: React.FC<Props> = ({ currentCase, setOpen }) => {
  const [cases, setCases] = useState<any[]>([]);
  const [displayCases, setDisplayCases] = useState<any[]>([]);
  const [caseActivities, setCaseActivities] = useState<any[]>([]);
  const [selectedCase, setSelectedCase] = useState<any>({});
  const [checkedCases, setCheckedCases] = useState<any[]>([]);

  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/cases`, axiosConfig)
      .then((res) => {
        // console.log("user cases", res.data.cases);
        setCases(res.data.cases.filter((c: any) => c._id !== currentCase._id));
        let case_id: string = "";
        for (let item of res.data.cases) {
          case_id = case_id + item._id + ",";
        }

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/activity/massive_get`,
            { case_id: case_id },
            axiosConfig
          )
          .then((res) => {
            // console.log("user activities", res.data.activities);
            setCaseActivities(res.data.activities);
          });
      });
  }, []);

  const handleMergeCase = () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases/merge`,
        {
          keep_case: currentCase._id,
          delete_case: selectedCase._id,
        },
        axiosConfig
      )
      .then((res) => {
        // console.log("merge case", res.data);
        window.location.reload();
      });
  };

  return (
    <>
      <ThriveCaseSelection
        cases={cases}
        displayCases={displayCases}
        setDisplayCases={setDisplayCases}
        caseActivities={caseActivities}
        checkedCases={checkedCases}
        setCheckedCases={setCheckedCases}
        selectedCase={selectedCase}
        setSelectedCase={setSelectedCase}
        enableControl={true}
        enableSelection={true}
        enableMultiSelection={false}
        setOpen={setOpen}
        status_default="open"
        sortby_default="recent"
        title={"Select Merge Case"}
        handleNext={handleMergeCase}
      ></ThriveCaseSelection>
    </>
  );
};
export default MergeCase;
