import React from "react";
import { Modal, Fade, Box, Backdrop } from "@mui/material";

const ThriveModal = (props: any) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "98%", md: props.width ? props.width : 960 },
    height: props.height ? props.height : 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const handleClose = () => {
    if (props.closeByClick) {
      props.setOpen(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style} style={{ padding: 0 }}>
          {props.contentComponent}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ThriveModal;
