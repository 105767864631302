import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ThriveTitle from "./ThriveTitle";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { StyledButtonMain } from "./Buttons";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import { StyledTextarea } from "./Textareas";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ThriveProviderCategory = [
  "Accounting",
  "Legal",
  "Marketing",
  "HR",
  "Business Consultant",
  "IT",
];

const ThriveResourceCategory = [
  "Chamber",
  "City Administration",
  "Economic Development",
  "Licensing",
  "Village Administration",
  "Chamber of Commerce",
  "Downtown Development Authority",
  "Financial Resources and Assistance",
  "Non-Profit",
];

interface Props {
  setOpen: any;
  editorType: string;
  title: string;
  editingResource: any;
}

export interface TagOption {
  readonly value: string;
  readonly label: string;
}

const ThriveResourceEditor: React.FC<Props> = ({
  title,
  setOpen,
  editorType,
  editingResource,
}) => {
  const [resource, setResource] = useState(editingResource);
  const [availableTags, setAvailableTags] = useState([]);
  const [factsheets, setFactsheets] = useState<any>(
    editingResource.factsheets ? editingResource.factsheets : []
  );
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    const tag_api = editorType === "Resource" ? "" : "/provider";
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/tags${tag_api}`, axiosConfig)
      .then((res) => {
        setAvailableTags(res.data.tags);
      });
  }, [editorType]);
  const handleFileUpload = async (file: File, file_type: string) => {
    const formData = new FormData();
    formData.append("file", file);

    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    if (file_type === "logo") {
      const result = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/images/upload`,
        formData,
        axiosConfig
      );
      setResource({
        ...resource,
        imagePath:
          `${process.env.REACT_APP_API_DOMAIN}/images/upload/` +
          result.data.imageName,
      });
    } else if (file_type === "onepager") {
      const result = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/onepager`,
        formData,
        axiosConfig
      );
      setResource({
        ...resource,
        onepagerPath:
          `${process.env.REACT_APP_API_DOMAIN}/factSheets/upload/` +
          result.data.pdfName,
      });
    } else if (file_type === "factsheet") {
      const result = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/factSheet`,
        formData,
        axiosConfig
      );
      const factsheetName = result.data.factsheetName;
      const factsheetStorageName = result.data.storageName;

      setFactsheets([
        ...factsheets,
        {
          name: factsheetName,
          storageName: factsheetStorageName,
          path:
            `${process.env.REACT_APP_API_DOMAIN}/factSheets/upload/` +
            result.data.storageName,
          download: `${process.env.REACT_APP_API_DOMAIN}/upload/factsheet/${factsheetStorageName}/${factsheetName}`,
        },
      ]);
    }
  };

  const handleChangeSubmit = async () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    // Find new tags and add them to the database
    if (resource.tags) {
      let newTags = resource.tags.filter(
        (tag: any) => !availableTags.includes(tag as never)
      );

      if (newTags.length > 0) {
        const tag_api = editorType === "Resource" ? "" : "/provider";
        const tag_save_result = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/tags${tag_api}`,
          { tags: newTags },
          axiosConfig
        );
        // console.log(tag_save_result.data.msg);
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/resources`,
        {
          resource: {
            ...resource,
            editorType: editorType,
            factsheets: factsheets,
          },
        },
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        window.location.reload();
      });
  };

  const handleDelete = async () => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    const api = editorType === "Resource" ? "resources" : "providers";

    axios
      .delete(
        `${process.env.REACT_APP_API_DOMAIN}/${api}?id=${resource._id}`,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        window.location.reload();
      });
  };

  const generateProviderDropdown = () => {
    return (
      <Select
        labelId="provider-category-label"
        id="demo-simple-select"
        label={`${editorType} Category`}
        value={resource["category"] ? resource["category"] : ""}
        onChange={(e) => {
          setResource({ ...resource, category: e.target.value });
        }}
      >
        {ThriveProviderCategory.sort().map((category: any, index: number) => {
          return (
            <MenuItem key={index} value={category}>
              {category}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const generateResourceDropdown = () => {
    return (
      <Select
        labelId="provider-category-label"
        id="demo-simple-select"
        label={`${editorType} Category`}
        value={resource["category"] ? resource["category"] : ""}
        onChange={(e) => {
          setResource({ ...resource, category: e.target.value });
        }}
      >
        {ThriveResourceCategory.sort().map((category: any, index: number) => {
          return (
            <MenuItem key={index} value={category}>
              {category}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const createTagDisplay = (tags: any = "") => {
    let formattedTags = tags.map((tag: any) => {
      return { value: tag, label: tag };
    });
    return formattedTags;
  };

  const handleChange = (
    newValue: OnChangeValue<TagOption, true>,
    actionMeta: ActionMeta<TagOption>
  ) => {
    let tagList = newValue.map(function (tag) {
      return tag.value;
    });

    setResource({
      ...resource,
      tags: tagList,
    });
  };

  return (
    <>
      <ThriveTitle title={title} setOpen={setOpen}></ThriveTitle>
      <Box sx={{ overflow: "auto", height: "590px" }}>
        <Stack sx={{ padding: "20px" }} spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`${editorType} Name`}
              variant="outlined"
              value={resource["orgName"] ? resource["orgName"] : ""}
              onChange={(e) => {
                setResource({ ...resource, orgName: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`${editorType} Calendar Link`}
              variant="outlined"
              value={resource["calendarLink"] ? resource["calendarLink"] : ""}
              onChange={(e) => {
                setResource({ ...resource, calendarLink: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`${editorType} Website`}
              variant="outlined"
              value={resource["url"] ? resource["url"] : ""}
              onChange={(e) => {
                setResource({ ...resource, url: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <FormControl fullWidth>
              <InputLabel id="provider-category-label">{`${editorType} Category`}</InputLabel>
              {editorType === "Provider" && generateProviderDropdown()}
              {editorType === "Resource" && generateResourceDropdown()}
            </FormControl>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`Contact First Name`}
              variant="outlined"
              value={resource["firstName"] ? resource["firstName"] : ""}
              onChange={(e) => {
                setResource({ ...resource, firstName: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`Contact Last Name`}
              variant="outlined"
              value={resource["lastName"] ? resource["lastName"] : ""}
              onChange={(e) => {
                setResource({ ...resource, lastName: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`Phone`}
              variant="outlined"
              value={resource["phone"] ? resource["phone"] : ""}
              onChange={(e) => {
                setResource({ ...resource, phone: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`Email`}
              variant="outlined"
              value={resource["email"] ? resource["email"] : ""}
              onChange={(e) => {
                setResource({ ...resource, email: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`Address Line 1`}
              variant="outlined"
              value={resource["addressline1"] ? resource["addressline1"] : ""}
              onChange={(e) => {
                setResource({ ...resource, addressline1: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`Address Line 2`}
              variant="outlined"
              value={resource["addressline2"] ? resource["addressline2"] : ""}
              onChange={(e) => {
                setResource({ ...resource, addressline2: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`City`}
              variant="outlined"
              value={resource["city"] ? resource["city"] : ""}
              onChange={(e) => {
                setResource({ ...resource, city: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`State`}
              variant="outlined"
              value={resource["state"] ? resource["state"] : ""}
              onChange={(e) => {
                setResource({ ...resource, state: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <TextField
              label={`Zip Code`}
              variant="outlined"
              value={resource["zip"] ? resource["zip"] : ""}
              onChange={(e) => {
                setResource({ ...resource, zip: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              id="logoPath"
              label={`Logo URL`}
              variant="outlined"
              value={resource["imagePath"] ? resource["imagePath"] : ""}
              onChange={(e) => {
                setResource({ ...resource, imagePath: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <Typography sx={{ alignItems: "center", display: "flex" }}>
              OR
            </Typography>
            <Button size="small" variant="contained" component="label">
              Upload
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  handleFileUpload(e.target.files![0], "logo");
                }}
              />
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <TextField
              id="onepagerPath"
              label={`One Pager URL`}
              variant="outlined"
              value={resource["onepagerPath"] ? resource["onepagerPath"] : ""}
              onChange={(e) => {
                setResource({ ...resource, onepagerPath: e.target.value });
              }}
              fullWidth
              className="mt-2"
            ></TextField>
            <Typography sx={{ alignItems: "center", display: "flex" }}>
              OR
            </Typography>
            <Button size="small" variant="contained" component="label">
              Upload
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  handleFileUpload(e.target.files![0], "onepager");
                }}
              />
            </Button>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Box>
              <Typography>Factsheets</Typography>
              <hr></hr>
              <Stack>
                {factsheets.map((factsheet: any, index: number) => {
                  return (
                    <Stack key={index} direction={"row"} spacing={1}>
                      <Typography>{factsheet.name}</Typography>
                      <Button
                        sx={{
                          height: "20px",
                          width: "20px",
                          fontSize: "10px",
                          marginTop: "2px",
                        }}
                        variant="contained"
                        href={factsheet.download}
                      >
                        Download
                      </Button>
                      <Button
                        sx={{
                          height: "20px",
                          width: "20px",
                          fontSize: "10px",
                          marginTop: "2px",
                        }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setFactsheets(
                            factsheets.filter((fact: any) => {
                              return fact !== factsheet;
                            })
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                })}
              </Stack>
            </Box>
            <Button
              variant="contained"
              component="label"
              sx={{ height: "30px" }}
            >
              Upload Factsheet
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  handleFileUpload(e.target.files![0], "factsheet");
                }}
              />
            </Button>
          </Stack>

          <StyledTextarea
            sx={{
              width: { xs: "300px", md: "900px" },
              margin: "auto",
              display: "block",
            }}
            minRows={8}
            maxRows={10}
            placeholder="Provider Description, separated by comma"
            value={resource["description"] ? resource["description"] : ""}
            onChange={(e: any) => {
              setResource({ ...resource, description: e.target.value });
            }}
          />

          <CreatableSelect
            isMulti
            onChange={handleChange}
            options={createTagDisplay(availableTags)}
            placeholder="Tags"
            defaultValue={resource.tags ? createTagDisplay(resource.tags) : []}
          />
          <div className="flex justify-between">
            <StyledButtonMain
              variant="contained"
              size="large"
              sx={{ width: "20%", backgroundColor: "#941566" }}
              onClick={handleChangeSubmit}
            >
              Save
            </StyledButtonMain>

            <StyledButtonMain
              variant="contained"
              size="large"
              sx={{ width: "20%", backgroundColor: "#CE2029" }}
              onClick={handleDelete}
            >
              Delete
            </StyledButtonMain>
          </div>
        </Stack>
      </Box>
    </>
  );
};

export default ThriveResourceEditor;
