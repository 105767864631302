export default function findObjectDifferences<T>(
  obj1: T,
  obj2: T,
  currentPath = ""
): { [key: string]: [any, any] } {
  const differences: { [key: string]: [any, any] } = {};

  function compareObjects(obj1: any, obj2: any, currentPath: string): void {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (!obj2.hasOwnProperty(key)) {
          differences[newPath] = [obj1[key], undefined];
        } else if (
          typeof obj1[key] === "object" &&
          typeof obj2[key] === "object"
        ) {
          compareObjects(obj1[key], obj2[key], newPath);
        } else if (obj1[key] !== obj2[key]) {
          differences[newPath] = [obj1[key], obj2[key]];
        }
      }
    }

    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;
        differences[newPath] = [undefined, obj2[key]];
      }
    }
  }

  compareObjects(obj1, obj2, currentPath);
  return differences;
}
