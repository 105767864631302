import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { CaseType, ReferralType, defaultCase } from "../../interfaces";
import { currencyFormat } from "../Dashboard/FirmListing/MiNiFirmReport";

const extractTextFromHTML = (html: any) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const textContent = doc.body.textContent || "";
  const lines = textContent.split("\n").filter((line) => line.trim() !== "");
  return lines.join("\n");
};

const arrayToObj = (arr: any) => {
  let obj: any = {};
  arr.map((item: any) => {
    obj[item._id] = item;
    return null;
  });
  return obj;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
  },
  text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 5,
  },
  table_row: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
  },
  table_row_odd: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
    backgroundColor: "rgb(235, 235, 235)",
  },
  table_text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 5,
    flex: 1,
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
  },
  checkbox_text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 2,
    flex: 1,
  },
  section_title: {
    fontSize: 20,
    color: "white",
    backgroundColor: "rgb(158, 31, 99)",
    marginBottom: "5px",
    marginTop: "5px",
    paddingLeft: "5px",
  },

  image: {
    width: 100,
    height: 100,
  },
  resource_image: { maxWidth: "100%", maxHeight: "200px", padding: "10px" },
  mainTitle: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  header: {
    flexDirection: "row", // Arrange children horizontally
    alignItems: "center", // Align children vertically
    justifyContent: "space-between", // Center children horizontally
    margin: 0,
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%", // Make the line span the full width of the page
    marginTop: -20, // Add some margin at the bottom
    marginBottom: 10,
  },
  hr_nomargin: {
    borderBottomColor: "black",
    borderBottomWidth: 5,
    width: "100%", // Make the line span the full width of the page
    // marginTop: 10, // Add some margin at the bottom
    marginBottom: 10,
  },
  half_left: { textAlign: "left", width: "49%", height: "100%" },
  half_right: { textAlign: "left", width: "49%", height: "100%" },
  checkbox: {
    width: 10,
    height: 10,
    border: "1 solid black",
    display: "flex",
  },
  one_thired: {
    textAlign: "left",
    width: "33%",
    height: "auto",
    minHeight: "100px",
    flexGrow: 1,
  },
  two_third: {
    textAlign: "left",
    width: "66%",
    height: "auto",
    minHeight: "100px",
    flexGrow: 1,
  },
  text_left: {
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginLeft: 5,
    flex: 1,
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
    width: "33%",
  },
  text_right: {
    textAlign: "left",
    fontFamily: "Times-Roman",
    fontSize: 12,

    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-all",
    width: "66%",
  },
});

const FirmReportPdf = () => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseID: string = queryParams.get("id") as string;

  const [theCase, setTheCase] = useState<CaseType>(defaultCase);
  const [firm, setFirm] = useState<any>({});
  const [activity, setActivity] = useState<any>([]);
  const [emails, setEmails] = useState<any>([]);
  const [initialMap, setInitialMap] = useState<{
    [key: string]: { init: string; full: string };
  }>({});
  const [referrals, setReferrals] = useState<any[]>([]);
  const [actionPlans, setActionPlans] = useState<any[]>([]);
  const [dataFlag, setDataFlag] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/cases?id=${caseID}`,
        axiosConfig
      )
      .then((res) => {
        setTheCase(res.data.case);
        getFirmById(res.data.case.firm);
        getActivity(res.data.case._id);
      });
  }, []);

  const getFirmById = (id: string) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/firms/id?id=${id}`, axiosConfig)
      .then((res) => {
        setFirm(res.data.firm);
      });
  };

  const compareByDate = (a: any, b: any) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  };

  const getActivity = (id: string) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/activity?case_id=${id}`,
        axiosConfig
      )
      .then(async (res) => {
        // console.log(res.data.activity);
        const sortedActivity = res.data.activity.sort(compareByDate);
        setActivity(
          sortedActivity.filter(
            (item: any) => !item.activity_type.includes("edit")
          )
        );
        const initials: string[] = Array.from(
          new Set(res.data.activity.map((item: any) => item.user_id))
        );

        if (initials.length > 1) {
          getInitialMap(initials);
        }
        setEmails(await getEmails(res.data.activity));
        setReferrals(
          await getReferrals(
            res.data.activity.filter((item: any) => {
              return (
                item.activity_type === "Referral" ||
                item.activity_type === "Provider Referral Initiated"
              );
            })
          )
        );
        setActionPlans(await getActionPlans(res.data.activity));
        setDataFlag(true);
      });
  };

  const getInitialMap = (ids: string[]) => {
    let tempInitialMap: { [key: string]: { init: string; full: string } } = {
      HIS: { init: "HIS", full: "HIS" },
    };
    const clean_ids = ids.filter((element: any) => {
      // Keep only elements that are not null, undefined, or empty strings
      return (
        element !== null &&
        element !== undefined &&
        element !== "" &&
        element !== "HIS"
      );
    });
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/user?ids=${clean_ids.join(",")}`,
        axiosConfig
      )
      .then((res) => {
        res.data.user.map((user: any) => {
          tempInitialMap[user._id as string] = {
            init: user["firstName"][0] + user["lastName"][0],
            full: user["firstName"] + " " + user["lastName"],
          };
          return null;
        });
        setInitialMap(tempInitialMap);
      });
  };

  const getActionPlans = async (activity: any) => {
    const actionPlanActivities = activity.find((item: any) => {
      return item.activity_type === "Business Action Plan";
    });
    if (actionPlanActivities) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/actionPlan?action_plan_id=${actionPlanActivities.action_plan_id}`,
        axiosConfig
      );
      const actionPlans = result.data;
      return actionPlans.content;
    } else {
      return [];
    }
  };

  const getEmails = async (activity: any) => {
    const email_activities = activity.filter((item: any) => {
      return (
        item.activity_type === "Event Invitation" ||
        item.activity_type === "Email Communication" ||
        item.activity_type === "Provider Referral Initiated" ||
        item.activity_type === "Providers Referral Email"
      );
    });
    if (email_activities.length > 0) {
      const email_ids = email_activities.map((item: any) => item.email_id);
      const result = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/email?email_ids=${email_ids.join(
          ","
        )}`,
        axiosConfig
      );
      const objectWithIds = arrayToObj(result.data.emails);
      return objectWithIds;
    } else {
      return {};
    }
  };

  const getReferrals = async (activity: any) => {
    let uniqueReferrals: any[] = [];
    activity.map((item: any) => {
      if (!uniqueReferrals.includes(item.referral_id)) {
        uniqueReferrals.push(item.referral_id);
      }
      return true;
    });

    if (uniqueReferrals.length > 0) {
      let referrals = [];
      for (let i = 0; i < uniqueReferrals.length; i++) {
        let result = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/referral?referral_id=${uniqueReferrals[i]}`,
          axiosConfig
        );

        if (result.data.referralContent.provider_id) {
          const provider_result = await axios.get(
            `${process.env.REACT_APP_API_DOMAIN}/resources?provider_id=${result.data.referralContent.provider_id}`,
            axiosConfig
          );
          referrals.push({
            ...result.data.referralContent,
            provider: provider_result.data.provider,
          });
        }
        if (result.data.referralContent.resource_id) {
          const resource_result = await axios.get(
            `${process.env.REACT_APP_API_DOMAIN}/resources?resource_id=${result.data.referralContent.resource_id}`,
            axiosConfig
          );
          referrals.push({
            ...result.data.referralContent,
            resource: resource_result.data.resource,
          });
        }
      }

      return referrals;
    } else {
      return [];
    }
  };

  // console.log(theCase, firm, activity, referrals);
  const availableGrant = [
    "[EDCA] Small Business Recovery Grant Application",
    "[EDCA] Small Business Recovery Grant Application - Round 2",
    "SBSL",
    "MSBSG",
    "MSBR Restart Grant",
    "MSBR",
    "EIDL Grant",
    "MEDC Grant",
    "PPP Grant",
    "Saving Business Saving Lives Grant",
    "Stabilization Grant",
  ];

  if (dataFlag) {
    return (
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="LETTER" style={styles.page}>
            <View style={styles.header}>
              <Image
                src={`${process.env.REACT_APP_API_DOMAIN}/images/Thrive_logo_primary.jpg`}
                style={styles.image}
              />
              <Text style={styles.mainTitle}>CLIENT REPORT</Text>
            </View>
            <View style={styles.hr} />
            <Text
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {firm["Company Name"]}
            </Text>

            <View style={styles.header}>
              <View style={styles.half_left}>
                <Text style={styles.section_title}>BASIC INFO</Text>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Contact:</Text>
                  <Text style={styles.table_text}>{firm["Contact"]}</Text>
                </View>

                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Email:</Text>
                  <Text style={styles.table_text}>{firm["Email"]}</Text>
                </View>

                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Phone:</Text>
                  <Text style={styles.table_text}>{firm["Phone"]}</Text>
                </View>

                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Website:</Text>
                  <Text style={styles.table_text}>{firm["Website"]}</Text>
                </View>

                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Founding Year:</Text>
                  <Text style={styles.table_text}>{firm["Founding Year"]}</Text>
                </View>

                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Employee:</Text>
                  <Text style={styles.table_text}>{firm["Employee"]}</Text>
                </View>

                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Annual Revenue:</Text>
                  <Text style={styles.table_text}>
                    {firm["Annual Revenue"]}
                  </Text>
                </View>

                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Address:</Text>
                  <Text style={styles.table_text}>{firm["Address"]}</Text>
                </View>

                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>City:</Text>
                  <Text style={styles.table_text}>{firm["City"]}</Text>
                </View>

                <View style={styles.table_row}>
                  <Text style={styles.table_text}>State:</Text>
                  <Text style={styles.table_text}>{firm["State"]}</Text>
                </View>

                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>ZIP:</Text>
                  <Text style={styles.table_text}>{firm["ZIP"]}</Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}> </Text>
                  <Text style={styles.table_text}></Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}> </Text>
                  <Text style={styles.table_text}></Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}> </Text>
                  <Text style={styles.table_text}></Text>
                </View>
              </View>
              <View style={styles.half_right}>
                <Text style={styles.section_title}>
                  Certifications/Classifications
                </Text>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Certified Women Owned:</Text>
                  <Text style={styles.table_text}>
                    {firm["Certified Women Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>
                    Certified Minority Owned:
                  </Text>
                  <Text style={styles.table_text}>
                    {firm["Certified Minority Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>
                    Certified Disabled Owned:
                  </Text>
                  <Text style={styles.table_text}>
                    {firm["Certified Disabled Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>
                    Certified Veteran Owned:
                  </Text>
                  <Text style={styles.table_text}>
                    {firm["Certified Veteran Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Certified LGBTQ+ Owned:</Text>
                  <Text style={styles.table_text}>
                    {firm["Certified LGBTQ+ Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Woman-Owned:</Text>
                  <Text style={styles.table_text}>{firm["Woman-Owned"]}</Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Minority-Owned:</Text>
                  <Text style={styles.table_text}>
                    {firm["Minority-Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>Veteran-Owned:</Text>
                  <Text style={styles.table_text}>{firm["Veteran-Owned"]}</Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Disabled-Owned:</Text>
                  <Text style={styles.table_text}>
                    {firm["Disabled-Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>LGBTQ+ Owned:</Text>
                  <Text style={styles.table_text}>{firm["LGBTQ+ Owned"]}</Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Youth Owned:</Text>
                  <Text style={styles.table_text}>{firm["Youth Owned"]}</Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>
                    Low to Moderate Income Owned:
                  </Text>
                  <Text style={styles.table_text}>
                    {firm["Low to Moderate Income Owned"]}
                  </Text>
                </View>
                <View style={styles.table_row_odd}>
                  <Text style={styles.table_text}>Non-English Business:</Text>
                  <Text style={styles.table_text}>
                    {firm["Non-English Business"]}
                  </Text>
                </View>
                <View style={styles.table_row}>
                  <Text style={styles.table_text}>NEP:</Text>
                  <Text style={styles.table_text}>{firm["NEP"]}</Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={styles.section_title}>
                Business Description and Services
              </Text>
              <View style={styles.table_row_odd}>
                <Text style={styles.table_text}>Primary Industry:</Text>
                <Text style={styles.table_text}>{firm["primaryIndustry"]}</Text>
              </View>
              <View style={styles.table_row}>
                <Text style={styles.table_text}>Secondary Industry:</Text>
                <Text style={styles.table_text}>
                  {firm["secondaryIndustry"]}
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.section_title}>Active Business Channels</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={[
                  styles.checkbox,
                  firm["Home-based"] && { backgroundColor: "black" },
                ]}
              />
              <Text style={styles.checkbox_text}>Home-based</Text>
              <View
                style={[
                  styles.checkbox,
                  firm["Selling Online"] && { backgroundColor: "black" },
                ]}
              />
              <Text style={styles.checkbox_text}>Selling Online</Text>
              <View
                style={[
                  styles.checkbox,
                  firm["In-Person Sales"] && { backgroundColor: "black" },
                ]}
              />
              <Text style={styles.checkbox_text}>In-Person Sales</Text>
              <View
                style={[
                  styles.checkbox,
                  firm["Commercial Location"] && { backgroundColor: "black" },
                ]}
              />
              <Text style={[styles.checkbox_text, { fontSize: 10 }]}>
                Commercial Location
              </Text>
              <View
                style={[
                  styles.checkbox,
                  firm["Mobile Business"] && { backgroundColor: "black" },
                ]}
              />
              <Text style={styles.checkbox_text}>Mobile Business</Text>
            </View>
          </Page>
          <Page size="LETTER" style={styles.page}>
            <View>
              <View>
                <Text style={styles.section_title}>Grants Applications</Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.table_text}>Grant</Text>
                  <Text style={styles.table_text}>Status</Text>
                  <Text style={styles.table_text}>Amount</Text>
                </View>
                {availableGrant.map((grant, index) => {
                  return (
                    <View
                      style={[
                        styles.table_row,
                        availableGrant.indexOf(grant) % 2 === 0
                          ? styles.table_row_odd
                          : {},
                      ]}
                      key={index}
                    >
                      <Text style={styles.table_text}>{grant}</Text>
                      <Text style={styles.table_text}>
                        {firm["grants"][grant]?.["decision"]}
                      </Text>
                      <Text style={styles.table_text}>
                        {currencyFormat(firm["grants"][grant]?.["value"])}
                      </Text>
                    </View>
                  );
                })}
              </View>
              <View>
                <Text style={styles.section_title}>Client Notes</Text>
                <Text style={styles.text}>{firm["notes"]}</Text>
              </View>

              <View>
                <Text style={styles.section_title}>Business Action Plan</Text>
                {actionPlans.map((actionPlan, index) => {
                  return (
                    <View key={index} wrap={false}>
                      <View>
                        <Text style={{ fontSize: "14px" }}>
                          {new Intl.DateTimeFormat("en-US").format(
                            new Date(actionPlan.startDate)
                          )}{" "}
                          - {actionPlan.action}
                        </Text>
                        <View style={styles.hr_nomargin}></View>
                      </View>

                      <View style={styles.table_row}>
                        <Text style={styles.text_left}>
                          Business Objective:
                        </Text>
                        <Text style={styles.text_right}>
                          {actionPlan.businessObjective}
                        </Text>
                      </View>

                      <View style={styles.table_row}>
                        <Text style={styles.text_left}>Time Line:</Text>
                        <Text style={styles.text_right}>
                          {actionPlan.timeLine}
                        </Text>
                      </View>

                      <View style={styles.table_row}>
                        <Text style={styles.text_left}>Note:</Text>
                        <Text style={styles.text_right}>{actionPlan.note}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </Page>
          {referrals.length > 0 && (
            <Page size="LETTER" style={styles.page}>
              <View>
                <Text style={[styles.section_title, { height: 150 }]}>
                  Referrals
                </Text>
              </View>
              {referrals.map((referral, index) => {
                return (
                  <View key={index} wrap={false}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={styles.one_thired}>
                        <Image
                          src={
                            referral["provider"]
                              ? referral["provider"]["imagePath"]
                              : referral["resource"]
                              ? referral["resource"]["imagePath"]
                              : ""
                          }
                          style={styles.resource_image}
                        />
                      </View>
                      <View style={styles.two_third}>
                        <View style={styles.table_row}>
                          <Text style={styles.table_text}>
                            Referral{" "}
                            {referral["provider_id"] ? "Provider" : "Resource"}:
                          </Text>
                          <Text style={styles.table_text}>
                            {referral["provider_id"]
                              ? referral["provider"]["orgName"]
                              : referral["resource"]["orgName"]}
                          </Text>
                        </View>
                        <View style={styles.table_row_odd}>
                          <Text style={styles.table_text}>Referral Date:</Text>
                          <Text style={styles.table_text}>
                            {new Intl.DateTimeFormat("en-US").format(
                              new Date(referral["referral_date"])
                            )}
                          </Text>
                        </View>
                        <View style={styles.table_row}>
                          <Text style={styles.table_text}>Referral Type:</Text>
                          <Text style={styles.table_text}>
                            {referral["provider_id"] ? "Provider" : "Resource"}
                          </Text>
                        </View>
                        <View style={styles.table_row_odd}>
                          <Text style={styles.table_text}>
                            Referral Objective:
                          </Text>
                          <Text style={styles.table_text}>
                            {referral["referral_objective"]}
                          </Text>
                        </View>
                        <View style={{ height: "100%" }}>
                          <Text style={[styles.text, { marginLeft: "5px" }]}>
                            Referral Notes:
                          </Text>
                          <Text>{referral["referral_note"]}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.hr}></View>
                  </View>
                );
              })}
            </Page>
          )}
          <Page size="LETTER" style={styles.page}>
            <View>
              <Text style={styles.section_title}>Activities</Text>
            </View>
            <View>
              {activity.map((item: any, index: number) => {
                return (
                  <View key={index} wrap={false}>
                    <View>
                      <Text>
                        {new Intl.DateTimeFormat("en-US").format(
                          new Date(item.created_at)
                        )}{" "}
                        - {item.activity_type}
                      </Text>
                      <View style={styles.hr_nomargin}></View>
                    </View>

                    <View style={styles.table_row}>
                      <Text style={styles.table_text}>Assistance:</Text>
                      <Text style={styles.table_text}>
                        {item.assistance_requested
                          ? item.assistance_requested.join(", ")
                          : ""}
                      </Text>
                    </View>

                    {item.activity_type === "Event Invitation" ||
                    item.activity_type === "Email Communication" ||
                    item.activity_type === "Provider Referral Initiated" ||
                    item.activity_type === "Providers Referral Email" ? (
                      <View style={styles.table_row_odd}>
                        <Text style={styles.text_left}>Email:</Text>
                        <Text style={styles.text_right}>
                          {extractTextFromHTML(
                            emails[item["email_id"]]["htmlContent"]
                          )}
                        </Text>
                      </View>
                    ) : (
                      <View style={styles.table_row_odd}>
                        <Text style={styles.text_left}>Notes:</Text>
                        <Text style={styles.text_right}>
                          {item.activity_note}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={styles.text}>
                        Contact Time:{item.activity_contact_time}
                      </Text>
                      <Text style={styles.text}>
                        Prep Time:{item.activity_prep_time}
                      </Text>
                      <Text style={styles.text}>
                        Travel Time:{item.activity_travel_time}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  } else {
    return <></>;
  }
};

export default FirmReportPdf;
