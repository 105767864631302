import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import axios from "axios";
import { useLocation } from "react-router-dom";

const surveyJson = {
  showQuestionNumbers: "off",
  elements: [
    {
      type: "html",
      name: "parentQuestion",
      html: "<h4>How did I do? (Use a scale of 1-6 where 1 indicates you are not at all satisfied, and 6 means you are extremely satisfied)</h4>",
    },
    {
      name: "q1a",
      title: "Quality of customer service",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1b",
      title: "Quality of resources provided",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1c",
      title: "Quality of responsiveness",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q1d",
      title: "Helpful for your business?",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "q2",
      title:
        "How likely is it that you would recommend Business Forward to a friend or business colleague?",
      description:
        "Use a scale of 1-10 where 1 indicates you are not at all likely to recommend, and 10 means you are extremely likely to recommend.",
      type: "rating",
      rateValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      type: "html",
      name: "additional comments",
      html: "<h4>Additional Comments</h4>",
    },
    {
      name: "q3",
      title:
        "Have you met your consultant in person at your place of business?",
      type: "radiogroup",
      choices: ["Yes", "No"],
    },
    {
      name: "q3SubQuestion1",
      title: "If not, what day(s) work best?",
      type: "checkbox",
      visibleIf: '{q3} = "No"',
      choices: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    },
    {
      name: "q3SubQuestion2",
      title: "What time(s)?",
      type: "checkbox",
      visibleIf: '{q3} = "No"',
      choices: [
        "Early morning",
        "Lunch",
        "Late Afternoon",
        "Early Evening",
        "Evening",
      ],
    },
    {
      name: "q4",
      title: "General comments",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
    {
      name: "q5",
      title: "Consultant Strengths",
      description: "What did I do best?",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
    {
      name: "q6",
      title: "Areas for improvement",
      description: "What should I do better?",
      type: "comment",
      rows: 3,
      autoGrow: true,
      allowResize: false,
    },
  ],
};

const PublicClientReferralSurvey = () => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      // Add JWT token to Authorization header
    },
  };

  const survey = new Model(surveyJson);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get("caseId");
  const referralID = queryParams.get("referralId");

  survey.onComplete.add((sender, options) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/survey/client_referral_result`,
        {
          activity_type: "Client Referral Survey (by Client)",
          surveyResult: {
            survey_result: sender.data,
            case_id: caseId,
            survey_id: "64f75c4287cfd9719d4904e1",
            referral_id: referralID,
          },
        },
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
        } else {
          console.log("API Error");
        }
      });
  });

  return <Survey model={survey} />;
};
export default PublicClientReferralSurvey;
