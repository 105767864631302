import React from "react";
import { useParams } from "react-router-dom";
import RGA_report from "./ReportItems/RGA_report";
import InitialContactReport from "./ReportItems/InitialContactReport";
import ActiveClientsReport from "./ReportItems/ActiveClientsReport";

const Report = () => {
  const { id } = useParams();

  if (id === "rga") {
    return <RGA_report />;
  } else if (id === "initial_contact") {
    return <InitialContactReport />;
  } else if (id === "active_clients") {
    return <ActiveClientsReport />;
  } else {
    return <>No matching Report found</>;
  }
};

export default Report;
