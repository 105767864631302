import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const Tags = (props: any) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [matchingTagIndices, setMatchingTagIndices] = useState<number[]>([]);

  useEffect(() => {
    if (searchQuery === "") {
      setMatchingTagIndices(
        props.availableTags.map((tag: string, index: number) => index)
      );
    } else {
      const matchingTagIndicesTemp = props.availableTags
        .map((tag: string, index: number) =>
          tag.toLowerCase().includes(searchQuery.toLowerCase()) ? index : -1
        )
        .filter((index: number) => index !== -1);
      setMatchingTagIndices(matchingTagIndicesTemp);
    }
  }, [searchQuery, props.availableTags]);

  const style = {
    "& label.Mui-focused": {
      color: "#9D2362",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#9D2362",
      },
    },
  };

  return (
    <div>
      <TextField
        label="Search Tags"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "10px", borderColor: "#9D2362" }}
        className="bg-white w-[100%]"
        color="secondary"
        sx={style}
      />
      <div className="bg-white rounded-lg shadow-md p-4">
        {matchingTagIndices.map((index: number) => (
          <div key={index}>
            <div className="grid grid-cols-[40px_1fr]">
              <div
                className={`mr-1 text-center col-start-1 col-span-1 flex items-center justify-center ${
                  !props.resourceStatics[props.availableTags[index]]
                    ? "text-gray-400"
                    : ""
                }`}
              >
                {props.resourceStatics[props.availableTags[index]]
                  ? `(${props.resourceStatics[props.availableTags[index]]})`
                  : "(0)"}
              </div>
              <div className=" col-start-2 col-span-1 flex justify-start">
                <input
                  type="checkbox"
                  id={props.availableTags[index]}
                  name={props.availableTags[index]}
                  checked={props.checkedTags[index]}
                  onChange={() => props.onTagChange(index)}
                  disabled={!props.resourceStatics[props.availableTags[index]]}
                />
                <label
                  htmlFor={props.availableTags[index]}
                  className={`ml-2 text-left ${
                    !props.resourceStatics[props.availableTags[index]]
                      ? "text-gray-400"
                      : ""
                  }`}
                >
                  {props.availableTags[index]}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
