import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const FlowCard = (props: any) => {
  const navigate = useNavigate();
  const getBackgroundImage = () => {
    let backgroundImageUrl = "";
    if (props.activity && props.activity.length > 0) {
      props.activity.map((activityItem: any) => {
        if (activityItem["activity_type"] === props.type) {
          backgroundImageUrl = `url(${process.env.REACT_APP_API_DOMAIN}/images/gray_check.png)`;
        }
        return true;
      });
    }
    return backgroundImageUrl;
  };

  return (
    <Box
      sx={{
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
        minHeight: "180px",
        textAlign: "center",
        alignItems: "center",
        backgroundImage: getBackgroundImage(),
        backgroundSize: "50px auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 110px",
        cursor: "pointer",
        backgroundColor: props.disable ? "#cdcdcd" : "",
        borderRadius: "10px",
      }}
      onClick={() => {
        if (!props.disable) {
          props.setCardOpen(true);
          props.setOpenCardTitle(props.title);
          if (props.navigateUrl) {
            navigate(props.navigateUrl);
          }
        }
      }}
    >
      <div className="bg-[#0f95b3] h-10 rounded-t-[10px] flex items-center justify-center">
        <h3
          className="text-xs font-bold text-center m-4"
          style={{ color: "white" }}
        >
          {props.displayTitle}
        </h3>
      </div>
      <div className="text-xs text-left m-2" style={{ textAlign: "center" }}>
        {props.subtitle}
      </div>
    </Box>
  );
};

export default FlowCard;
