import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Box, Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";


const ReassignClient = (props: any) => {
  const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    const navigate = useNavigate();


  const [clientList, setClientList] = useState<
    { _id:any, firstName: string; lastName: string, email: string }[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<{value: any, label:any}>({ value: props.currUser._id, label: props.currUser.firstName + " " + props.currUser.lastName });

  useEffect(() => {
    axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user/allUsers`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            setClientList(res.data.users);
          } else {
            console.log("API Error at getting survey result");
          }
        });
  }, []);

  const handleSelectChange = (selectedClient: any) => {
    setSelectedValue(selectedClient);
  };

  const handleSubmit = () => {
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/cases/reassignClient`,
          {
            caseId: props.caseId,
            userId: selectedValue.value,
          },
          axiosConfig
        )
        .then((res) => {
          props.setReassignClientOpen(false);
          navigate("/dashboard");
        });       
  }

    return (
    <>
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#9E1F63FF",
        padding: "3px",
        margin: 0,
        color: "white",
        borderRadius: "5px 5px 0 0",
      }}
    >
      <Typography sx={{ display: "inline-block" }} variant="h4">
        REASSIGN CLIENT
      </Typography>
      <Box
        sx={{ display: "inline", float: "right", cursor: "pointer" }}
        onClick={() => {
          props.setReassignClientOpen(false);
        }}
      >
        <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
      </Box>
    </Box>
    <div className="w-full h-full flex flex-col items-center ">
     <div className="mt-[20%]">
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      className="w-[400px]"
      placeholder="Select the Client"
      options={clientList.map((client) => ({
        value: client._id,
        label: client.firstName + " " + client.lastName,
      }))}
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: "50px",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#9D2362" : "white",
          color: state.isSelected ? "white" : "black",
          ":hover": {
            backgroundColor: state.isSelected ? "#9D2362" : "#F3F4F6",
          },
        }),
      }}
    />
    </div>
    <div className="mt-3">
    <Button
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "20px",
              backgroundColor: "#9E1F63FF",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>

    </div>
    </div>
    
  </>);
};
export default ReassignClient;