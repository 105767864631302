import { createContext } from "react";
import { UserType } from "../interfaces";

export interface UserContextType {
  user: UserType;
  setUser: (newUser: UserType) => void;
}

export const UserContext = createContext<UserContextType>({
  user: {
    _id: "",
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    token: "",
    phone: "",
  },
  setUser: () => {},
});
