import React from "react";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment";

const ActivityDetailWithoutLink = (props: any) => {
  const [firm, setFirm] = React.useState<any>(props.firm);

  const handleSelect = (value: number) => {
    if (props.multiselect) {
      const currentIndex = props.selectedCase.indexOf(value);
      const newChecked = [...props.selectedCase];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      props.setSelectedCase(newChecked);
    } else {
      props.setSelectedCase(props.case);
    }
  };

  return (
    <>
      <Box
        sx={{ cursor: "pointer", width: "100%" }}
        onClick={() => {
          handleSelect(props.index2);
        }}
      >
        <Card
          sx={{
            backgroundColor:
              props.selectedCase._id === props.index ? "#9e1f6357" : "white",
            display: "flex",
            alignItems: "center",
            margin: "10px",
            padding: "0 0 0 5px",
            textAlign: "left",
          }}
        >
          <Avatar />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                {firm["Company Name"]}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div"
              >
                Created Date: {moment(props.case.date).format("MM/DD/YYYY")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                component="div"
              >
                Created by:{" "}
                {props.case.createdBy ? props.case.createdBy : "BFC"}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                {firm["Industry"]}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ActivityDetailWithoutLink;
