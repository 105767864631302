import { Margin, usePDF } from "react-to-pdf";
import SurveyJsPanel from "../SurveyJsPanel";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const ActiveClientsReport = () => {
  const [cities, setCities] = useState<string[]>([]);
  const [data1, setData1] = useState<any[]>([]);
  useEffect(() => {
    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/reports/active_clients`,
        axiosConfig
      )
      .then((response: any) => {
        console.log(response.data);
        setCities(response.data.cities);
        setData1(response.data.firmsWithInitialContact);
      });
  }, []);

  const surveyContent1 = {
    elements: [
      {
        name: "City",
        title: "Active by community/city",
        type: "dropdown",
        choices: cities,
      },
    ],
    showQuestionNumbers: "off",
    completedHtml: "Thank you for your feedback!",
  };

  const surveyResult1: any[] = data1;

  const { toPDF, targetRef } = usePDF({
    filename: "Report.pdf",
    page: { margin: Margin.SMALL, format: "letter" },
    overrides: {
      canvas: {
        removeContainer: false,
      },
    },
  });

  return (
    <>
      <Box sx={{ position: "absolute", marginTop: "-42px" }}>
        <Button
          sx={{ marginLeft: "10px" }}
          color={"warning"}
          variant="contained"
          onClick={() => toPDF()}
        >
          Print
        </Button>
      </Box>
      <SurveyJsPanel
        surveyTitle={"Initial Contact Report"}
        pdfref={targetRef}
        surveyContent={surveyContent1}
        surveyResult={surveyResult1}
      ></SurveyJsPanel>
    </>
  );
};

export default ActiveClientsReport;
